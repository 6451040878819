import React from 'react'
import { TypeOfAlertEnum } from '../../../shared/types/typeOfAlertEnum'
import { Button } from '@consta/uikit/Button'
import styles from './AlertCard.module.css'
import warningIcon from '../../../assets/icons/warning.svg'
import infoIcon from '../../../assets/icons/alert.svg'
import { useNavigate } from 'react-router-dom'
const AlertCard = ({
  dashboardData,
  typeOfAlert,
}: {
  dashboardData?: {
    quarter?: string
    year?: string
    due?: string
    reportId?: string
    isButtonDisabled?: boolean
  }
  typeOfAlert: TypeOfAlertEnum
}) => {
  const navigate = useNavigate()
  const handleOnClick = (url: string) => {
    navigate(url)
  }

  const mapping = {
    [TypeOfAlertEnum.WARNING]: {
      icon: warningIcon,
      alt: 'warning',
      title: 'Пора отправить отчет',
      subtitle: `Отчет за ${dashboardData?.quarter} квартал ${dashboardData?.year} года нужно отправить до ${dashboardData?.due}.`,
      buttonLabel: 'Отправить отчет',
      navigateTo: `/reports/create`,
      disabled: false,
    },
    [TypeOfAlertEnum.INFO]: {
      icon: infoIcon,
      alt: 'info',
      title: 'Вы давно не добавляли действие',
      subtitle: `Наполнение раздела «деятельность» напрямую влияет на квартальный отчет.`,
      buttonLabel: 'Добавить действие',
      navigateTo: `/activity/createActivity`,
      disabled: false,
    },
    [TypeOfAlertEnum.UPDATE_REPORT]: {
      icon: warningIcon,
      alt: 'warning',
      title: 'Необходимо исправить отчет',
      subtitle: `Отчет ${dashboardData?.reportId} был проверен модератором, необходимо сделать правки`,
      buttonLabel: 'Изменить отчет',
      navigateTo: `/reports/${dashboardData?.reportId}/edit`,
      disabled: false,
    },
    [TypeOfAlertEnum.CREATE_QUARTER_REPORT]: {
      icon: infoIcon,
      alt: 'info',
      title: `Сдача квартального отчета`,
      subtitle: `Формирование отчета за текущий квартал (${dashboardData?.quarter} квартал)`,
      buttonLabel: 'Создать',
      navigateTo: `/reports/create`,
      disabled: dashboardData?.isButtonDisabled,
    },
    [TypeOfAlertEnum.SHOW_QUARTER_REPORT]: {
      icon: infoIcon,
      alt: 'info',
      title: 'Просмотр промежуточного квартального отчета',
      subtitle: `Посмотреть промежуточные показатели по текущему кварталу (${dashboardData?.quarter} квартал)`,
      buttonLabel: 'Создать',
      navigateTo: `/reports/view/quarter`,
      disabled: false,
    },
    [TypeOfAlertEnum.SHOW_YEAR_REPORT]: {
      icon: infoIcon,
      alt: 'info',
      title: 'Просмотр промежуточного годового отчета',
      subtitle: ` Посмотреть промежуточные показатели за год (учитываются только проведенные квартальные отчеты)`,
      buttonLabel: 'Создать',
      navigateTo: `/reports/view/year`,
      disabled: false,
    },
  }

  const renderData = mapping[typeOfAlert]

  return (
    <div className={styles.alertCard}>
      <div className={styles.mainAlert}>
        <img src={renderData.icon} alt={renderData.alt} />
      </div>
      <div>
        <p className={styles.alertTitle}>{renderData.title}</p>
        <p className={styles.alertText}>{renderData.subtitle}</p>
        <Button
          label={renderData.buttonLabel}
          onClick={() => handleOnClick(renderData.navigateTo)}
          disabled={renderData.disabled}
        />
      </div>
    </div>
  )
}

export default AlertCard
