import { FC, useEffect, useState } from 'react'
import { ProgressLine } from '@consta/uikit/ProgressLine'

import styles from './ProgressBar.module.css'

interface ProgressBarProps {
  dataReceived: boolean
}

export const ProgressBar: FC<ProgressBarProps> = ({ dataReceived }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress === 99) {
        return
      }
      if (progress < 90) {
        setProgress(progress + 30)
      } else {
        setProgress(progress + 1)
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [progress])

  useEffect(() => {
    if (dataReceived) {
      setProgress(100)
    }
  }, [dataReceived])

  return (
    <div className={styles.wrapper}>
      {progress < 100 && (
        <div className={styles.container}>
          <ProgressLine size='m' value={progress} />
          <div className={styles.percentProgress}>
            <div>{`${progress}%`}</div>
            {progress === 99 && <div>Еще немного терпения</div>}
          </div>
        </div>
      )}
    </div>
  )
}
