export enum ReportStatusEnum {
  NEW = 'new',
  APPROVED = 'approved',
  TO_REVIEW = 'to_review',
  ON_REVIEW = 'on_review',
  AUTO_APPROVED = 'auto_approved',
}
export enum ReportStatusEnumToRu {
  NEW = 'Новый',
  APPROVED = 'Подтверждено',
  TO_REVIEW = 'Исправление',
  ON_REVIEW = 'Проверено',
  AUTO_APPROVED = 'Автоматически подтверждено',
}
