import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import 'styles/global.css'
import { presetGpnDefault, Theme } from '@consta/uikit/Theme'
import { BrowserRouter as Router } from 'react-router-dom'
const container = document.getElementById('root')!
const root = createRoot(container)
root.render(
  <Router>
    <Theme preset={presetGpnDefault}>
      <App />
    </Theme>
  </Router>
)
