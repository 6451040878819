export enum ACTIONS {
  SET_DATA,
  UPDATE_FIELD,
  UPDATE_CHECKBOX,
  INSERT_FILE,
  DELETE_FILE,
  INSERT_PHOTO,
  DELETE_PHOTO,
  SEND_DATA,
  SEND_DATA_FAILED,
  SEND_DATA_SUCCESS,
}

export interface SET_DATA {
  type: ACTIONS.SET_DATA
  payload: unknown
}
export interface UPDATE_FIELD {
  type: ACTIONS.UPDATE_FIELD
  payload: {
    key: string
    value: string | number | null
  }
}
export interface UPDATE_CHECKBOX {
  type: ACTIONS.UPDATE_CHECKBOX
  payload: {
    key: string
    checked: boolean
  }
}
export interface INSERT_FILE {
  type: ACTIONS.INSERT_FILE
  payload: unknown
}
export interface DELETE_FILE {
  type: ACTIONS.DELETE_FILE
  payload: unknown
}
export interface INSERT_PHOTO {
  type: ACTIONS.INSERT_PHOTO
  payload: unknown
}
export interface DELETE_PHOTO {
  type: ACTIONS.DELETE_PHOTO
  payload: unknown
}
export interface SEND_DATA {
  type: ACTIONS.SEND_DATA
  payload: unknown
}
export interface SEND_DATA_FAILED {
  type: ACTIONS.SEND_DATA_FAILED
  payload: unknown
}
export interface SEND_DATA_SUCCESS {
  type: ACTIONS.SEND_DATA_SUCCESS
  payload: unknown
}

export type ActivityActions =
  | SET_DATA
  | UPDATE_FIELD
  | UPDATE_CHECKBOX
  | INSERT_FILE
  | DELETE_FILE
  | INSERT_PHOTO
  | DELETE_PHOTO
  | SEND_DATA
  | SEND_DATA_FAILED
  | SEND_DATA_SUCCESS
