import styles from './BasicInfo.module.css'
import { UserInfoDTO } from 'shared/Api/users'

export const BasicInfo = ({ user }: { user: UserInfoDTO }) => {
  const getFormattedPhone = (phone: string | null) =>
    (phone ?? '').replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 ($2) $3-$4-$5')

  return (
    <div className={styles.blockInfoContainer}>
      <h3 className={styles.title}>Основная информация</h3>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Дата рождения</div>
        {user.birth_date ? (
          <div className={styles.textRight}>{user.birth_date}</div>
        ) : (
          <div className={styles.noInformation}>Пусто</div>
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Email</div>
        <div className={styles.textRight}>{user.email}</div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Телефон</div>
        {user.phone ? (
          <div className={styles.textRight}>{getFormattedPhone(user.phone)}</div>
        ) : (
          <div className={styles.noInformation}>Пусто</div>
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Должность</div>
        {user.position ? (
          <div className={styles.textRight}>{user.position}</div>
        ) : (
          <div className={styles.noInformation}>Пусто</div>
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Комитет</div>
        {user.committee ? (
          <div className={styles.textRight}>{user.committee}</div>
        ) : (
          <div className={styles.noInformation}>Пусто</div>
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>МО</div>
        {user.municipal_entity ? (
          <div className={styles.textRight}>{user.municipal_entity}</div>
        ) : (
          <div className={styles.noInformation}>Пусто</div>
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Партия</div>
        {user.party ? (
          <div className={styles.textRight}>{user.party}</div>
        ) : (
          <div className={styles.noInformation}>Пусто</div>
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Ссылки на соц. сети</div>
        {user.social_medias.length === 0 ? (
          <div className={styles.noInformation}>Пусто</div>
        ) : (
          <div className={styles.textRight}>
            {user.social_medias.map((item, index) => (
              <div key={index} className={styles.linkContainer}>
                <a target='_blank' rel='noreferrer' href={item.link} className={styles.link}>
                  {item.link}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
