export const itemsComboboxCriteria = [
  { label: 'Личный прием', labelForBackend: 'personal_reception', id: 1 },
  { label: 'Решенное обращение', labelForBackend: 'solved_appeal', id: 2 },
  { label: 'Кол-во принятых обращений', labelForBackend: 'received_enquiries', id: 3 },
  { label: 'Проведенное мероприятие', labelForBackend: 'event_held', id: 4 },
  {
    label: 'Участие в собрании граждан',
    labelForBackend: 'participation_in_citizenship_meeting',
    id: 5,
  },
  {
    label: 'Участие в сессии, комитете, комиссии и ином',
    labelForBackend: 'participation_in_session',
    id: 6,
  },
  {
    label: 'Участие в общем собрании Совета в МО',
    labelForBackend: 'participation_in_municipal_entity_meeting',
    id: 7,
  },
  {
    label: 'Обучающее мероприятие, организованное Советом МО',
    labelForBackend: 'training_event_by_municipal_entity',
    id: 8,
  },
  {
    label: 'Обучающее мероприятие при участии членов Совета МО',
    labelForBackend: 'training_event_with_participation_municipal_entity',
    id: 9,
  },
  { label: 'Кол-во депутатов, прошедших обучение', labelForBackend: 'trained_deputies', id: 10 },
  { label: 'Проект муниципального уровня', labelForBackend: 'municipal_project', id: 11 },
  { label: 'Проект краевого уровня', labelForBackend: 'regional_project', id: 12 },
  {
    label: 'Законодательная инициатива МО',
    labelForBackend: 'legislative_initiative_of_municipal_entity',
    id: 13,
  },
  {
    label: 'Законодательная инициатива ЗСК',
    labelForBackend: 'legislative_initiative_of_ZSK',
    id: 14,
  },
  { label: 'Участие депутата ЗСК', labelForBackend: 'participation_of_deputy_ZSK', id: 15 },
  { label: 'Участие депутата ГД', labelForBackend: 'participation_of_deputy_GD', id: 16 },
  { label: 'Бюро президиума', labelForBackend: 'bureau_of_presidium', id: 17 },
  {
    label: 'Кол-во публикаций на оф. сайтах МО',
    labelForBackend: 'publication_links_municipal_entity',
    id: 18,
  },
  { label: 'Кол-во публикаций в СМИ', labelForBackend: 'publication_links_mass_media', id: 19 },
  {
    label: 'Кол-во публикаций на других интернет-ресурсах',
    labelForBackend: 'publication_links_in_internet_resources',
    id: 20,
  },
]
