import { FC } from 'react'

import styles from './InputGroup.module.css'

export const InputGroup: FC<any> = ({ label, required, labelRight, children }: any) => {
  return (
    <div className={styles.inputGroupContainer}>
      <div className={styles.label}>
        {label} {required && <span style={{ color: 'red' }}>*</span>}
      </div>
      <div className={styles.children}>{children}</div>
      {labelRight && <div className={styles.labelRight}>{labelRight}</div>}
    </div>
  )
}
