import { FC } from 'react'

import styles from './LogoBlock.module.css'

import logo from '../../assets/icons/logo.svg'
import logoText from '../../assets/icons/logoText.svg'

export const LogoBlock: FC = () => {
  return (
    <div className={styles.wrapper}>
      <img src={logo} alt='' />
      <img src={logoText} alt='' />
    </div>
  )
}
