import styles from './SystemInformation.module.css'
import { UserInfoDTO } from 'shared/Api/users'
import { rolesToUIMapping } from 'shared/utils/rolesToUIMapping'
import { statusToUIText, statusToUITextColors } from 'shared/utils/statusMappings'
import { dateMappingsCreatedAt } from 'shared/utils/dateMappingsCreatedAt'

export const SystemInformation = ({ user }: { user: UserInfoDTO }) => {
  return (
    <div className={styles.blockInfoContainer}>
      <h3 className={styles.title}>Системная информация</h3>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>ID</div>
        <div className={styles.textRight}>{user.id}</div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Дата создания</div>
        <div className={styles.textRight}>{dateMappingsCreatedAt(user.createdAt)}</div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Роль</div>
        <div className={styles.textRight}>{rolesToUIMapping(user.role)}</div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Статус</div>
        <div className={styles.textRight} style={{ color: statusToUITextColors(user.status) }}>
          {statusToUIText(user.status)}
        </div>
      </div>
    </div>
  )
}
