import { Type_of_education } from '../types/typeOfEducation'

export const itemsAutoCompleteTypeOfEducation = [
  {
    label: 'Бакалавр',
    id: 1,
    value: Type_of_education.BACHELOR,
  },
  {
    label: 'Магистр',
    id: 2,
    value: Type_of_education.MASTER,
  },
  {
    label: 'Доктор наук',
    id: 3,
    value: Type_of_education.DOCTORATE,
  },
  {
    label: 'Аспирант',
    id: 4,
    value: Type_of_education.POSTGRADUATE,
  },
  {
    label: 'Специалист',
    id: 5,
    value: Type_of_education.SPECIALIST,
  },
  {
    label: 'Ассоциат',
    id: 6,
    value: Type_of_education.ASSOCIATE,
  },
  {
    label: 'Диплом (5 лет)',
    id: 7,
    value: Type_of_education.DIPLOMA,
  },
]
