import { Attachment } from '@consta/uikit/Attachment'
import { Tag } from '@consta/uikit/Tag'
import { IconDownload } from '@consta/icons/IconDownload'
import { IconCopy } from '@consta/icons/IconCopy'

import { dateMappings } from 'shared/utils/dateMappings'

import { Activity } from 'store/activities/types'

import styles from './Content.module.css'
import { statusToUIActivity } from '../../../../shared/utils/statusMappings'
import iconEdit from '../../../../assets/icons/edit.svg'
import { StatusAuthData } from '../../../../shared/types/statusAuthData'
import { useNavigate } from 'react-router-dom'
export const Content = ({
  activityData,
  authData,
  id,
}: {
  activityData: Activity
  authData: StatusAuthData
  id: undefined | string
}) => {
  const navigate = useNavigate()
  const handleChangeActivity = () => {
    if (id === undefined) {
      return
    }
    navigate(`/edit-activity/${id}`)
  }
  const handleDownloadAttachment = (url: string) => {
    const extension = url.split('.').pop()
    const filename = url.split('/').pop()
    const link = document.createElement('a')
    link.href = url
    link.download = filename || `file.${extension}`
    link.click()
  }
  const status = statusToUIActivity(activityData.status)

  return (
    <div className={styles.blockInfoContainer}>
      <div className={styles.title}>
        <h3>Содержание</h3>
        {authData?.role === 'editor' && (
          <img
            style={{ cursor: 'pointer' }}
            src={iconEdit}
            alt='Изменить данные'
            onClick={handleChangeActivity}
          />
        )}
      </div>

      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Заголовок</div>
        <div className={styles.textRight}>
          {activityData.title}
          <IconCopy
            view='ghost'
            size='s'
            className={styles.iconCopy}
            onClick={() => navigator.clipboard.writeText(activityData.title)}
          />
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Текст статьи</div>
        <div className={styles.textRight}>
          {activityData.text}
          <IconCopy
            view='ghost'
            size='s'
            className={styles.iconCopy}
            onClick={() => navigator.clipboard.writeText(activityData.text)}
          />
        </div>
      </div>
      {status !== 'none' && (
        <div className={styles.textContainer}>
          <div className={styles.textLeft}>Статус</div>
          <div className={styles.textRight}>{status}</div>
        </div>
      )}

      {activityData.images.data && (
        <div className={styles.textContainer}>
          <div className={styles.textLeft}>Фотографии</div>
          <div className={styles.textRight}>
            <div className={styles.flexPhotoContainer}>
              {activityData.images.data.map((item: any, index: number) => (
                <div className={styles.photoContainer} key={index}>
                  <img
                    alt={'photo #' + index}
                    src={`${item.attributes.url}`}
                    className={styles.photo}
                  />
                  <a href={item.attributes.url} download className={styles.download}>
                    <IconDownload size='s' />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {activityData.documents.data && (
        <div className={styles.textContainer}>
          <div className={styles.textLeft}>Документы</div>
          <div className={styles.textRight}>
            <div className={styles.flexAttachment}>
              {activityData.documents.data.map((file: any, index: number) => (
                <Attachment
                  key={index}
                  fileName={file.attributes.name}
                  fileExtension={file.attributes.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                  fileDescription={file.attributes.createdAt}
                  buttonIcon={IconDownload}
                  onButtonClick={() => handleDownloadAttachment(file.attributes.url)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Дата проведения</div>
        <div className={styles.textRight}>
          {dateMappings(activityData.date)}
          <IconCopy
            view='ghost'
            size='s'
            className={styles.iconCopy}
            onClick={() => navigator.clipboard.writeText(dateMappings(activityData.date))}
          />
        </div>
      </div>
      {activityData.members.data.length > 0 && (
        <div className={styles.textContainer}>
          <div className={styles.textLeft}>Другие участники</div>
          <div className={styles.textRight}>
            <div className={styles.flexTag}>
              {activityData.members.data.map((item: any, index: number) => (
                <Tag
                  label={`${item.attributes.last_name} ${item.attributes.first_name[0]}. ${item.attributes.second_name[0]}.`}
                  key={index}
                  mode='info'
                  size='l'
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
