import { ActivityState } from './types'

import { ACTIONS, ActivityActions } from './actions'

export const activityReducer = (state: ActivityState, action: ActivityActions): ActivityState => {
  switch (action.type) {
    case ACTIONS.UPDATE_FIELD:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    case ACTIONS.UPDATE_CHECKBOX:
      return {
        ...state,
        [action.payload.key]: action.payload.checked,
      }
    default:
      return state
  }
}
