import { Activity } from 'store/activities/types'

import { IconCheck } from '@consta/icons/IconCheck'

import styles from './InteractionWithVoters.module.css'

const InteractionToggleItem = ({ title, toggle }: { title: string; toggle: boolean }) => {
  return (
    <>
      {toggle && (
        <div className={styles.textContainer}>
          <div className={styles.textLeft}>{title}</div>
          <div className={styles.textRight}>
            <IconCheck view='brand' size='s' />
          </div>
        </div>
      )}
    </>
  )
}

const InteractionNumberItem = ({ title, count }: { title: string; count: number }) => {
  return (
    <>
      {count !== 0 && (
        <div className={styles.textContainer}>
          <div className={styles.textLeft}>{title}</div>
          <div className={styles.textRight}>{count}</div>
        </div>
      )}
    </>
  )
}

export const InteractionWithVoters = ({ activityData }: { activityData: Activity }) => {
  return (
    <div className={styles.blockInfoContainer}>
      <h3 className={styles.title}>Взаимодействие с избирателями</h3>
      <InteractionToggleItem title='Личный прием' toggle={activityData.personal_reception} />
      <InteractionToggleItem title='Решенное обращение' toggle={activityData.solved_appeal} />
      <InteractionToggleItem title='Проведенное мероприятие' toggle={activityData.event_held} />
      <InteractionToggleItem
        title='Участие в собрании граждан'
        toggle={activityData.participation_in_citizenship_meeting}
      />
      <InteractionToggleItem
        title='Участие в сессии, комитете, комиссии и ином'
        toggle={activityData.participation_in_session}
      />
      <InteractionToggleItem
        title='Участие в общем собрании Совета в МО'
        toggle={activityData.participation_in_municipal_entity_meeting}
      />
      <InteractionToggleItem
        title='Обучающее мероприятие, организованное Советом МО'
        toggle={activityData.training_event_by_municipal_entity}
      />
      <InteractionToggleItem
        title='Обучающее мероприятие при участии членов Совета МО'
        toggle={activityData.training_event_with_participation_municipal_entity}
      />
      <InteractionToggleItem
        title='Проект муниципального уровня'
        toggle={activityData.municipal_project}
      />
      <InteractionToggleItem
        title='Проект краевого уровня'
        toggle={activityData.regional_project}
      />
      <InteractionToggleItem
        title='Законодательная инициатива МО'
        toggle={activityData.legislative_initiative_of_municipal_entity}
      />
      <InteractionToggleItem
        title='Законодательная инициатива ЗСК'
        toggle={activityData.legislative_initiative_of_ZSK}
      />
      <InteractionToggleItem
        title='Участие депутата ЗСК'
        toggle={activityData.participation_of_deputy_ZSK}
      />
      <InteractionToggleItem
        title='Участие депутата ГД'
        toggle={activityData.participation_of_deputy_GD}
      />

      <InteractionNumberItem
        title='Бюро президиума'
        count={parseInt(activityData.bureau_of_presidium) || 0}
      />

      <InteractionNumberItem
        title='Кол-во принятых обращений'
        count={parseInt(activityData.received_enquiries) || 0}
      />

      <InteractionNumberItem
        title='Кол-во депутатов, прошедших обученией'
        count={parseInt(activityData.trained_deputies) || 0}
      />
    </div>
  )
}
