import { itemsAutoCompleteTypeOfEducation } from './typeOfEducationMappingForEditCard'
import { EditUserInfoDto } from '../Api/edit-user-info.dto'
import { ReportStatusEnum } from '../types/reportStatusEnum'
import { FilteringOptionsForReport } from '../types/FilteringOptionsForReport'
import {
  statusReportToUITextColors,
  statusToUIActivity,
  statusToUIReport,
  statusToUIText,
} from './statusMappings'
import { dateMappingsCreatedAt } from './dateMappingsCreatedAt'
import { FilteringOptionsForUsers } from '../types/filteringOptionsForUsers'
import { ItemSelectStatus } from '../types/itemSelectStatus'
import { ItemSelectRole } from '../types/itemSelectRole'
import { itemsSelectStatus } from '../constants/itemsSelectStatus'
import { itemsSelectRole } from '../constants/itemsSelectRole'
import { rolesToUIMapping } from './rolesToUIMapping'
import { itemsSelectStatusActive } from '../constants/itemsSelectStatusActive'
import { dateMappingForFiltering, dateMappingFromQuery } from './dateMappingForFiltering'
import { dateMappings } from './dateMappings'
import { ItemCombobox } from '../types/ItemCombobox'
import { UserRole } from '../enums/UserRole'
export const updateEducationInformation = (data: EditUserInfoDto) => {
  return {
    ...data,
    educations: data.educations.map(item => {
      return {
        ...item,
        type_of_education: itemsAutoCompleteTypeOfEducation.find(
          (typeOfEdu: { value: string; label: string }) =>
            typeOfEdu.label === item.type_of_education || typeOfEdu.value === item.type_of_education
        )?.value,
      }
    }),
  }
}
export const getParamsFromUrlForActivities = (queryParams: URLSearchParams) => {
  return {
    status:
      itemsSelectStatusActive.find(
        (item: ItemSelectStatus) => item.statusFilter === queryParams.get('status')
      ) || null,
    municipality: queryParams.get('municipal_entity') || null,
    criteria: queryParams.getAll('criteria[]') || null,
    members: queryParams.getAll('members[]') || null,
    criteria_setting: queryParams.get('criteria_setting') || null,
    dateEvent: dateMappingFromQuery({
      from: queryParams.get('from'),
      until: queryParams.get('until'),
    }),
  }
}

export const getParamsFromUrlForUsers = (
  queryParams: URLSearchParams
): FilteringOptionsForUsers => {
  return {
    municipality: queryParams.get('municipal_entity') || null,
    search: queryParams.get('first_field') || null,
    status:
      itemsSelectStatus.find(
        (item: ItemSelectStatus) => item.statusFilter === queryParams.get('status')
      ) || null,
    role:
      itemsSelectRole.find((item: ItemSelectRole) => item.value === queryParams.get('role')) ||
      null,
  }
}
export const getParamsFromUrl = (queryParams: URLSearchParams): FilteringOptionsForReport => {
  let reportingPeriod
  if (queryParams.get('year') !== null && queryParams.get('quarter') !== null) {
    reportingPeriod = `${queryParams.get('quarter')} квартал ${queryParams.get('year')} года`
  }
  if (queryParams.get('year') !== null && queryParams.get('quarter') === null) {
    reportingPeriod = `${queryParams.get('year')} год`
  }
  return {
    status: (queryParams.get('status') as ReportStatusEnum) || null,
    municipality: queryParams.get('municipal_entity') || null,
    search: queryParams.get('first_field') || null,
    reportingPeriod,
  }
}

const stateToRequest: Record<string, string> = {
  municipality: 'municipal_entity',
  status: 'status',
  search: 'first_field',
}
const stateToRequestUser: Record<string, string> = {
  municipality: 'municipal_entity',
  status: 'status',
  search: 'first_field',
  role: 'role',
}
export const filterParamsUserReq = (filterParams: {
  municipality: null | string
  role: null | string
  status: null | string
  search: null | string
}) => {
  return Object.entries(filterParams).reduce(
    (acc, [key, value]) => (value ? { ...acc, [stateToRequestUser[key]]: value } : acc),
    {}
  )
}
export const filterParamsToRequestParams = (filterParams: {
  municipality?: string | null
  status?: string | null
  search?: string | null
  reportingPeriod?: string | null
}) => {
  const { reportingPeriod } = filterParams

  const splitPeriod = reportingPeriod?.split(' ')
  const params: Record<string, string> = {}

  if (splitPeriod && splitPeriod[2] !== undefined) {
    params.quarter = splitPeriod[0]
    params.year = splitPeriod[2]
  }
  if (splitPeriod && splitPeriod[2] === undefined) {
    params.year = splitPeriod[0]
  }
  return Object.entries(filterParams).reduce(
    (acc, [key, value]) => (value ? { ...acc, [stateToRequest[key]]: value } : acc),
    params
  )
}
const mapUserNavigate = {
  municipality: 'municipal_entity',
  status: 'status',
  search: 'first_field',
  role: 'role',
}

export interface filterParamsResponse {
  status?: string | null
  from?: string | null
  until?: string | null
  municipal_entity?: string | null
  criteria?: any[] | null
  members?: any[] | null
  criteria_setting?: boolean | null | string
}
export const filterParamsActivityReq = (filterParams: {
  municipal_entity?: null | string
  status?: null | string
  dateEvent?: any
  members?: null | ItemCombobox[]
  criteria?: null | ItemCombobox[]
  criteria_setting?: boolean | null
}): filterParamsResponse => {
  const response: filterParamsResponse = {}
  if (filterParams.municipal_entity) {
    response.municipal_entity = filterParams.municipal_entity
  }
  if (filterParams.status) {
    response.status = filterParams.status
  }
  if (filterParams.dateEvent && filterParams.dateEvent[0]) {
    response.from = dateMappingForFiltering(filterParams.dateEvent[0])
  }
  if (filterParams.dateEvent && filterParams.dateEvent[1]) {
    response.until = dateMappingForFiltering(filterParams.dateEvent[1])
  }
  if (filterParams.members) {
    response.members = filterParams.members?.map((item: ItemCombobox) => item.id)
  }
  if (filterParams.criteria) {
    response.criteria = filterParams.criteria?.map((item: ItemCombobox) => item.labelForBackend)
  }
  if (filterParams.criteria_setting) {
    response.criteria_setting = filterParams.criteria_setting
  }
  return response
}
export const getActivitiesNavigateUrl = (filterParameters: any) => {
  const params = []
  if (filterParameters.municipality) {
    params.push(`municipal_entity=${filterParameters.municipality}`)
  }
  if (filterParameters.status) {
    params.push(`status=${filterParameters.status.statusFilter}`)
  }
  if (filterParameters?.dateEvent && filterParameters?.dateEvent[0]) {
    params.push(`from=${dateMappingForFiltering(filterParameters.dateEvent[0])}`)
  }
  if (filterParameters?.dateEvent && filterParameters?.dateEvent[1]) {
    params.push(`until=${dateMappingForFiltering(filterParameters.dateEvent[1])}`)
  }
  if (filterParameters.members && filterParameters.members.length > 0) {
    params.push(
      `members[]=${filterParameters.members
        .map((item: ItemCombobox) => item.id)
        .join('&members[]=')}`
    )
  }
  if (filterParameters.criteria && filterParameters.criteria.length > 0) {
    params.push(
      `criteria[]=${filterParameters.criteria
        .map((item: ItemCombobox) => item.labelForBackend)
        .join('&criteria[]=')}`
    )
  }
  if (filterParameters.criteriaSetting) {
    params.push(`criteria_setting=${filterParameters.criteriaSetting}`)
  }
  return `/activity?${params.join('&')}`
}
export const getUsersNavigateUrl = (filterParameters: any) => {
  const params = ['municipality', 'status', 'search', 'role']
    .map((el: string) => {
      if (filterParameters[el]) {
        if (el === 'role') {
          return `${mapUserNavigate[el as keyof FilteringOptionsForUsers]}=${filterParameters[el]
            ?.value}`
        }
        if (el === 'status') {
          return `${mapUserNavigate[el as keyof FilteringOptionsForUsers]}=${filterParameters[el]
            ?.statusFilter}`
        }
        return `${mapUserNavigate[el as keyof FilteringOptionsForUsers]}=${filterParameters[el]}`
      }
      return null
    })
    .filter(el => el !== null)
  return `/users?${params.join('&')}`
}

export const getReportNavigateURL = (filterParameters: any) => {
  const splitFilterParams = filterParameters.reportingPeriod?.label?.split(' ') ?? null
  const params: any[] = ['status', 'search', 'year', 'quarter', 'municipality'].reduce(
    (acc: any[], item) => {
      if (
        Object.keys(stateToRequest).includes(item) &&
        filterParameters[item as keyof FilteringOptionsForReport]
      ) {
        const value =
          item === 'status'
            ? filterParameters[item as keyof FilteringOptionsForReport]?.statusFilter
            : filterParameters[item as keyof FilteringOptionsForReport]
        const key = stateToRequest[item]
        acc.push(`${key}=${value}`)
      }
      if ('quarter' === item && splitFilterParams[2] === undefined) {
        acc.push(`year=${splitFilterParams[0]}`)
      }
      if ('year' === item && splitFilterParams && splitFilterParams[2] !== undefined) {
        acc.push(`${item}=${splitFilterParams[2]}`)
      }
      if ('quarter' === item && splitFilterParams && splitFilterParams[2] !== undefined) {
        acc.push(`${item}=${splitFilterParams[0]}`)
      }
      return acc
    },
    []
  )
  return `/reports?${params.join('&')}`
}
export const structureActivity = (data: any[], lastNumber: number): any[] => {
  return data?.map((obj: any, index: number) => ({
    ...obj,
    serial: lastNumber === 0 ? index : lastNumber + index + 1,
    status: statusToUIActivity(obj.status),
    dateEvent: dateMappings(obj.date),
  }))
}
export const structureReport = (
  data: any[],
  role: UserRole,
  lastNumber: number
): {
  id: string
  municipal_entity: string
  status: string
  reportingPeriod: string
  dateDispatch: string
  type: 'year' | 'quarter'
}[] => {
  return data?.map((obj: any, index) => ({
    serial: lastNumber === 0 ? index : lastNumber + index + 1,
    id: obj.id,
    municipal_entity: obj.municipal_entity,
    status: statusToUIReport(obj.status, role),
    color: statusReportToUITextColors(obj.status),
    reportingPeriod:
      obj?.type === 'year' ? `${obj.year} год` : `${obj.quarter} квартал ${obj.year} года`,
    dateDispatch: dateMappingsCreatedAt(obj.createdAt),
    type: obj?.type ?? 'quarter',
  }))
}
export const structureUser = (
  data: any[],
  lastNumber: number
): {
  fullName: string
  id: string
  role: string
  email: string
  status: string
}[] => {
  return data?.map((obj: any, index: number) => ({
    serial: lastNumber === 0 ? index : lastNumber + index + 1,
    fullName: `${obj.last_name} ${obj.first_name} ${obj.second_name}`,
    id: obj.id,
    status: statusToUIText(obj.status),
    email: obj.email,
    role: rolesToUIMapping(obj.role),
  }))
}
export const structureUsers = (data: any[]) => {
  return data?.map((item: any) => ({
    label: `${item.last_name} ${item.first_name[0]}. ${item.second_name[0]}.`,
    id: item.id,
  }))
}
export const makeSecondArrayUnique = (mainArray: any[], secondArray: any[]) => {
  return secondArray
    ?.map(el => (mainArray.includes(el.label) ? null : el))
    .filter(el => el !== null)
}
