const styles = {
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    color: '#91a4ab',
  },
}

export const ReportViewListItem = ({
  value,
  text,
}: {
  value: number | string | boolean
  text: string
}) => {
  return (
    <div style={styles.topRow}>
      <p style={styles.text}>{text}</p>
      <p>{value}</p>
    </div>
  )
}
