import { UserInfoDTO } from 'shared/Api/users'
import { EditUserInfoDto } from 'shared/Api/edit-user-info.dto'

export enum ACTIONS {
  SET_DATA = 'SET_DATA',
  UPDATE_ROOT_PROPERTY = 'UPDATE_ROOT_PROPERTY',
  UPDATE_EDUCATION_PROPERTY = 'UPDATE_EDUCATION_PROPERTY',
  VALIDATE = 'VALIDATE',
  UPDATE_AVATAR = 'UPDATE_AVATAR',
  DELETE_AVATAR = 'DELETE_AVATAR',
  UPDATE_SECTION = 'UPDATE_SECTION',
  CREATE_SECTION = 'EDIT_USER_ACTION_CREATE',
  DELETE_SECTION = 'EDIT_USER_ACTION_DELETE',
  SEND_DATA_SUCCESS = 'SEND_DATA_SUCCESS',
  SEND_DATA_FAILED = 'SEND_DATA_FAILED',
}

export type PathSections = keyof Pick<
  EditUserInfoDto,
  'social_medias' | 'educations' | 'careers' | 'social_activities' | 'awards'
>

export interface SET_DATA {
  type: ACTIONS.SET_DATA
  payload: UserInfoDTO
}
export interface UPDATE_EDUCATION_PROPERTY {
  type: ACTIONS.UPDATE_EDUCATION_PROPERTY
  payload: {
    key: string
    value: string | number | boolean | object | null
  }
}
export interface UPDATE_ROOT_PROPERTY {
  type: ACTIONS.UPDATE_ROOT_PROPERTY
  payload: {
    key: string
    value: string | number | boolean | object | null
  }
}
export interface VALIDATE {
  type: ACTIONS.VALIDATE
}

export interface UPDATE_AVATAR {
  type: ACTIONS.UPDATE_AVATAR
  payload: {
    selectedFile: File[]
    file_deleted: boolean
  }
}

export interface DELETE_AVATAR {
  type: ACTIONS.DELETE_AVATAR
  payload: {
    file_deleted: boolean
  }
}

export interface UPDATE_SECTION {
  type: ACTIONS.UPDATE_SECTION
  payload: {
    path: PathSections
    index: number
    key: string
    value: string | number | boolean | object | null
  }
}
export interface CREATE_SECTION {
  type: ACTIONS.CREATE_SECTION
  payload: {
    path: PathSections
  }
}

export interface DELETE_SECTION {
  type: ACTIONS.DELETE_SECTION
  payload: {
    path: PathSections
    id: number
  }
}

export interface SEND_DATA_SUCCESS {
  type: ACTIONS.SEND_DATA_SUCCESS
}

export interface SEND_DATA_FAILED {
  type: ACTIONS.SEND_DATA_FAILED
  payload: {
    message: string
  }
}

export type EDIT_USER_ACTIONS =
  | SET_DATA
  | UPDATE_ROOT_PROPERTY
  | UPDATE_EDUCATION_PROPERTY
  | VALIDATE
  | UPDATE_AVATAR
  | DELETE_AVATAR
  | UPDATE_SECTION
  | CREATE_SECTION
  | DELETE_SECTION
  | SEND_DATA_SUCCESS
  | SEND_DATA_FAILED
