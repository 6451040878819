import { ReportState } from './types'
import { ReportStatusEnum } from '../../shared/types/reportStatusEnum'

export const initState: ReportState = {
  client: '',
  comment: '',
  municipal_entity: '',
  quarter: '',
  status: ReportStatusEnum.NEW,
  year: 0,
  type: 'quarter' || 'year',
  created_date: '',
  // additional fields
  original: {
    id: 0,
    personal_reception: 0,
    solved_appeal: 0,
    received_enquiries: 0,
    event_held: 0,
    participation_in_session: 0,
    participation_in_citizenship_meeting: 0,
    participation_in_municipal_entity_meeting: 0,
    training_event_by_municipal_entity: 0,
    training_event_with_participation_municipal_entity: 0,
    trained_deputies: 0,
    municipal_project: 0,
    regional_project: 0,
    legislative_initiative_of_municipal_entity: 0,
    legislative_initiative_of_ZSK: 0,
    participation_of_deputy_ZSK: 0,
    participation_of_deputy_GD: 0,
    bureau_of_presidium: 0,
    publication_links_municipal_entity: 0,
    publication_links_mass_media: 0,
    publication_links_in_internet_resources: 0,
    quantity_of_posted_activities_in_smd_web_site: 0,
  },
  actual: {
    id: 0,
    personal_reception: 0,
    solved_appeal: 0,
    received_enquiries: 0,
    event_held: 0,
    participation_in_session: 0,
    participation_in_citizenship_meeting: 0,
    participation_in_municipal_entity_meeting: 0,
    training_event_by_municipal_entity: 0,
    training_event_with_participation_municipal_entity: 0,
    trained_deputies: 0,
    municipal_project: 0,
    regional_project: 0,
    legislative_initiative_of_municipal_entity: 0,
    legislative_initiative_of_ZSK: 0,
    participation_of_deputy_ZSK: 0,
    participation_of_deputy_GD: 0,
    bureau_of_presidium: 0,
    publication_links_municipal_entity: 0,
    publication_links_mass_media: 0,
    publication_links_in_internet_resources: 0,
    quantity_of_posted_activities_in_smd_web_site: 0,
  },
  arguments: {
    id: 0,
    personal_reception: '',
    solved_appeal: '',
    received_enquiries: '',
    event_held: '',
    participation_in_session: '',
    participation_in_citizenship_meeting: '',
    participation_in_municipal_entity_meeting: '',
    training_event_by_municipal_entity: '',
    training_event_with_participation_municipal_entity: '',
    trained_deputies: '',
    municipal_project: '',
    regional_project: '',
    legislative_initiative_of_municipal_entity: '',
    legislative_initiative_of_ZSK: '',
    participation_of_deputy_ZSK: '',
    participation_of_deputy_GD: '',
    bureau_of_presidium: '',
    publication_links_municipal_entity: '',
    publication_links_mass_media: '',
    publication_links_in_internet_resources: '',
    quantity_of_posted_activities_in_smd_web_site: '',
  },
  files: {
    id: 0,
    personal_reception: [],
    solved_appeal: [],
    received_enquiries: [],
    event_held: [],
    participation_in_session: [],
    participation_in_citizenship_meeting: [],
    participation_in_municipal_entity_meeting: [],
    training_event_by_municipal_entity: [],
    training_event_with_participation_municipal_entity: [],
    trained_deputies: [],
    municipal_project: [],
    regional_project: [],
    legislative_initiative_of_municipal_entity: [],
    legislative_initiative_of_ZSK: [],
    participation_of_deputy_ZSK: [],
    participation_of_deputy_GD: [],
    bureau_of_presidium: [],
    publication_links_municipal_entity: [],
    publication_links_mass_media: [],
    publication_links_in_internet_resources: [],
    quantity_of_posted_activities_in_smd_web_site: [],
  },
  // system fields
  errorMessage: '',
  isDataChanged: false,
  isLoaded: false,
  mode: 'create',
}
