import { ActivityState } from './types'

type Sections =
  | 'interactions'
  | 'activities'
  | 'education'
  | 'projects'
  | 'normative_work'
  | 'interactions_with_ZSK'

type DisabledSection = {
  [p in Sections]: boolean
}

export const sectionMapping: {
  [p in Sections]: Array<keyof ActivityState>
} = {
  interactions: ['personal_reception', 'solved_appeal', 'received_enquiries'],
  activities: [
    'event_held',
    'participation_in_citizenship_meeting',
    'participation_in_session',
    'participation_in_municipal_entity_meeting',
  ],
  education: [
    'training_event_by_municipal_entity',
    'training_event_with_participation_municipal_entity',
    'trained_deputies',
  ],
  projects: ['municipal_project', 'regional_project'],
  normative_work: ['legislative_initiative_of_municipal_entity', 'legislative_initiative_of_ZSK'],
  interactions_with_ZSK: [
    /**
     *     Согласно задаче DEV-218: убрать логику выключения для ЗСК в деятельности
     *     нужно убрать следующие поля из учета фильтрации:
     *     'participation_of_deputy_ZSK'
     *     'participation_of_deputy_GD'
     */
    'bureau_of_presidium',
  ],
}

export function getActiveSection(state: ActivityState): DisabledSection {
  const calculate = (item: keyof ActivityState) => {
    return typeof state[item] === 'string'
      ? parseInt(state[item]) === 0 || state[item] === ''
      : !state[item]
  }

  const selectedSection: DisabledSection = {
    interactions: sectionMapping.interactions.every(calculate),
    activities: sectionMapping.activities.every(calculate),
    education: sectionMapping.education.every(calculate),
    projects: sectionMapping.projects.every(calculate),
    normative_work: sectionMapping.normative_work.every(calculate),
    interactions_with_ZSK: sectionMapping.interactions_with_ZSK.every(calculate),
  }

  const selectedSectionName = Object.keys(selectedSection).find(
    // @ts-ignore
    (key: string) => selectedSection[key] === false
  ) as Sections | undefined

  if (selectedSectionName) {
    const result = {
      interactions: false,
      activities: false,
      education: false,
      projects: false,
      normative_work: false,
      interactions_with_ZSK: false,
    }
    result[selectedSectionName] = true
    return result
  } else {
    return selectedSection
  }
}

export function isAnySectionSelected(state: ActivityState): boolean {
  const selectedSection = getActiveSection(state)
  // @ts-ignore
  return !Object.keys(selectedSection).every((key: string) => selectedSection[key])
}

export function prepareBodyRequest(state: ActivityState): object {
  const { status, images, documents, ...body } = state
  return body
}
