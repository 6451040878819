import { ItemSelectRole } from 'shared/types/itemSelectRole'
import { rolesToUIMapping } from '../utils/rolesToUIMapping'
import { UserRolesEnum } from '../enums/UserRole'

export const itemsSelectRole: ItemSelectRole[] = [
  {
    label: rolesToUIMapping(UserRolesEnum.EDITOR),
    value: UserRolesEnum.EDITOR,
    id: 1,
  },
  {
    label: rolesToUIMapping(UserRolesEnum.DEPUTY),
    value: UserRolesEnum.DEPUTY,
    id: 2,
  },
  {
    label: rolesToUIMapping(UserRolesEnum.THIRD_FACES),
    value: UserRolesEnum.THIRD_FACES,
    id: 3,
  },
]
