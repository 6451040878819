export function translationOfCriteria(date: string[]) {
  const translations: { [key: string]: string } = {
    personal_reception: 'Личный прием',
    solved_appeal: 'Решенное обращение',
    received_enquiries: 'Кол-во принятых обращений',
    event_held: 'Проведенное мероприятие',
    participation_in_citizenship_meeting: 'Участие в собрании граждан',
    participation_in_session: 'Участие в сессии, комитете, комиссии и ином',
    participation_in_municipal_entity_meeting: 'Участие в общем собрании Совета в МО',
    training_event_by_municipal_entity: 'Обучающее мероприятие, организованное Советом МО',
    training_event_with_participation_municipal_entity:
      'Обучающее мероприятие при участии членов Совета МО',
    trained_deputies: 'Кол-во депутатов, прошедших обучение',
    municipal_project: 'Проект муниципального уровня',
    regional_project: 'Проект краевого уровня',
    legislative_initiative_of_municipal_entity: 'Законодательная инициатива МО',
    legislative_initiative_of_zsk: 'Законодательная инициатива ЗСК',
    participation_of_deputy_zsk: 'Участие депутата ЗСК',
    participation_of_deputy_gd: 'Участие депутата ГД',
    bureau_of_presidium: 'Бюро президиума',
  }

  const translatedArray = date.map(item => translations[item] || item)
  return translatedArray
}
