import { FC, useState, useEffect } from 'react'

import axios from '../../shared/Api/axios'

import { LogoBlock } from 'components/LogoBlock/LogoBlock'
import { User } from '@consta/uikit/User'

import { NavLink, useNavigate } from 'react-router-dom'

import styles from './Header.module.css'
import { StatusAuthData } from '../../shared/types/statusAuthData'
import { UserRolesEnum } from '../../shared/enums/UserRole'

interface HeaderProps {
  authData: StatusAuthData
}

export const Header: FC<HeaderProps> = ({ authData }) => {
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const handleOutsideClick = () => {
      setIsMenuOpen(false)
    }
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const handleLogout = () => {
    axios
      .post('/api/auth/logout')
      .then(response => {
        if (response.data.success) {
          navigate('/auth')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleClickUser = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <div className={styles.wrapper}>
      <LogoBlock />
      <div className={styles.tabsContainer}>
        <NavLink
          to={'/board'}
          className={({ isActive }) => (isActive ? styles.active : styles.link)}
        >
          Доска
        </NavLink>
        <NavLink
          to={'/activity'}
          className={({ isActive }) => (isActive ? styles.active : styles.link)}
        >
          Деятельность
        </NavLink>
        <NavLink
          to={'/reports'}
          className={({ isActive }) => (isActive ? styles.active : styles.link)}
        >
          Отчетность
        </NavLink>
        {authData?.role === UserRolesEnum.EDITOR ? (
          <NavLink
            to={'/users'}
            className={({ isActive }) => (isActive ? styles.active : styles.link)}
          >
            Пользователи
          </NavLink>
        ) : null}
      </div>
      <div className={styles.userContainer}>
        {authData && (
          <User
            className={styles.user}
            onClick={handleClickUser}
            avatarUrl={authData?.image?.url}
            name={`${authData?.last_name} ${authData?.first_name}`}
          />
        )}
        {isMenuOpen && (
          <div className={styles.userMenu}>
            {authData?.role === UserRolesEnum.DEPUTY ? (
              <NavLink to={'/profile'}>
                <div>Профиль</div>
              </NavLink>
            ) : null}
            <div onClick={handleLogout}>Выйти из аккаунта</div>
          </div>
        )}
      </div>
    </div>
  )
}
