import { useState, useReducer, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import axios from '../../../shared/Api/axios'

import { IconAttach } from '@consta/icons/IconAttach'
import { IconTrash } from '@consta/icons/IconTrash'

import { Button } from '@consta/uikit/Button'
import { TextField } from '@consta/uikit/TextField'
import { Checkbox } from '@consta/uikit/Checkbox'
import { DragNDropField } from '@consta/uikit/DragNDropField'
import { DatePicker } from '@consta/uikit/DatePicker'
import { Combobox } from '@consta/uikit/Combobox'
import { Attachment } from '@consta/uikit/Attachment'

import { useForm } from 'react-hook-form'

import { InputGroup } from 'components/InputGroup/InputGroup'
import { FormSection } from 'components/EditUsersCards/FormSection/FormSection'
import { dateMappingForFiltering } from 'shared/utils/dateMappingForFiltering'

import {
  activityReducer,
  initState,
  ACTIONS,
  isAnySectionSelected,
  prepareBodyRequest,
  ActivityState,
} from 'store/activities'

import styles from './CreateActivity.module.css'
import { StatusAuthData } from '../../../shared/types/statusAuthData'
import { findMembers } from '../../../shared/Api/activities'
import { makeSecondArrayUnique, structureUsers } from '../../../shared/utils/helper'

export interface Links {
  publication_links_municipal_entity: string[]
  publication_links_mass_media: string[]
  publication_links_in_internet_resources: string[]
}

export const CreateActivity = ({ authData }: { authData: StatusAuthData }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    title: string
    text: string
    datePicker: string
  }>()

  const [isSaveButtonLocked, setIsSaveButtonLocked] = useState(false)

  const navigate = useNavigate()

  const [userProfile, dispatch] = useReducer(activityReducer, initState)

  const isAnySelected = useMemo(() => isAnySectionSelected(userProfile), [userProfile])

  const isMeDisabled = (key: keyof ActivityState) =>
    isAnySelected && (userProfile[key] === false || userProfile[key] === '')

  const [link, setLink] = useState<Links>({
    publication_links_municipal_entity: [],
    publication_links_mass_media: [],
    publication_links_in_internet_resources: [],
  })

  const [numberPublications, setNumberPublications] = useState({
    publication_links_municipal_entity: '0',
    publication_links_mass_media: '0',
    publication_links_in_internet_resources: '0',
  })

  const [valueCombobox, setValueCombobox] = useState<{ label: string; id: number }[] | null>([])

  const [itemsCombobox, setItemsCombobox] = useState<{ label: string; id: number }[]>([])
  useEffect(() => {
    if (!authData.municipal_entity) {
      return
    }
    axios
      .get('/api/clients/find-members', {
        params: {
          municipal_entity: authData.municipal_entity,
        },
      })
      .then(response => {
        const transformedData: { label: string; id: number }[] = response.data.map((item: any) => {
          return {
            label: `${item.last_name} ${item.first_name[0]}. ${item.second_name[0]}.`,
            id: item.id,
          }
        })
        setItemsCombobox(transformedData)
      })
      .catch(error => {
        console.error(error)
      })
  }, [authData])

  const [datePicker, setDatePicker] = useState<Date | null>(new Date())

  const [filesImg, setFilesImg] = useState<File[]>([])

  const [files, setFiles] = useState<File[]>([])

  const [isParticipantPristine, setIsParticipantPristine] = useState<boolean>(true)

  const handleDropFilesImg = (droppedFiles: File[]) => {
    setFilesImg([...filesImg, ...droppedFiles])
  }

  const handleDeleteImg = (index: number) => {
    const updatedFiles = [...filesImg.slice(0, index), ...filesImg.slice(index + 1)]
    setFilesImg(updatedFiles)
  }

  const handleDeleteAttachment = (index: number) => {
    const updatedFiles = [...files.slice(0, index), ...files.slice(index + 1)]
    setFiles(updatedFiles)
  }

  const handleDropFiles = (droppedFiles: File[]) => {
    setFiles([...files, ...droppedFiles])
  }

  const handleChangeNumberPublications = ({ obj, name }: { obj: any; name: keyof Links }) => {
    setNumberPublications(prev => ({
      ...prev,
      [name]: obj.value,
    }))
    setLink(prev => ({
      ...prev,
      [name]: Array.from({ length: parseInt(obj.value) }, (_, index) => prev[name][index] || ''),
    }))
  }

  const handleLinkChange = (name: keyof Links, index: number, obj: any) => {
    setLink(prev => ({
      ...prev,
      [name]: prev[name].map((item, i) => (i === index ? obj.value : item)),
    }))
  }

  const handleChangeCheckbox = (object: {
    e: React.ChangeEvent<HTMLInputElement>
    checked: boolean
  }) => {
    dispatch({
      type: ACTIONS.UPDATE_CHECKBOX,
      payload: {
        key: object.e.target.name,
        checked: object.checked,
      },
    })
  }

  const handleChangeTextField = ({ value, name }: any) => {
    dispatch({
      type: ACTIONS.UPDATE_FIELD,
      payload: {
        key: name,
        value,
      },
    })
  }
  const handleInputChange = async (value: any) => {
    const isExist = itemsCombobox.find(el => el.label === value)
    if (isExist) {
      return
    }
    const options: {
      municipal_entity?: string
      full_name?: string
    } = {
      municipal_entity: authData.municipal_entity ?? '',
    }
    if (value) {
      options.full_name = value
    }
    const newMembers = await findMembers(options)
    const structuredNewMembers = structureUsers(newMembers)
    const labelsArr = itemsCombobox.map(el => el.label)
    const newUniqueMembers = makeSecondArrayUnique(labelsArr, structuredNewMembers) ?? []
    return setItemsCombobox([...itemsCombobox, ...newUniqueMembers])
  }
  const handleSave = () => {
    setIsSaveButtonLocked(true)
    const memberIds = valueCombobox?.map(item => item.id)
    const formData = new FormData()
    filesImg.forEach(file => {
      formData.append(`files.images`, file)
    })
    files.forEach(file => {
      formData.append(`files.documents`, file)
    })

    formData.append(
      'data',
      JSON.stringify({
        ...prepareBodyRequest(userProfile),
        date: dateMappingForFiltering(datePicker),
        members: memberIds,
        publication_links_municipal_entity: link.publication_links_municipal_entity,
        publication_links_mass_media: link.publication_links_mass_media,
        publication_links_in_internet_resources: link.publication_links_in_internet_resources,
        received_enquiries: parseInt(userProfile.received_enquiries),
        trained_deputies: parseInt(userProfile.trained_deputies),
        bureau_of_presidium: parseInt(userProfile.bureau_of_presidium),
      })
    )
    axios
      .post(`/api/activities`, formData)
      .then(() => {
        navigate('/activity')
      })
      .catch(error => {
        console.error(error)
        setIsSaveButtonLocked(false)
      })
  }

  const validateParticipants: { status: 'alert'; caption: string } | {} = useMemo(() => {
    return {
      ...(!isParticipantPristine && (!valueCombobox || valueCombobox.length === 0)
        ? { status: 'alert', caption: 'Поле обязательно для заполнения' }
        : {}),
    }
  }, [valueCombobox, isParticipantPristine])

  const isFormValid = useMemo(() => {
    return (
      userProfile?.title?.length > 0 &&
      userProfile?.text?.length > 0 &&
      valueCombobox &&
      valueCombobox.length > 0 &&
      isAnySectionSelected(userProfile)
    )
  }, [userProfile, valueCombobox])

  const isSaveButtonDisabled = useMemo(
    () => (!isFormValid ? true : isSaveButtonLocked),
    [isFormValid, isSaveButtonLocked]
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <h2 className={styles.mainTitle}>Новая деятельность</h2>
        <div>
          <InputGroup label='Заголовок' labelRight='максимум 200 символов' required>
            <TextField
              {...register('title', {
                required: 'Поле обязательно для заполнения',
                maxLength: {
                  value: 200,
                  message: 'Максимальная длина поля: 200 символов',
                },
              })}
              status={errors?.title && 'alert'}
              caption={errors?.title && errors?.title?.message}
              type='text'
              width='full'
              placeholder='Новая новость'
              name='title'
              value={userProfile.title}
              onChange={handleChangeTextField}
            />
          </InputGroup>
          <InputGroup label='Текст статьи' labelRight='максимум 5000 символов' required>
            <TextField
              {...register('text', {
                required: 'Поле обязательно для заполнения',
                maxLength: {
                  value: 5000,
                  message: 'Максимальная длина поля: 5000 символов',
                },
              })}
              status={errors?.text && 'alert'}
              caption={errors?.text && errors?.text?.message}
              width='full'
              type='textarea'
              minRows={7}
              maxRows={13}
              placeholder='Введите текст'
              className={styles.fieldIndentation}
              name='text'
              value={userProfile.text}
              onChange={handleChangeTextField}
            />
          </InputGroup>
          <InputGroup label='Фотографии' labelRight='максимум 10 шт.'>
            <DragNDropField
              multiple={true}
              onDropFiles={handleDropFilesImg}
              maxSize={16 * 1024 * 1024}
              disabled={filesImg.length >= 10}
              className={filesImg.length >= 10 ? undefined : styles.dragNDrop}
              accept={['.png', '.jpeg', '.jpg']}
            >
              {({ openFileDialog }) => (
                <>
                  <div className={styles.textTop}>Перетащите фото сюда или нажмите на кнопку </div>
                  <div className={styles.textBottom}>Поддерживаемые форматы: PNG, JPEG, JPG</div>
                  <Button
                    onClick={openFileDialog}
                    label='Загрузить файл'
                    iconLeft={IconAttach}
                    view='ghost'
                    size='s'
                  />
                </>
              )}
            </DragNDropField>
            <div className={styles.imageContainer}>
              {filesImg.map((file, index) => (
                <div className={styles.avatarContainer} key={index}>
                  <IconTrash
                    className={styles.iconTrash}
                    onClick={() => handleDeleteImg(index)}
                    size='l'
                  />
                  <img src={URL.createObjectURL(file)} alt={file.name} className={styles.avatar} />
                </div>
              ))}
            </div>
          </InputGroup>
          <InputGroup label='Файлы' labelRight='максимум 10 шт.'>
            <DragNDropField
              multiple={true}
              onDropFiles={handleDropFiles}
              maxSize={16 * 1024 * 1024}
              disabled={files.length >= 10}
              className={files.length >= 10 ? undefined : styles.dragNDrop}
            >
              {({ openFileDialog }) => (
                <>
                  <div className={styles.textTop}>Перетащите файлы сюда или нажмите на кнопку</div>
                  <div className={styles.textBottom}>Поддерживаемые форматы: PDF, DOCX</div>
                  <Button
                    onClick={openFileDialog}
                    label='Загрузить файл'
                    iconLeft={IconAttach}
                    view='ghost'
                    size='s'
                  />
                </>
              )}
            </DragNDropField>
            <div className={styles.attachmentContainer}>
              {files.map((file, index) => (
                <Attachment
                  key={index}
                  fileName={file.name}
                  fileExtension={file.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                  fileDescription={file.type}
                  buttonIcon={IconTrash}
                  onButtonClick={() => handleDeleteAttachment(index)}
                />
              ))}
            </div>
          </InputGroup>
          <InputGroup
            label='Дата проведения'
            labelRight='Не может быть ранее одной недели от текущего момента.'
            required
          >
            <DatePicker
              minDate={new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)}
              maxDate={new Date()}
              className={styles.fieldDatePicker}
              readOnly={true}
              value={datePicker}
              onChange={({ value }) => setDatePicker(value)}
            />
          </InputGroup>
          <InputGroup label='Участники' required>
            <Combobox
              label=''
              items={itemsCombobox}
              placeholder='ФИО участника'
              className={styles.fieldIndentation}
              value={valueCombobox}
              multiple
              onInputChange={({ value }) => handleInputChange(value)}
              onChange={({ value }) => setValueCombobox(value)}
              onFocus={() => setIsParticipantPristine(false)}
              {...validateParticipants}
            />
          </InputGroup>
          <FormSection title={'Взаимодействие с избирателями'}>
            <InputGroup>
              <div className={styles.checkboxGroup}>
                <Checkbox
                  checked={userProfile.personal_reception}
                  label='Личный прием'
                  onChange={handleChangeCheckbox}
                  name='personal_reception'
                  view='ghost'
                  disabled={isMeDisabled('personal_reception')}
                />
                <Checkbox
                  checked={userProfile.solved_appeal}
                  label='Решенное обращение'
                  onChange={handleChangeCheckbox}
                  name='solved_appeal'
                  view='ghost'
                  disabled={isMeDisabled('solved_appeal')}
                />
              </div>
            </InputGroup>
            <InputGroup label='Кол-во принятых обращений'>
              <TextField
                type='number'
                width='full'
                name='received_enquiries'
                value={userProfile.received_enquiries}
                onChange={handleChangeTextField}
                incrementButtons={false}
                disabled={isMeDisabled('received_enquiries')}
              />
            </InputGroup>
          </FormSection>
          <FormSection title={'Мероприятия'}>
            <InputGroup>
              <div className={styles.checkboxGroup}>
                <Checkbox
                  checked={userProfile.event_held}
                  label='Проведенное мероприятие'
                  onChange={handleChangeCheckbox}
                  name='event_held'
                  view='ghost'
                  disabled={isMeDisabled('event_held')}
                />
                <Checkbox
                  checked={userProfile.participation_in_citizenship_meeting}
                  label='Участие в собрании граждан'
                  onChange={handleChangeCheckbox}
                  name='participation_in_citizenship_meeting'
                  view='ghost'
                  disabled={isMeDisabled('participation_in_citizenship_meeting')}
                />
                <Checkbox
                  checked={userProfile.participation_in_session}
                  label='Участие в сессии, комитете, комиссии и ином'
                  onChange={handleChangeCheckbox}
                  name='participation_in_session'
                  view='ghost'
                  disabled={isMeDisabled('participation_in_session')}
                />
                <Checkbox
                  checked={userProfile.participation_in_municipal_entity_meeting}
                  label='Участие в общем собрании Совета в МО'
                  onChange={handleChangeCheckbox}
                  name='participation_in_municipal_entity_meeting'
                  view='ghost'
                  disabled={isMeDisabled('participation_in_municipal_entity_meeting')}
                />
              </div>
            </InputGroup>
          </FormSection>
          <FormSection title={'Обучение'}>
            <InputGroup>
              <div className={styles.checkboxGroup}>
                <Checkbox
                  checked={userProfile.training_event_by_municipal_entity}
                  label='Обучающее мероприятие, организованное Советом МО'
                  onChange={handleChangeCheckbox}
                  name='training_event_by_municipal_entity'
                  view='ghost'
                  disabled={isMeDisabled('training_event_by_municipal_entity')}
                />
                <Checkbox
                  checked={userProfile.training_event_with_participation_municipal_entity}
                  label='Обучающее мероприятие при участии членов Совета МО'
                  onChange={handleChangeCheckbox}
                  name='training_event_with_participation_municipal_entity'
                  view='ghost'
                  disabled={isMeDisabled('training_event_with_participation_municipal_entity')}
                />
              </div>
            </InputGroup>
            <InputGroup label='Кол-во депутатов, прошедших обученией'>
              <TextField
                type='number'
                width='full'
                name='trained_deputies'
                value={userProfile.trained_deputies}
                onChange={handleChangeTextField}
                incrementButtons={false}
                disabled={isMeDisabled('trained_deputies')}
              />
            </InputGroup>
          </FormSection>
          <FormSection title={'Проекты'}>
            <InputGroup>
              <div className={styles.checkboxGroup}>
                <Checkbox
                  checked={userProfile.municipal_project}
                  label='Проект муниципального уровня'
                  onChange={handleChangeCheckbox}
                  name='municipal_project'
                  view='ghost'
                  disabled={isMeDisabled('municipal_project')}
                />
                <Checkbox
                  checked={userProfile.regional_project}
                  label='Проект краевого уровня'
                  onChange={handleChangeCheckbox}
                  name='regional_project'
                  view='ghost'
                  disabled={isMeDisabled('regional_project')}
                />
              </div>
            </InputGroup>
          </FormSection>
          <FormSection title={'Нормотворческая деятельность'}>
            <InputGroup>
              <div className={styles.checkboxGroup}>
                <Checkbox
                  checked={userProfile.legislative_initiative_of_municipal_entity}
                  label='Законодательная инициатива МО'
                  onChange={handleChangeCheckbox}
                  name='legislative_initiative_of_municipal_entity'
                  view='ghost'
                  disabled={isMeDisabled('legislative_initiative_of_municipal_entity')}
                />
                <Checkbox
                  checked={userProfile.legislative_initiative_of_ZSK}
                  label='Законодательная инициатива ЗСК'
                  onChange={handleChangeCheckbox}
                  name='legislative_initiative_of_ZSK'
                  view='ghost'
                  disabled={isMeDisabled('legislative_initiative_of_ZSK')}
                />
              </div>
            </InputGroup>
          </FormSection>
          <FormSection title={'Взаимодействие с ЗСК '}>
            <InputGroup>
              <div className={styles.checkboxGroup}>
                <Checkbox
                  checked={userProfile.participation_of_deputy_ZSK}
                  label='Участие депутата ЗСК'
                  onChange={handleChangeCheckbox}
                  name='participation_of_deputy_ZSK'
                  view='ghost'
                />
                <Checkbox
                  checked={userProfile.participation_of_deputy_GD}
                  label='Участие депутата ГД'
                  onChange={handleChangeCheckbox}
                  name='participation_of_deputy_GD'
                  view='ghost'
                />
              </div>
            </InputGroup>
            <InputGroup label='Бюро президиума'>
              <TextField
                type='number'
                width='full'
                name='bureau_of_presidium'
                value={userProfile.bureau_of_presidium}
                onChange={handleChangeTextField}
                incrementButtons={false}
                disabled={isMeDisabled('bureau_of_presidium')}
              />
            </InputGroup>
          </FormSection>
          <FormSection title={'Информационная деятельность'}>
            <InputGroup label='Кол-во публикаций на оф. сайтах МО'>
              <TextField
                type='number'
                width='full'
                className={styles.fieldIndentation}
                onChange={obj =>
                  handleChangeNumberPublications({
                    obj,
                    name: 'publication_links_municipal_entity',
                  })
                }
                value={numberPublications.publication_links_municipal_entity}
                min='0'
                max='30'
                readOnly={true}
              />
              {link.publication_links_municipal_entity.map((value, index) => (
                <TextField
                  width='full'
                  key={index}
                  type='text'
                  placeholder='Ссылка на публикацию'
                  className={styles.fieldIndentation}
                  value={value}
                  onChange={obj =>
                    handleLinkChange('publication_links_municipal_entity', index, obj)
                  }
                />
              ))}
            </InputGroup>
            <InputGroup label='Кол-во публикаций в СМИ'>
              <TextField
                type='number'
                width='full'
                className={styles.fieldIndentation}
                onChange={obj =>
                  handleChangeNumberPublications({
                    obj,
                    name: 'publication_links_mass_media',
                  })
                }
                value={numberPublications.publication_links_mass_media}
                min='0'
                max='30'
                readOnly={true}
              />
              {link.publication_links_mass_media.map((value, index) => (
                <TextField
                  width='full'
                  key={index}
                  type='text'
                  placeholder='Ссылка на публикацию'
                  className={styles.fieldIndentation}
                  value={value}
                  onChange={obj => handleLinkChange('publication_links_mass_media', index, obj)}
                />
              ))}
            </InputGroup>
            <InputGroup label='Кол-во публикаций на других интернет-ресурсах'>
              <TextField
                type='number'
                width='full'
                className={styles.fieldIndentation}
                onChange={obj =>
                  handleChangeNumberPublications({
                    obj,
                    name: 'publication_links_in_internet_resources',
                  })
                }
                value={numberPublications.publication_links_in_internet_resources}
                min='0'
                max='30'
                readOnly={true}
              />
              {link.publication_links_in_internet_resources.map((value, index) => (
                <TextField
                  width='full'
                  key={index}
                  type='text'
                  placeholder='Ссылка на публикацию'
                  className={styles.fieldIndentation}
                  value={value}
                  onChange={obj =>
                    handleLinkChange('publication_links_in_internet_resources', index, obj)
                  }
                />
              ))}
            </InputGroup>
          </FormSection>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Button
          label='Отменить'
          view='ghost'
          style={{ width: '240px', marginRight: '24px' }}
          onClick={() => navigate('/activity')}
        />
        <Button
          label='Сохранить'
          disabled={isSaveButtonDisabled}
          style={{ width: '240px' }}
          onClick={handleSubmit(handleSave)}
        />
      </div>
    </div>
  )
}
