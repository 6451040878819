import { Button } from '@consta/uikit/Button'
import { IconAdd } from '@consta/icons/IconAdd'

import { FormSection } from '../FormSection/FormSection'
import { FormInput } from '../FormInput/FormInput'
import { FormSectionInterface, Field, FormSectionWithListsInterface } from 'store/editUser'

import IconDelete from 'assets/icons/delete.svg'

import styles from './SectionConstructor.module.css'

export const SectionConstructor = ({
  formSection,
  handleChangeSection,
  handleChangeDeleteSection,
  handleClickAdditionFields,
}: {
  formSection: FormSectionInterface | FormSectionWithListsInterface
  handleChangeSection: (index: number, value: any, innerIndex?: number) => void
  handleChangeDeleteSection: (index: number) => void
  handleClickAdditionFields: () => void
}) => {
  const isDeleteIconShowen =
    (formSection.fields as Array<unknown>).filter((x: any) => x?.isremovable === 'true').length > 1

  return (
    <>
      <FormSection title={formSection.title}>
        {formSection.fields.map((field: Field | Field[], index: number) => {
          if (Array.isArray(field)) {
            return (
              <div className={styles.fieldsGroup} key={index}>
                {formSection.fields.length >= 2 && (
                  <img
                    src={IconDelete}
                    onClick={() => handleChangeDeleteSection(index)}
                    className={styles.cart}
                    alt='delete icon'
                  />
                )}
                {field.map((innerField: Field, innerIndex: number) => {
                  const { label, required, type, items, ...textFields } = innerField
                  return (
                    <FormInput
                      key={innerIndex}
                      label={label}
                      required={required || false}
                      type={type}
                      items={items ?? [{ label: '', id: 0 }]}
                      onChange={(value: any) => handleChangeSection(index, value, innerIndex)}
                      {...textFields}
                    />
                  )
                })}
              </div>
            )
          } else {
            const { label, required, type, items, ...textFields } = field
            return (
              <div key={index} className={styles.fieldsGroupMainInfo}>
                {isDeleteIconShowen && index > 7 && (
                  <img
                    src={IconDelete}
                    onClick={() => handleChangeDeleteSection(index - 7)}
                    alt='delete icon'
                    className={styles.cart}
                  />
                )}
                <FormInput
                  label={label}
                  required={required || false}
                  type={type}
                  items={items ?? [{ label: '', id: 0 }]}
                  onChange={(value: any) => handleChangeSection(index, value)}
                  {...textFields}
                />
              </div>
            )
          }
        })}
        {formSection.button && (
          <Button
            label={formSection.button.label}
            width='full'
            view={formSection.button.view}
            iconLeft={IconAdd}
            style={{ width: '588px', marginLeft: '282px' }}
            onClick={handleClickAdditionFields}
          />
        )}
      </FormSection>
    </>
  )
}
