import styles from './Awards.module.css'
import { UserInfoDTO } from 'shared/Api/users'

export const Awards = ({ user }: { user: UserInfoDTO }) => {
  return (
    <div className={styles.blockInfoContainer}>
      <h3 className={styles.title}>Награды</h3>
      {user.awards.length === 0 ? (
        <div className={styles.noInformation}>Информация не заполнена</div>
      ) : (
        user.awards.map((item, index) => {
          return (
            <div key={index} className={styles.infoContainer}>
              <div className={styles.textContainer}>
                <div className={styles.textLeft}>Название награды</div>
                <div className={styles.textRight}>{item.description}</div>
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}
