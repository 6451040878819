import { Activity } from 'store/activities/types'

import styles from './InformationActivities.module.css'

const LinksItem = ({ title, items }: { title: string; items: string[] }) => {
  const isEmpty = items.length === 0
  return (
    <>
      {!isEmpty && (
        <div className={styles.textContainer}>
          <div className={styles.textLeft}>{title}</div>
          <div className={styles.textRight}>{items.length}</div>
        </div>
      )}
      {items.map((item, index) => (
        <div className={styles.textContainer} key={index}>
          <a className={styles.link} href={item} target='_blank' rel='noreferrer'>
            {item}
          </a>
        </div>
      ))}
    </>
  )
}

export const InformationActivities = ({ activityData }: { activityData: Activity }) => {
  const isAllEmpty =
    (!activityData.publication_links_municipal_entity ||
      activityData.publication_links_municipal_entity.length === 0) &&
    (!activityData.publication_links_mass_media ||
      activityData.publication_links_mass_media.length === 0) &&
    (!activityData.publication_links_in_internet_resources ||
      activityData.publication_links_in_internet_resources.length === 0)

  return (
    <>
      {!isAllEmpty && (
        <div className={styles.blockInfoContainer}>
          <h3 className={styles.title}>Информационная деятельность</h3>
          <LinksItem
            items={activityData.publication_links_municipal_entity}
            title='Кол-во публикаций на оф. сайтах МО'
          />
          <LinksItem
            items={activityData.publication_links_mass_media}
            title={'Кол-во публикаций в СМИ'}
          />
          <LinksItem
            items={activityData.publication_links_in_internet_resources}
            title={'Кол-во публикаций на других интернет-ресурсах'}
          />
        </div>
      )}
    </>
  )
}
