import { FC } from 'react'

import { useForm } from 'react-hook-form'

import { TextField } from '@consta/uikit/TextField'
import { Button } from '@consta/uikit/Button'
import { Select } from '@consta/uikit/Select'

import { itemsSelectModalCreateUser } from 'shared/constants/itemsSelectModalCreateUser'
import { itemsAutoCompleteMunicipality } from 'shared/constants/itemsAutoCompleteMunicipality'
import { MunicipalityItem } from 'shared/types/MunicipalityItem'
import { CreateUserFormState } from 'shared/types/createUserFormState'
import { ItemSelectModalCreateUser } from 'shared/types/itemSelectModalCreateUser'
import axios from 'shared/Api/axios'

import styles from './ModalCreateUser.module.css'
import { UserRolesEnum } from '../../../shared/enums/UserRole'

interface ModalCreateUserProps {
  isOpened: boolean
  onToggle: (isOpened: boolean) => void
}

export const ModalCreateUser: FC<ModalCreateUserProps> = ({ isOpened, onToggle }) => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm<CreateUserFormState>()

  const handleChange = (
    key: keyof CreateUserFormState,
    value: string | ItemSelectModalCreateUser | MunicipalityItem | null
  ) => {
    clearErrors(key)
    const _value = typeof value === 'string' ? value : value?.label ?? ''
    setValue(key, _value)
  }

  const getSelectedItemByLabel = (key: 'role' | 'municipality', label?: string | undefined) => {
    if (!label) {
      label = getValues()[key]
    }
    return {
      role: itemsSelectModalCreateUser,
      municipality: itemsAutoCompleteMunicipality,
    }[key].find(item => item.label === label)
  }

  const getValueFromSelectedItem = (
    item: ItemSelectModalCreateUser | MunicipalityItem | undefined
  ) => {
    return item?.value ?? ''
  }

  const createUser = () => {
    const {
      email,
      first_name,
      last_name,
      second_name,
      role: roleLabel,
      municipality: municipalityLabel,
    } = getValues()
    const role = getValueFromSelectedItem(getSelectedItemByLabel('role', roleLabel))
    const municipality = getValueFromSelectedItem(
      getSelectedItemByLabel('municipality', municipalityLabel)
    )
    const requestData = {
      first_name,
      last_name,
      second_name,
      role,
      municipal_entity: municipality,
      ...(role === UserRolesEnum.THIRD_FACES ? {} : { email }),
    }

    const endpoint =
      role === UserRolesEnum.THIRD_FACES ? '/api/clients' : '/api/clients/create-deputy'

    axios.post(endpoint, requestData).then(handeCancel).catch(console.error)
  }

  const handeCancel = () => {
    onToggle(!isOpened)
    reset()
  }

  return (
    <>
      {isOpened && (
        <div className={styles.modal}>
          <div>
            <h3 className={styles.title}>Создание пользователя</h3>
            <Select
              {...register('role', {
                required: 'Обязательное поле',
              })}
              status={errors.role && 'alert'}
              caption={errors?.role?.message}
              label='Роль'
              labelPosition='top'
              name='role'
              required={true}
              items={itemsSelectModalCreateUser}
              value={getSelectedItemByLabel('role', getValues().role)}
              onChange={e => handleChange('role', e.value)}
              style={{ marginBottom: '16px' }}
              dropdownClassName={styles.dropdown}
            />
            <Select
              {...register('municipality', {
                required: 'Обязательное поле',
              })}
              status={errors.municipality && 'alert'}
              caption={errors?.municipality?.message}
              label='Муниципальное образование'
              labelPosition='top'
              name='municipality'
              required={true}
              items={itemsAutoCompleteMunicipality}
              value={getSelectedItemByLabel('municipality', getValues().municipality)}
              onChange={e => handleChange('municipality', e.value)}
              style={{ marginBottom: '16px' }}
              dropdownClassName={styles.dropdown}
            />
            <TextField
              {...register('last_name', {
                required: 'Обязательное поле',
              })}
              status={errors.last_name && 'alert'}
              caption={errors?.last_name?.message}
              label='Фамилия'
              labelPosition='top'
              required={true}
              onChange={e => handleChange('last_name', e.value)}
              value={getValues().last_name}
              name={'last_name'}
              width='full'
              style={{ marginBottom: '16px' }}
            />
            <TextField
              {...register('first_name', {
                required: 'Обязательное поле',
              })}
              status={errors.first_name && 'alert'}
              caption={errors?.first_name?.message}
              label='Имя'
              labelPosition='top'
              required={true}
              value={getValues().first_name}
              onChange={e => handleChange('first_name', e.value)}
              name={'first_name'}
              width='full'
              style={{ marginBottom: '16px' }}
            />
            <TextField
              {...register('second_name', {
                required: 'Обязательное поле',
              })}
              status={errors.second_name && 'alert'}
              caption={errors?.second_name?.message}
              label='Отчество'
              labelPosition='top'
              required={true}
              value={getValues().second_name}
              onChange={e => handleChange('second_name', e.value)}
              name={'second_name'}
              width='full'
              style={{ marginBottom: '16px' }}
            />
            {getValueFromSelectedItem(getSelectedItemByLabel('role')) !==
              UserRolesEnum.THIRD_FACES && (
              <TextField
                {...register('email', {
                  required: 'Обязательное поле',
                  pattern: /^\S+@\S+$/i,
                })}
                status={errors.email && 'alert'}
                caption={errors.email && (errors?.email?.message || 'Неправильный email')}
                label='Email'
                labelPosition='top'
                value={getValues().email}
                onChange={e => handleChange('email', e.value)}
                name={'email'}
                width='full'
              />
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              label='отменить'
              view='ghost'
              onClick={handeCancel}
              style={{ width: '152px' }}
            />
            <Button label='создать' onClick={handleSubmit(createUser)} style={{ width: '152px' }} />
          </div>
        </div>
      )}
    </>
  )
}
