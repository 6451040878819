import { FC } from 'react'

import axios from '../../shared/Api/axios'

import { Button } from '@consta/uikit/Button'

import styles from './ModalForBlocking.module.css'

interface ModalForBlocking {
  id: string | undefined
  isModallockOpened: boolean
  setIsModallockOpened: (isOpen: boolean) => void
  setIsUserBlocked: (isOpen: boolean) => void
}

export const ModalForBlocking: FC<ModalForBlocking> = ({
  id,
  isModallockOpened,
  setIsModallockOpened,
  setIsUserBlocked,
}) => {
  const handleButtonBlock = () => {
    axios
      .put(`/api/clients/change-status/${id}`, {
        status: 'blocked',
      })
      .then(() => {
        setIsModallockOpened(false)
        setIsUserBlocked(true)
      })
      .catch(console.error)
  }

  return (
    <>
      {isModallockOpened && (
        <div className={styles.modal}>
          <div className={styles.text}>Вы уверены, что хотите заблокировать пользователя?</div>
          <div className={styles.buttonFlexContainer}>
            <Button
              label='Отменить'
              view='ghost'
              className={styles.buttonCancel}
              onClick={() => setIsModallockOpened(false)}
            />
            <Button
              label='Заблокировать'
              className={styles.buttonBlock}
              onClick={handleButtonBlock}
            />
          </div>
        </div>
      )}
    </>
  )
}
