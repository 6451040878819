import { ReportState } from './types'

import { ACTIONS, ReportActions } from './actions'
import { ReportOriginalAndActualDTO, ReportStatisticDTO } from '../../shared/Api/reports'
import { ReportStatusEnum } from '../../shared/types/reportStatusEnum'

export const activityReducer = (state: ReportState, action: ReportActions): ReportState => {
  switch (action.type) {
    case ACTIONS.SET_DATA_FOR_EXISTING:
      return {
        ...state,
        client: action.payload.root.client ?? '',
        comment: action.payload.root.comment,
        created_date: action.payload.root.created_date,
        municipal_entity: action.payload.root.municipal_entity,
        year: Number(action.payload.root.year),
        quarter: action.payload.root.quarter,
        status: action.payload.root.status as ReportStatusEnum,
        type: action.payload.root.type,
        actual: parseFromAPItoState(action.payload.actual),
        original: parseFromAPItoState(action.payload.original),
        files: action.payload.files,
        arguments: action.payload.arguments,
        isLoaded: true,
        mode: 'edit',
      }
    case ACTIONS.UPDATE_ACTUAL_FIELD:
      return {
        ...state,
        actual: {
          ...state.actual,
          [action.payload.key]: action.payload.value,
        },
      }
    case ACTIONS.UPDATE_ARGUMENTS_FIELD:
      return {
        ...state,
        arguments: {
          ...state.arguments,
          [action.payload.key]: action.payload.value,
        },
      }
    case ACTIONS.UPDATE_FILES_FIELD:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.key]: action.payload.value,
        },
      }
    case ACTIONS.UPDATE_COMMENT_FIELD:
      return {
        ...state,
        isDataChanged: action.payload.isDataChanged,
        comment: action.payload.comment,
      }
    case ACTIONS.SET_DATA_FOR_NEW:
      return {
        ...state,
        quarter: action.payload.actual.quarter,
        year: action.payload.actual.year,
        actual: parseFromAPItoState(action.payload.actual),
        original: parseFromAPItoState(action.payload.original),
        status: ReportStatusEnum.NEW,
        client: String(action.payload.authData?.userId) || '',
        municipal_entity: action.payload.authData?.municipal_entity || '',
        type: action.payload.typeOfReport as 'quarter' | 'year',
        isLoaded: true,
        mode: 'create',
      }

    default:
      return state
  }
}

function parseFromAPItoState(data: ReportStatisticDTO): ReportOriginalAndActualDTO {
  return [
    'personal_reception',
    'solved_appeal',
    'received_enquiries',
    'event_held',
    'participation_in_session',
    'participation_in_citizenship_meeting',
    'participation_in_municipal_entity_meeting',
    'training_event_by_municipal_entity',
    'training_event_with_participation_municipal_entity',
    'trained_deputies',
    'municipal_project',
    'regional_project',
    'legislative_initiative_of_municipal_entity',
    'legislative_initiative_of_ZSK',
    'participation_of_deputy_ZSK',
    'participation_of_deputy_GD',
    'bureau_of_presidium',
    'publication_links_municipal_entity',
    'publication_links_mass_media',
    'publication_links_in_internet_resources',
    'quantity_of_posted_activities_in_smd_web_site',
    'id',
  ].reduce(
    (acc, key) => ({
      ...acc,
      // @ts-ignore
      [key]: key === 'id' ? data[key] : Number(data[key]),
    }),
    {}
  ) as ReportOriginalAndActualDTO
}
