import { useState, FC } from 'react'

import axios from '../../shared/Api/axios'

import { Button } from '@consta/uikit/Button'

import styles from './ModalForUnlock.module.css'

interface ModalForUnlock {
  id: string | undefined
  isModalUnlockOpened: boolean
  setIsModalUnlockOpened: (isOpen: boolean) => void
  setIsUserBlocked: (isOpen: boolean) => void
}

export const ModalForUnlock: FC<ModalForUnlock> = ({
  id,
  isModalUnlockOpened,
  setIsModalUnlockOpened,
  setIsUserBlocked,
}) => {
  const handleButtonBlock = () => {
    axios
      .put(`/api/clients/change-status/${id}`, {
        status: 'active',
      })
      .then(() => {
        setIsModalUnlockOpened(false)
        setIsUserBlocked(false)
      })
      .catch(console.error)
  }

  return (
    <>
      {isModalUnlockOpened && (
        <div className={styles.modal}>
          <div className={styles.text}>Вы уверены, что хотите разблокировать пользователя?</div>
          <div className={styles.buttonFlexContainer}>
            <Button
              label='Отменить'
              view='ghost'
              className={styles.buttonCancel}
              onClick={() => setIsModalUnlockOpened(false)}
            />
            <Button
              label='Разблокировать'
              className={styles.buttonUnlock}
              onClick={handleButtonBlock}
            />
          </div>
        </div>
      )}
    </>
  )
}
