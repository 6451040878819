import { Activity } from 'store/activities/types'
import {
  statusReportToUITextColors,
  statusToUIReport,
  statusToUIText,
  statusToUITextColors,
} from 'shared/utils/statusMappings'
import { dateMappingsCreatedAt } from 'shared/utils/dateMappingsCreatedAt'

import styles from './SystemInformation.module.css'
import { StatusAuthData } from '../../../../shared/types/statusAuthData'
import { ReportInfo } from '../../../../store/report'
import { ReportStatusEnum } from '../../../../shared/types/reportStatusEnum'
import { UserRolesEnum } from '../../../../shared/enums/UserRole'

export const SystemInformation = ({
  activityData,
  isReport = false,
  authData,
  report,
}: {
  activityData?: Activity
  isReport?: boolean
  authData?: StatusAuthData
  report?: ReportInfo
}) => {
  const attributes = activityData?.client?.data?.attributes
  const mapping = {
    true: {
      id: `${authData?.userId ?? ''}`,
      fullName: `${report?.client?.last_name ?? ''} ${report?.client?.first_name ?? ''} ${
        report?.client?.second_name ?? ''
      }`,
      created_date: report?.created_date ?? '',
      status:
        statusToUIReport(
          report?.status as ReportStatusEnum,
          authData?.role ?? UserRolesEnum.DEPUTY
        ) ?? '',
      statusColor: statusReportToUITextColors(report?.status as ReportStatusEnum) ?? '',
      municipal_entity: report?.municipal_entity ?? '',
    },
    false: {
      id: `${activityData?.client?.data?.id ?? ''}`,
      fullName: `${attributes?.last_name} ${attributes?.first_name} ${attributes?.second_name}`,
      created_date: dateMappingsCreatedAt(activityData?.createdAt ?? ''),
      status: statusToUIText(attributes?.status),
      statusColor: statusToUITextColors(attributes?.status),
      municipal_entity: attributes?.municipal_entity,
    },
  }
  const renderData = mapping[isReport ? 'true' : 'false']
  return (
    <div className={styles.blockInfoContainer}>
      <h3 className={styles.title}>Системная информация</h3>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Автор</div>
        <div className={`${styles.textRight} ${styles.textRightColor}`}>{renderData.fullName}</div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Дата создания</div>
        <div className={styles.textRight}>{renderData.created_date}</div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>ID</div>
        <div className={styles.textRight}>{renderData.id}</div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Статус</div>
        <div className={styles.textRight} style={{ color: renderData.statusColor }}>
          {renderData.status}
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textLeft}>Муниципальный округ</div>
        <div className={styles.textRight}>{renderData.municipal_entity}</div>
      </div>
    </div>
  )
}
