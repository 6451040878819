import axios from './axios'
import { EditUserInfoDto } from './edit-user-info.dto'
import { updateEducationInformation } from '../utils/helper'
import { UserRole } from '../enums/UserRole'

export const getMyCard = () => {
  return axios.get<UserInfoDTO>('/api/clients/get-personal-card')
}

export const updateMyCard = (data: EditUserInfoDto) => {
  const formData = new FormData()

  formData.append(`files.image`, Array.isArray(data.image) ? data.image[0] : data.image)
  const updatedData = updateEducationInformation(data)
  formData.append(
    'data',
    JSON.stringify({
      ...updatedData,
    })
  )

  return axios.put<UserInfoDTO>('/api/clients/fill-info', formData)
}

export const getCardById = (id: number) => {
  return axios.get<UserInfoDTO>(`/api/clients/get-deputy-card/${id}`)
}

export const updateCardById = (id: number, data: EditUserInfoDto) => {
  const formData = new FormData()

  formData.append(`files.image`, Array.isArray(data.image) ? data.image[0] : data.image)
  const updatedData = updateEducationInformation(data)

  formData.append(
    'data',
    JSON.stringify({
      ...updatedData,
    })
  )

  return axios.put<UserInfoDTO>(`/api/clients/fill-info-editor/${id}`, formData)
}
export const getListOfUsers = (params: {
  search?: string
  municipality?: string
  role?: string
  status?: string
  limit?: string | number
  offset?: string | number
}) => {
  return axios.get(`/api/clients`, { params }).then(response => response.data)
}
export interface UserInfoDTO {
  id: number
  first_name: string
  last_name: string
  second_name: string
  role: UserRole
  email: string
  image: File[] | any
  hash: null
  hash_createdAt: null
  birth_date: string | null
  municipal_entity: string | null
  status: UserStatus
  phone: string | null
  position: string | null
  committee: string | null
  party: string | null
  social_medias: UserSocialMedia[]
  educations: UserEducation[]
  careers: UserCareer[]
  social_activities: UserSocialActivity[]
  awards: UserAward[]
  createdAt: string
  updatedAt: string
}

export enum UserStatusEnum {
  ACTIVE = 'active',
  NEW = 'new',
  BLOCKED = 'blocked',
}

export type UserStatus = UserStatusEnum.ACTIVE | UserStatusEnum.NEW | UserStatusEnum.BLOCKED

export interface UserEducation {
  id?: number
  faculty: string
  institution_name: string
  period: string | null
  specialty: string
  type_of_education: string
  createdAt?: string
  updatedAt?: string
}

export interface UserSocialActivity {
  id?: number
  period: string | null
  position: string
  updatedAt?: string
  createdAt?: string
}

export interface UserSocialMedia {
  id?: number
  link: string
  createdAt?: string
  updatedAt?: string
}

export interface UserCareer {
  id?: number
  period: string | null
  position: string
  organization: string
  updatedAt?: string
  createdAt?: string
}

export interface UserAward {
  id?: number
  description: string
  updatedAt?: string
  createdAt?: string
}
