import { UserStatusEnum } from 'shared/Api/users'
import { EditUserState } from './types'
import { UserRolesEnum } from '../../shared/enums/UserRole'

export const initialState: EditUserState = {
  id: 0,
  first_name: '',
  last_name: '',
  second_name: '',
  email: '',
  image: null,
  role: UserRolesEnum.DEPUTY,
  birth_date: null,
  municipal_entity: null,
  status: UserStatusEnum.ACTIVE,
  phone: '',
  position: '',
  committee: '',
  party: '',
  social_medias: [],
  educations: [],
  careers: [],
  social_activities: [],
  awards: [],
  isDataChanged: false,
  isDataSaveFailed: false,
  isDataSaveSucceed: false,
  errorMessage: '',
  file_deleted: false,
}
