import React, { useState } from 'react'
import styles from '../ReportEditElement/ReportEditElement.module.css'
import { Attachment } from '@consta/uikit/Attachment'
import reportIcon from '../../../assets/icons/report.svg'
interface IValue {
  original: string | number
  actual: string | number
  files: File[]
  arguments: string
}

interface Props {
  label: string
  value: IValue
  handleOnIconClick: (data: any) => void
  field: string
}
const ReportViewSectionAsEditor = ({ label, value, field, handleOnIconClick }: Props) => {
  const [isHovering, setIsHovering] = useState(false)
  const [isRowOpen, setIsRowOpen] = useState(false)
  const backgroundColor = isHovering || isRowOpen ? '#F2F6F7' : '#FFF'
  const handleClick = () => {
    setIsRowOpen(!isRowOpen)
    setIsHovering(!isHovering)
  }
  return (
    <div>
      <div
        className={styles.container}
        style={{ backgroundColor }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={handleClick}
      >
        <TopRow
          label={label}
          value={value}
          field={field}
          isIconHidden={isHovering}
          handleOnIconClick={handleOnIconClick}
        />
        {isRowOpen && <MiddleRow value={value} />}
      </div>
      <div>{isRowOpen && <BottomRow value={value} />}</div>
    </div>
  )
}

const TopRow = ({
  label,
  value,
  field,
  handleOnIconClick,
}: {
  label: string
  value: IValue
  field: string
  isIconHidden: boolean
  handleOnIconClick: (data: any) => void
}) => {
  return (
    <div className={styles.topRow}>
      <div className={styles.topRowLabel}>{label}</div>
      <div>
        <span style={{ paddingRight: '40px' }}>{value.original}</span>
        <span
          style={{
            display: 'inline-block',
            width: '20px',
            fontWeight: '550',
            paddingRight: '40px',
          }}
        >
          {value.actual}
        </span>
        <span>
          <img
            src={reportIcon}
            alt='reportIcon'
            onClick={e => {
              e.stopPropagation()
              handleOnIconClick(field)
            }}
          />
        </span>
      </div>
    </div>
  )
}
const MiddleRow = ({ value }: { value: IValue }) => {
  return (
    <div className={styles.middleRow}>
      <div className={styles.middleRowValue}>
        <span
          style={{
            display: 'inline-block',
            wordWrap: 'break-word',
            fontSize: '14px',
          }}
        >
          {value.arguments}
        </span>
      </div>
    </div>
  )
}
const BottomRow = ({ value }: { value: IValue }) => {
  return (
    <div style={{ display: 'flex', backgroundColor: '#F2F6F7', flexWrap: 'wrap' }}>
      {value.files?.map((file: any, index) => (
        <a href={file.url} download key={index}>
          <Attachment
            key={file.name}
            fileName={file.name}
            fileExtension={file.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
            fileDescription={file.type}
          />
        </a>
      ))}
    </div>
  )
}
export default ReportViewSectionAsEditor
