import React, { FC, useEffect, useMemo, useReducer, useState } from 'react'

import axios from 'shared/Api/axios'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '@consta/uikit/Button'

import { ACTIONS, activityReducer, initState, ReportFields, ReportInfo } from 'store/report'

import styles from './ReportReview.module.css'
import { StatusAuthData } from '../../../shared/types/statusAuthData'
import {
  argumentReportEntity,
  changeReportStatusToUpdated,  createFilesReportEntity,
  getReportByIdToEdit,
  updateActualReport,
  updateArgumentsReport,
  updateFilesReport,
} from '../../../shared/Api/reports'
import { getMappedData, getMappedDataForEditor } from '../../../store/report/selectors'
import { ReportEditSection } from '../../../components/ReportComponents/ReportEditSection/ReportEditSection'
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar'
import { SystemInformation } from '../../ActivitiesFeatures/ActivitiCards/SystemInformation/SystemInformation'
import { ClientInfoInterface } from '../../../shared/types/clientInfoInterface'
import ReportComment from '../ReportComment/ReportComment'
import ReportViewAsEditor from '../../../components/ReportComponents/ReportViewAsEditor/ReportViewAsEditor'
import { ReportStatusEnum } from '../../../shared/types/reportStatusEnum'
import { UserRolesEnum } from '../../../shared/enums/UserRole'
import { quarterToDate } from '../../../shared/utils/quarterToDate'
import subtractIcon from '../../../assets/icons/subtract.svg'

interface ReportProps {
  authData: StatusAuthData
}

export const ReportReview: FC<ReportProps> = ({ authData }) => {
  const { reportId } = useParams()

  const [report, dispatch] = useReducer(activityReducer, initState)
  const navigate = useNavigate()
  const [initComment, setInitComment] = useState('')

  useEffect(() => {
    async function startFetching(reportId: string | number) {
      try {
        const response = await getReportByIdToEdit(reportId)
        const { original_report, actual_report, files_report, argument_report, ...root } = response
        if (!ignore) {
          dispatch({
            type: ACTIONS.SET_DATA_FOR_EXISTING,
            payload: {
              root: {
                ...root,
                id: Number(reportId),
                client: root.client as ClientInfoInterface,
              },
              actual: actual_report ?? {},
              original: original_report ?? {},
              files: files_report ?? {},
              arguments: argument_report ?? {},
            },
          })
          setInitComment(root.comment)
        }
      } catch (error: any) {
        if (error?.response?.status === 403 || 400) {
          navigate('/reports')
        }
      }
    }

    if (reportId === undefined) {
      return
    }
    let ignore = false
    startFetching(reportId)
    return () => {
      ignore = true
    }
  }, [reportId, navigate])
  const handleChangeCommentField = (value: string) => {
    dispatch({
      type: ACTIONS.UPDATE_COMMENT_FIELD,
      payload: {
        comment: value,
        isDataChanged: !(value === initComment),
      },
    })
  }
  const handleApprove = async () => {
    await axios.put(`/api/reports/change-status/${reportId}`, {
      status: ReportStatusEnum.APPROVED,
    })
    navigate('/reports')
  }
  const handleReviewed = async () => {
    await axios.put(`/api/reports/${reportId}`, {
      data: {
        comment: report.comment,
        status: ReportStatusEnum.ON_REVIEW,
      },
    })
    await axios.put(`/api/actual-reports/${report.actual.id}`, {
      data: {
        ...report.original,
        id: report.actual.id,
      },
    })
    navigate('/reports')
  }
  const handleChangeTextField = ({
    actual_number,
    comment,
    files,
    name,
  }: {
    actual_number: string
    comment: string | null
    files: File[] | undefined
    name: string
  }) => {
    dispatch({
      type: ACTIONS.UPDATE_ARGUMENTS_FIELD,
      payload: {
        key: name as keyof ReportFields<string>,
        value: comment ?? '',
      },
    })
    dispatch({
      type: ACTIONS.UPDATE_ACTUAL_FIELD,
      payload: {
        key: name as keyof ReportFields<string>,
        value: actual_number,
      },
    })
    dispatch({
      type: ACTIONS.UPDATE_FILES_FIELD,
      payload: {
        key: name as keyof ReportFields<File[]>,
        value: files ?? [],
      },
    })
  }

  const handleSave = async () => {
    const rootReport = await getReportByIdToEdit(reportId as string)
    let filesReportId = rootReport?.files_report?.id
    let argumentReportId = rootReport?.argument_report?.id
    const actualReportWithReportId = Object.assign(
      { ...report.actual },
      { id: rootReport.actual_report.id }
    )
    if(!filesReportId){
      const filesReport = await createFilesReportEntity(reportId as string)
      filesReportId = filesReport.data.data.id
    }
    const filesReportWithReportId = Object.assign(
      { ...report.files },
      { id: filesReportId }
    )
    if(!argumentReportId){
      const argumentReport = await argumentReportEntity(reportId as string)
      argumentReportId = argumentReport.data.data.id
    }
    const argumentsReportWithReportId = Object.assign(
      { ...report.arguments },
      { id: argumentReportId }
    )
    await updateActualReport(actualReportWithReportId.id, actualReportWithReportId)
    await updateArgumentsReport(argumentsReportWithReportId.id, argumentsReportWithReportId)
    await updateFilesReport(filesReportWithReportId.id, filesReportWithReportId)
    await changeReportStatusToUpdated(reportId as string)
    navigate('/reports')
  }
  const handleOnIconClick = (field: string) => {
    const municipal_entity = report?.municipal_entity ?? ''
    let from = ''
    let until = ''
    if (report?.year && report?.quarter) {
      const date = quarterToDate(report.quarter)
      from = `${report.year}-${date.from}`
      until = `${report.year}-${date.until}`
    }
    const queryParams = new URLSearchParams({
      'criteria[]': field,
      municipal_entity,
      from,
      until,
    })
    navigate(`/activity?${queryParams.toString()}`)
  }
  const sectionsForEditor = useMemo(() => getMappedDataForEditor(report), [report])
  const sectionsContentForEditor = sectionsForEditor.map((section, i) => (
    <ReportViewAsEditor
      key={i}
      title={section.title}
      list={section.items}
      handleOnIconClick={handleOnIconClick}
    />
  ))
  const sections = useMemo(() => getMappedData(report), [report])
  const sectionsContent = sections.map((section, i) => {
    return (
      <ReportEditSection
        key={i}
        title={section.title}
        list={section.items}
        onChange={handleChangeTextField}
      />
    )
  })
  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        {!report.isLoaded && <ProgressBar dataReceived={report.isLoaded} />}
        {report.isLoaded && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              marginBottom: '24px',
            }}
          >
            <h2 className={styles.mainTitle}>
              {`Правка отчета за ${report.quarter} квартал ${report.year} года`}
            </h2>
            {authData?.role === UserRolesEnum.EDITOR && (
              <a href={`/api/reports/export-as-pdf/${reportId}`} download>
                <img src={subtractIcon} alt='export' />
              </a>
            )}
          </div>
        )}
        {report.isLoaded && authData.role === UserRolesEnum.EDITOR && (
          <div>
            <SystemInformation authData={authData} report={report as ReportInfo} isReport={true} />
            {sectionsContentForEditor}
          </div>
        )}
        {report.isLoaded && authData.role === UserRolesEnum.DEPUTY && <div>{sectionsContent}</div>}
        {report.isLoaded && (
          <ReportComment
            authData={authData}
            status={report.status}
            onChange={handleChangeCommentField}
            val={initComment}
          />
        )}
      </div>
      {authData.role === UserRolesEnum.EDITOR && (
        <div className={styles.footerContainer}>
          <Button
            label='Отменить'
            view='ghost'
            style={{ width: '240px', marginRight: '24px' }}
            onClick={() => navigate(-1)}
          />
          {report.status !== ReportStatusEnum.APPROVED &&
            report.status !== ReportStatusEnum.AUTO_APPROVED &&
            report.status !== ReportStatusEnum.ON_REVIEW && (
              <Button
                label={report.isDataChanged ? 'Отправить на изменение' : 'Подтвердить'}
                style={{ width: '240px' }}
                onClick={report.isDataChanged ? handleReviewed : handleApprove}
              />
            )}
        </div>
      )}
      {authData?.role === UserRolesEnum.DEPUTY &&
      (authData.canCreateReport || report.status === ReportStatusEnum.ON_REVIEW) ? (
        <div className={styles.footerContainer}>
          <Button
            label='Отменить'
            view='ghost'
            style={{ width: '240px', marginRight: '24px' }}
            onClick={() => navigate(-1)}
          />
          <Button label='Отправить' style={{ width: '240px' }} onClick={handleSave} />
        </div>
      ) : null}
    </div>
  )
}
