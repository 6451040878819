import { ReactNode } from 'react'

import styles from './FormSection.module.css'

export const FormSection = ({ children, title = '' }: { children: ReactNode; title: string }) => {
  return (
    <div className={styles.blockInfoContainer}>
      <h3 className={styles.title}>{title}</h3>
      {children}
    </div>
  )
}
