import styles from './PublicActivities.module.css'
import { UserInfoDTO } from 'shared/Api/users'
import { dateMappingForUserCards } from 'shared/utils/dateMappingForUserCards'

export const PublicActivities = ({ user }: { user: UserInfoDTO }) => {
  return (
    <div className={styles.blockInfoContainer}>
      <h3 className={styles.title}>Общественная деятельность</h3>
      {user.social_activities.length === 0 ? (
        <div className={styles.noInformation}>Информация не заполнена</div>
      ) : (
        user.social_activities.map((item, index) => {
          return (
            <div key={index} className={styles.infoContainer}>
              <div className={styles.textContainer}>
                <div className={styles.textLeft}>Период осуществления</div>
                <div className={styles.textRight}>{dateMappingForUserCards(item.period)}</div>
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textLeft}>Должность</div>
                <div className={styles.textRight}>{item.position}</div>
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}
