import { ActivityStatusEnum } from '../types/activityStatusEnum'
import { ReportStatusEnum } from '../types/reportStatusEnum'
import { UserRole, UserRolesEnum } from '../enums/UserRole'

export function statusToUIText(role: string) {
  return (
    {
      new: 'Новый',
      active: 'Активный',
      blocked: 'Заблокирован',
    }[role] || 'none'
  )
}

export function statusToUIActivity(status: string) {
  return (
    {
      [ActivityStatusEnum.APPROVED]: 'Подтверждено',
      [ActivityStatusEnum.CHECKED]: 'Проверка',
      [ActivityStatusEnum.FIXED]: 'Исправление',
      [ActivityStatusEnum.UPLOADED]: 'Выгружен на сайт СМД',
    }[status] || 'none'
  )
}

export function statusReportToUITextColors(status: string) {
  return (
    {
      [ReportStatusEnum.NEW]: '#91A4AB',
      [ReportStatusEnum.APPROVED]: '#35AF0C',
      [ReportStatusEnum.TO_REVIEW]: '#DF2A55',
      [ReportStatusEnum.ON_REVIEW]: '#0076A3',
      [ReportStatusEnum.AUTO_APPROVED]: '#35AF0C',
    }[status] || '#ffffff'
  )
}
export const statusToUIReport = (status: string, role: UserRole) => {
  return role === UserRolesEnum.EDITOR
    ? {
        [ReportStatusEnum.NEW]: 'Новый',
        [ReportStatusEnum.APPROVED]: 'Подтверждено',
        [ReportStatusEnum.TO_REVIEW]: 'Нужно проверить',
        [ReportStatusEnum.ON_REVIEW]: 'На исправлении',
        [ReportStatusEnum.AUTO_APPROVED]: 'Автоматически подтверждено',
      }[status] || 'Неизвестный статус'
    : {
        [ReportStatusEnum.NEW]: 'Новый',
        [ReportStatusEnum.APPROVED]: 'Подтверждено',
        [ReportStatusEnum.TO_REVIEW]: 'На проверке',
        [ReportStatusEnum.ON_REVIEW]: 'На исправление',
        [ReportStatusEnum.AUTO_APPROVED]: 'Автоматически подтверждено',
      }[status] || 'Неизвестный статус'
}

export const statusToUIActivityColors = (role: string) => {
  return (
    {
      [ActivityStatusEnum.APPROVED]: '#35af0c',
      [ActivityStatusEnum.CHECKED]: '#0076a3',
      [ActivityStatusEnum.FIXED]: '#e27a19',
    }[role] || '#ffffff'
  )
}

export const statusToUITextColors = (role: string) => {
  return (
    {
      new: '#91A4AB',
      active: '#35AF0C',
      blocked: '#DF2A55',
    }[role] || '#ffffff'
  )
}
