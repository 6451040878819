import { FC, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Table } from '@consta/uikit/Table'
import { AutoComplete } from '@consta/uikit/AutoCompleteCanary'
import { Combobox } from '@consta/uikit/Combobox'
import { DatePicker } from '@consta/uikit/DatePicker'
import { Button } from '@consta/uikit/Button'
import subtract from '../../../assets/icons/subtract.svg'
import { itemsSelectStatusActive } from 'shared/constants/itemsSelectStatusActive'
import { itemsComboboxCriteria } from 'shared/constants/itemsComboboxCriteria'
import { itemsAutoCompleteMunicipality } from 'shared/constants/itemsAutoCompleteMunicipality'
import { FilteringOptionsForActivity } from 'shared/types/FilteringOptionsForActivity'
import { ItemCombobox } from 'shared/types/ItemCombobox'
import styles from './Activity.module.css'
import { StatusAuthData } from '../../../shared/types/statusAuthData'
import { Switch } from '@consta/uikit/Switch'
import { useInView } from 'react-intersection-observer'
import { UserRolesEnum } from '../../../shared/enums/UserRole'
import {
  filterParamsActivityReq,
  filterParamsResponse,
  getActivitiesNavigateUrl,
  getParamsFromUrlForActivities,
  makeSecondArrayUnique,
  structureActivity,
  structureUsers,
} from '../../../shared/utils/helper'
import { findMembers, findUsersByIds, getActivities } from '../../../shared/Api/activities'

interface ActivityProps {
  authData: StatusAuthData
}

export const Activity: FC<ActivityProps> = ({ authData }) => {
  const navigate = useNavigate()
  const [criteriaSetting, setCriteriaSetting] = useState<boolean>(false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [activityList, setActivityList] = useState<any>([])
  const [valueComboboxCriteria, setValueComboboxCriteria] = useState<ItemCombobox[] | null>([])
  const [valueComboboxParticipants, setValueComboboxParticipants] = useState<ItemCombobox[] | null>(
    []
  )
  const [paramsLoaded, setParamsLoaded] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const { ref, inView } = useInView({
    threshold: 0.1,
  })
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(20)

  const [itemsCombobox, setItemsCombobox] = useState<ItemCombobox[]>([])

  const [filterParameters, setFilterParameters] = useState<FilteringOptionsForActivity>({
    municipality: null,
    status: null,
    dateEvent: [],
  })
  useEffect(() => {
    if (!(firstLoad && authData.isLoaded)) {
      return
    }

    const params = getParamsFromUrlForActivities(queryParams)
    setFilterParameters({
      ...(params as FilteringOptionsForActivity),
    })

    const paramsForFindMembers: { municipal_entity?: string } = {}
    if (authData.role === UserRolesEnum.DEPUTY && authData.municipal_entity) {
      paramsForFindMembers.municipal_entity = authData.municipal_entity
    }

    const fetchData = async () => {
      const calls: Array<Promise<ItemCombobox[]>> = [findMembers(paramsForFindMembers)]
      if (params?.members?.length > 0) {
        calls.push(findUsersByIds(params.members))
      }
      const [items, participants] = await Promise.all(calls)
      const structuredItems = structureUsers(items)
      const structuredParticipants = structureUsers(participants) ?? []
      const labelsArr = structuredItems.map(el => el.label)
      const uniqueParticipants = makeSecondArrayUnique(labelsArr, structuredParticipants)
      return [[...structuredItems, ...uniqueParticipants], structuredParticipants]
    }
    setCriteriaSetting(params.criteria_setting === 'true')
    const criteria_fields = itemsComboboxCriteria.map(el => el.labelForBackend)
    const criteria = (params?.criteria ?? [])
      .map(el => {
        if (criteria_fields.includes(el)) {
          return itemsComboboxCriteria.find(item => item.labelForBackend === el)
        }
        return null
      })
      .filter(el => el !== null) as ItemCombobox[]
    setValueComboboxCriteria(criteria)
    fetchData()
      .then(r => {
        setItemsCombobox(r[0] as ItemCombobox[])
        setValueComboboxParticipants(r[1] as ItemCombobox[])
      })
      .then(() => {
        setLimit(20)
        setOffset(0)
        setParamsLoaded(true)
      })
  }, [firstLoad, authData.isLoaded])

  useEffect(() => {
    if (inView && activityList.length + limit < total) {
      setOffset(activityList.length + 1)
    } else if (inView && total - activityList.length > 0) {
      setLimit(total - activityList.length - 1)
      setOffset(activityList.length + 1)
    }
  }, [inView, activityList])

  useEffect(() => {
    const paramsForReq: filterParamsResponse = filterParamsActivityReq({
      municipal_entity: filterParameters?.municipality,
      status: filterParameters?.status?.statusFilter,
      dateEvent: filterParameters?.dateEvent,
      members: valueComboboxParticipants,
      criteria: valueComboboxCriteria,
      criteria_setting: criteriaSetting,
    })
    getActivities({ ...paramsForReq, limit, offset }).then(response => {
      const activities = structureActivity(response.data, offset)
      if (firstLoad && authData.isLoaded) {
        setFirstLoad(false)
      }
      setTotal(response.total)
      if (paramsLoaded) {
        limit === 20 && offset === 0
          ? setActivityList(activities)
          : setActivityList([...activityList, ...activities])
      }
    })
  }, [
    filterParameters,
    valueComboboxParticipants,
    valueComboboxCriteria,
    criteriaSetting,
    offset,
    limit,
  ])
  const columns: any = [
    {
      title: '№',
      accessor: 'serial',
      renderCell: (row: any) => {
        return <div>{row.serial + 1}</div>
      },
    },
    {
      title: authData?.role === UserRolesEnum.DEPUTY ? 'Заголовок' : 'Муниципальное образование',
      accessor: authData?.role === UserRolesEnum.DEPUTY ? 'title' : 'municipal_entity',
      sortable: true,
    },
    {
      title: 'ID',
      accessor: 'id',
    },
    {
      title: 'Статус',
      accessor: 'status',
      renderCell: (row: any) => (
        <div className={`${row.status === 'Подтверждено' ? styles.approved : styles.uploaded}`}>
          {row.status}
        </div>
      ),
    },
    {
      title: 'Дата проведения',
      accessor: 'dateEvent',
    },
  ]
  const exportUrl = queryParams.toString()
  const handleClickRow = ({ id }: { id: string | undefined }): void => {
    navigate(`${id}`)
  }
  const updateLimitOffset = () => {
    setLimit(20)
    setOffset(0)
  }
  const updateNavigateUrl = (name: string, value: any) => {
    updateLimitOffset()
    navigate(
      getActivitiesNavigateUrl({
        ...filterParameters,
        criteriaSetting,
        members: valueComboboxParticipants,
        criteria: valueComboboxCriteria,
        [name]: value,
      })
    )
  }
  const handleChangeFilterParameters = (value: any, name: any) => {
    setFilterParameters(prev => ({
      ...prev,
      [name]: value,
    }))
    updateNavigateUrl(name, value)
  }
  const handleSwitchCriteriaSetting = (name: string, value: boolean) => {
    setCriteriaSetting(!criteriaSetting)
    updateNavigateUrl(name, value)
  }
  const handleInputChange = async (name: string, value: any) => {
    const flag = itemsCombobox.find(el => el.label === value)
    if (!flag) {
      const newMembers = await findMembers({ full_name: value })
      const structuredNewMembers = structureUsers(newMembers)
      const labelsArr = itemsCombobox.map(el => el.label)
      const newUniqueMembers = makeSecondArrayUnique(labelsArr, structuredNewMembers) ?? []
      return setItemsCombobox([...itemsCombobox, ...newUniqueMembers])
    }
    updateNavigateUrl(name, value)
  }
  const handleComboboxMembersChange = (name: string, value: any) => {
    setValueComboboxParticipants(value)
    updateNavigateUrl(name, value)
  }
  const handleComboboxCriteriaChange = (name: string, value: any) => {
    setValueComboboxCriteria(value)
    updateNavigateUrl(name, value)
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerContainer}>
        <h3 className={styles.title}>Деятельность</h3>
        {authData?.role === UserRolesEnum.DEPUTY ? (
          <Link to='createActivity'>
            <Button label='Создать деятельность' style={{ width: '227px' }} />
          </Link>
        ) : (
          <div className={styles.buttons}>
            <a href={`/api/activities/export-by-filter?${exportUrl}`} download>
              <img src={subtract} alt='' />
            </a>
            <Link
              to={{
                pathname: `/reports/statistic-by-query`,
                search: exportUrl,
              }}
            >
              <Button label='Посмотреть показатели' style={{ width: '230px' }} />
            </Link>
          </div>
        )}
      </div>
      <div className={styles.autoCompleteContainer}>
        {authData?.role === UserRolesEnum.DEPUTY ? null : (
          <AutoComplete
            type='text'
            placeholder='МО'
            value={filterParameters.municipality}
            items={itemsAutoCompleteMunicipality}
            onChange={e => handleChangeFilterParameters(e.value, 'municipality')}
            width='full'
            name='municipality'
          />
        )}
        <Combobox
          placeholder='Статус'
          items={itemsSelectStatusActive}
          value={filterParameters.status}
          onChange={e => handleChangeFilterParameters(e.value, 'status')}
        />
        <DatePicker
          type='date-range'
          width='full'
          value={filterParameters.dateEvent}
          onChange={e => handleChangeFilterParameters(e.value, 'dateEvent')}
        />
      </div>
      <div className={styles.autoCompleteContainer}>
        <Combobox
          items={itemsCombobox}
          placeholder='Участники'
          className={styles.fieldIndentation}
          value={valueComboboxParticipants}
          multiple
          onInputChange={({ value }) => handleInputChange('inputChange', value)}
          onChange={({ value }) => {
            handleComboboxMembersChange('members', value)
          }}
        />
        <Switch
          checked={Boolean(criteriaSetting)}
          label={criteriaSetting ? 'Или' : 'И'}
          onChange={() => {
            handleSwitchCriteriaSetting('criteriaSetting', !criteriaSetting)
          }}
        />
        <Combobox
          items={itemsComboboxCriteria}
          placeholder='Критерии'
          className={styles.fieldIndentation}
          value={valueComboboxCriteria}
          multiple
          onChange={({ value }) => {
            handleComboboxCriteriaChange('criteria', value)
          }}
        />
      </div>
      {activityList && (
        <div>
          <Table
            rows={activityList}
            columns={columns}
            zebraStriped='even'
            borderBetweenRows
            borderBetweenColumns
            activeRow={{ id: undefined, onChange: handleClickRow }}
          />
          <div style={{ height: '2px', width: '100%' }} ref={ref}></div>
        </div>
      )}
    </div>
  )
}
