import React from 'react'
import { TextField, TextFieldPropValue } from '@consta/uikit/TextField'
import { StatusAuthData } from '../../../shared/types/statusAuthData'
import { ReportStatusEnum } from '../../../shared/types/reportStatusEnum'
import { UserRolesEnum } from '../../../shared/enums/UserRole'

const ReportComment = ({
  authData,
  status,
  onChange,
  val,
}: {
  authData: StatusAuthData
  status: ReportStatusEnum
  onChange: (value: string) => void
  val: string
}) => {
  const [comment, setComment] = React.useState(val)
  const onChangeComment = (value: TextFieldPropValue) => {
    onChange(value?.toString() ?? '')
    setComment(value ?? '')
  }
  const isDisabled =
    authData.role === UserRolesEnum.DEPUTY ||
    status === ReportStatusEnum.APPROVED ||
    status === ReportStatusEnum.AUTO_APPROVED
  return (
    <TextField
      rows={6}
      style={{ width: '100%', flexBasis: '50%' }}
      type='textarea'
      disabled={isDisabled}
      placeholder={isDisabled ? comment : 'Причина изменения показателя'}
      withClearButton={authData.role === UserRolesEnum.EDITOR}
      value={comment}
      onChange={({ value }) => onChangeComment(value)}
    />
  )
}

export default ReportComment
