import React, { CSSProperties } from 'react'
import { ReportAsSectionItemWithAllValues } from '../../../store/report'
import ReportViewSectionAsEditor from '../ReportViewSectionAsEditor/ReportViewSectionAsEditor'

const styles: {
  blockInfo: CSSProperties
  title: CSSProperties
} = {
  blockInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    padding: '16px 12px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    marginBottom: '24px',
  },
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '150%',
    marginBottom: '24px',
  },
}
const ReportViewAsEditor = ({
  title,
  list,
  handleOnIconClick,
}: {
  title: string
  list: ReportAsSectionItemWithAllValues[]
  handleOnIconClick: (data: any) => void
}) => {
  const listItems = list.map((item, i) => (
    <ReportViewSectionAsEditor
      key={i}
      label={item.label}
      value={item.value}
      field={item.key}
      handleOnIconClick={handleOnIconClick}
    />
  ))
  return (
    <div>
      <div style={styles.blockInfo}>
        <h3 style={styles.title}>{title}</h3>
        {listItems}
      </div>
    </div>
  )
}

export default ReportViewAsEditor
