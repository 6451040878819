export interface ItemsSelectStatusReport {
  label: string
  id: number
  statusFilter: string
}

export const itemsSelectStatusReport: ItemsSelectStatusReport[] = [
  {
    label: 'Новый',
    id: 1,
    statusFilter: 'new',
  },
  {
    label: 'Подтверждено',
    id: 2,
    statusFilter: 'approved',
  },
  {
    label: 'На проверке',
    id: 3,
    statusFilter: 'to_review',
  },
  {
    label: 'На исправление',
    id: 4,
    statusFilter: 'on_review',
  },
]
export const itemsSelectStatusReportEditor: ItemsSelectStatusReport[] = [
  {
    label: 'Новый',
    id: 1,
    statusFilter: 'new',
  },
  {
    label: 'На исправлении',
    id: 2,
    statusFilter: 'on_review',
  },
  {
    label: 'Нужно проверить',
    id: 3,
    statusFilter: 'to_review',
  },
  {
    label: 'Подтвержден',
    id: 4,
    statusFilter: 'approved',
  },
]
