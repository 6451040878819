import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Button } from '@consta/uikit/Button'

import {
  approveReportById,
  getReportByActivityQuery,
  getReportById,
  getReportForCurrentQuarter,
  getYearlyReport,
  ReportStatisticDTO,
} from '../../../shared/Api/reports'
import styles from './ReportView.module.css'
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar'
import { getQuarterAsGreekNow } from '../../../shared/utils/getQuarter'
import { ReportStatusEnum } from '../../../shared/types/reportStatusEnum'
import { ReportViewContent } from '../../../components/ReportComponents/ReportViewContent/ReportViewContent'

export const ReportView = ({
  type = 'quarter',
  auth: { canCreateReport },
}: {
  type: 'quarter' | 'year' | 'by-id' | 'by-activity'
  auth: { canCreateReport: boolean }
}) => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const [searchParams] = useSearchParams()
  const [report, setReport] = useState<ReportStatisticDTO | null>(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const handleOnApprove = async (reportId: number, report: ReportStatisticDTO) => {
    await approveReportById(reportId)
    return setReport({ ...report, status: ReportStatusEnum.APPROVED })
  }
  const title = useMemo(() => {
    switch (type) {
      case 'quarter':
        return `Просмотр отчета за ${getQuarterAsGreekNow()} квартал ${new Date().getFullYear()} года`
      case 'year':
        return `Просмотр отчета за ${new Date().getFullYear()} год`
      case 'by-id':
        return `Просмотр отчета за ${report?.quarter} квартал ${report?.year} года`
      case 'by-activity':
        return `Просмотр отчета по отфильтрованной деятельности`
    }
  }, [report, type])

  useEffect(() => {
    async function startFetching(reportId: string) {
      try {
        let response
        switch (type) {
          case 'quarter':
            response = await getReportForCurrentQuarter()
            break
          case 'year':
            response = await getYearlyReport()
            break
          case 'by-id':
            response = await getReportById(reportId)
            break
          case 'by-activity':
            response = await getReportByActivityQuery(searchParams.toString())
            break
        }

        if (!ignore) {
          setReport(response)
        }
      } catch (error: any) {
        if (error?.response?.status === 403) {
          navigate('/reports')
        }
      }
    }

    let ignore = false
    startFetching(reportId ?? '')
    return () => {
      ignore = true
    }
  }, [navigate, reportId, searchParams, type])

  useEffect(() => {
    if (report) {
      setIsLoaded(true)
    }
  }, [report])
  return (
    <div>
      <div className={styles.main}>
        {!isLoaded && <ProgressBar dataReceived={isLoaded} />}
        {isLoaded && <h2 className={styles.mainTitle}>{title}</h2>}
        {isLoaded && report && <ReportViewContent report={report} />}
      </div>
      <div className={styles.footerContainer}>
        {type === 'by-id' && report?.status === ReportStatusEnum.TO_REVIEW ? (
          <Button
            label='Подтвердить'
            view='primary'
            style={{ width: '240px' }}
            onClick={async () => handleOnApprove(report?.id, report)}
          />
        ) : null}
        <Button
          label='Назад'
          view='ghost'
          style={{ width: '240px' }}
          onClick={() => navigate(-1)}
        />
      </div>
    </div>
  )
}
