import axios from './axios'
import { ActivityStatusEnum } from '../types/activityStatusEnum'
import { UserRole } from '../enums/UserRole'
import { ItemCombobox } from '../types/ItemCombobox'
export const findUsersByIds = (id: number[] | string[]): Promise<ItemCombobox[]> => {
  return axios.get(`/api/clients/find-users-by-id?ids=${id}`).then(r => r.data)
}
export const changeStatusById = (activityId: number, status: ActivityStatusEnum) => {
  return axios
    .put<Attributes>(`/api/activities/status/${activityId}`, {
      status,
    })
    .then(res => res.data)
}
export const findMembers = (params: {
  municipal_entity?: string
  full_name?: string
}): Promise<ItemCombobox[]> => {
  return axios
    .get<ItemCombobox[]>('/api/clients/find-members', { params })
    .then(response => response.data)
}
export const getActivitiesById = (activityId: number) => {
  return axios
    .get<GetActivitiesByIdDTO>(`/api/activities/${activityId}?populate=*`)
    .then(res => res.data)
}
export const getActivities = async (params: {
  status?: string | null
  from?: string | null
  until?: string | null
  municipal_entity?: string | null
  criteria?: string | string[] | null
  members?: string | string[] | null
  criteria_setting?: string | null | boolean
  limit?: string | null | number
  offset?: string | null | number
}): Promise<{
  data: any[]
  total: number
}> => {
  return axios.get(`api/activities`, { params }).then(res => res.data)
}
export interface GetActivitiesByIdDTO {
  data: {
    id: number
    attributes: Attributes
  }
}
export interface IGetActivities {
  userId: number
  role: UserRole
  query: {
    status?: string | null
    from?: string | null
    until?: string | null
    municipal_entity?: string | null
    criteria?: string | string[] | null
    members?: string | string[] | null
    criteria_setting?: string | null
    limit?: string | null
    offset?: string | null
  }
}
export interface Attributes {
  title: string
  text: string
  date: string
  personal_reception: boolean
  solved_appeal: boolean
  event_held: boolean
  participation_in_session: boolean
  participation_in_citizenship_meeting: boolean
  participation_in_municipal_entity_meeting: boolean
  training_event_by_municipal_entity: boolean
  training_event_with_participation_municipal_entity: boolean
  trained_deputies: any
  municipal_project: boolean
  regional_project: boolean
  legislative_initiative_of_municipal_entity: boolean
  legislative_initiative_of_ZSK: boolean
  participation_of_deputy_ZSK: boolean
  participation_of_deputy_GD: boolean
  bureau_of_presidium: any
  publication_links_municipal_entity: any[]
  publication_links_mass_media: any[]
  publication_links_in_internet_resources: any[]
  status: string
  createdAt: string
  updatedAt: string
  received_enquiries: any
  images: Images
  documents: Documents
  members: Members
  client: Creator
}

export interface Images {
  data: unknown[] | null
}

export interface Documents {
  data: unknown[] | null
}

export interface Members {
  data: Member[]
}

export interface Member {
  id: number
  attributes: MemberAttributes
}

export interface MemberAttributes {
  createdAt: string
  updatedAt: string
  first_name: string
  last_name: string
  second_name: string
  role: string
  email: string
  hash_createdAt: any
  birth_date?: string
  municipal_entity?: string
  status: string
  phone?: string
  position?: string
  committee?: string
  party?: string
}

export interface Creator {
  data: CreatorData
}

export interface CreatorData {
  id: number
  attributes: CreatorAttributes
}

export interface CreatorAttributes {
  createdAt: string
  updatedAt: string
  first_name: string
  last_name: string
  second_name: string
  role: string
  email: string
  hash_createdAt: any
  birth_date: string
  municipal_entity: string
  status: string
  phone: string
  position: string
  committee: string
  party: string
}
