import { useReducer, useEffect, FC, useMemo } from 'react'

import { useNavigate } from 'react-router-dom'

import { Button } from '@consta/uikit/Button'

import { initState, activityReducer, ACTIONS, ReportFields } from 'store/report'

import styles from './ReportCreate.module.css'
import { StatusAuthData } from '../../../shared/types/statusAuthData'
import {
  createActualReport,
  createArgumentsReport,
  createFilesReport,
  createOriginalReport,
  createReport,
  getQuarterlyReport,
  getYearlyReport,
} from '../../../shared/Api/reports'
import { ReportEditSection } from '../../../components/ReportComponents/ReportEditSection/ReportEditSection'
import { getMappedData } from '../../../store/report/selectors'
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar'
import { ReportTypeEnum } from '../../../shared/types/reportTypeEnum'
import { UserRolesEnum } from '../../../shared/enums/UserRole'

interface ReportProps {
  authData: StatusAuthData
  type?: string
}

export const ReportCreate: FC<ReportProps> = ({ authData, type = ReportTypeEnum.QUARTER }) => {
  const [report, dispatch] = useReducer(activityReducer, initState)

  const navigate = useNavigate()

  useEffect(() => {
    if (!authData.canCreateReport) {
      navigate('/reports')
    }
    async function startFetching() {
      try {
        const fetch = type === ReportTypeEnum.QUARTER ? getQuarterlyReport : getYearlyReport
        const response = await fetch()
        if (!ignore) {
          dispatch({
            type: ACTIONS.SET_DATA_FOR_NEW,
            payload: {
              actual: response,
              original: response,
              authData,
              typeOfReport: type,
            },
          })
        }
      } catch (error: any) {
        if (error?.response?.status === 403) {
          navigate('/reports')
        }
      }
    }
    let ignore = false
    startFetching()
    return () => {
      ignore = true
    }
  }, [authData, navigate, type])
  const handleChangeTextField = ({
    actual_number,
    comment,
    files,
    name,
  }: {
    actual_number: string
    comment: string | null
    files: File[] | undefined
    name: string
  }) => {
    dispatch({
      type: ACTIONS.UPDATE_ARGUMENTS_FIELD,
      payload: {
        key: name as keyof ReportFields<string>,
        value: comment ?? '',
      },
    })
    dispatch({
      type: ACTIONS.UPDATE_ACTUAL_FIELD,
      payload: {
        key: name as keyof ReportFields<string>,
        value: actual_number,
      },
    })
    dispatch({
      type: ACTIONS.UPDATE_FILES_FIELD,
      payload: {
        key: name as keyof ReportFields<File[]>,
        value: files ?? [],
      },
    })
  }

  const handleSave = async () => {
    const data = {
      client: Number(report.client),
      quarter: report.quarter,
      year: Number(report.year),
      status: report.status,
      municipal_entity: report.municipal_entity,
      comment: report.comment,
      type: report.type,
    }
    const rootReport = await createReport({
      ...data,
    })
    const originalReportWithReportId = Object.assign(
      { ...report.original },
      { report: rootReport.data.id }
    )
    const actualReportWithReportId = Object.assign(
      { ...report.actual },
      { report: rootReport.data.id }
    )
    const filesReportWithReportId = Object.assign(
      { ...report.files },
      { report: rootReport.data.id }
    )
    const argumentsReportWithReportId = Object.assign(
      { ...report.arguments },
      { report: rootReport.data.id }
    )
    await createOriginalReport({
      data: originalReportWithReportId,
    })
    await createActualReport({
      data: actualReportWithReportId,
    })
    await createArgumentsReport({
      data: argumentsReportWithReportId,
    })
    await createFilesReport({
      data: filesReportWithReportId,
    })
    navigate('/reports')
  }

  const sections = useMemo(() => getMappedData(report), [report])

  const sectionsContent = sections.map((section, i) => (
    <ReportEditSection
      key={i}
      title={section.title}
      list={section.items}
      onChange={handleChangeTextField}
    />
  ))

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        {!report.isLoaded && <ProgressBar dataReceived={report.isLoaded} />}
        {report.isLoaded && (
          <h2
            className={styles.mainTitle}
          >{` Отправка отчета за ${report.quarter} квартал ${report.year} года`}</h2>
        )}
        {report.isLoaded && sectionsContent}
      </div>
      {authData?.role === UserRolesEnum.DEPUTY && authData.canCreateReport && (
        <div className={styles.footerContainer}>
          <Button
            label='Отменить'
            view='ghost'
            style={{ width: '240px', marginRight: '24px' }}
            onClick={() => navigate(-1)}
          />
          <Button label='Отправить' style={{ width: '240px' }} onClick={handleSave} />
        </div>
      )}
    </div>
  )
}
