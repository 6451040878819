export function dateMappingForFiltering(date: any) {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const formattedDay = day < 10 ? `0${day}` : `${day}`
  const formattedMonth = month < 10 ? `0${month}` : `${month}`
  const formattedYear = `${year}`

  return `${formattedYear}-${formattedMonth}-${formattedDay}`
}
export function dateMappingFromQuery(date: { from: string | null; until: string | null }): any[] {
  const response = []
  if (date.from) {
    const [from_year, from_month, from_day] = date.from.split('-')
    response[0] = new Date(+from_year, +from_month - 1, +from_day)
  }
  if (date.until) {
    const [until_year, until_month, until_day] = date.until.split('-')
    response[1] = new Date(+until_year, +until_month - 1, +until_day)
  }
  return response
}
