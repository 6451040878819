import { FC, useEffect, useState } from 'react'

import axios from '../../shared/Api/axios'

import { Badge } from '@consta/uikit/Badge'
import { translationOfCriteria } from 'shared/utils/translationOfCriteria'

import styles from './Dashboard.module.css'
import { StatusAuthData } from '../../shared/types/statusAuthData'
import AlertCard from '../../components/Dashboard/AlertCard/AlertCard'
import { TypeOfAlertEnum } from '../../shared/types/typeOfAlertEnum'
import { UserRolesEnum } from '../../shared/enums/UserRole'

interface DashboardProps {
  authData: StatusAuthData
}

export const Dashboard: FC<DashboardProps> = ({ authData }) => {
  const [dashboardData, setDashboardData] = useState<any>()

  useEffect(() => {
    axios
      .get(`/api/clients/dashboard`)
      .then(response => {
        setDashboardData(response.data)
      })
      .catch(console.error)
  }, [])
  return (
    <>
      {dashboardData && (
        <div className={styles.wrapper}>
          {authData.role === UserRolesEnum.DEPUTY ? (
            <h3
              className={styles.title}
            >{`Личный кабинет Совета молодых депутатов: ${authData.municipal_entity}`}</h3>
          ) : (
            <h3 className={styles.title}>{`Личный кабинет модератора`}</h3>
          )}
          {authData?.role === UserRolesEnum.DEPUTY && dashboardData?.alert ? (
            <div className={styles.alertCardsContainer}>
              {dashboardData?.timeToSendReport ? (
                <AlertCard
                  dashboardData={dashboardData?.timeToSendReport}
                  typeOfAlert={TypeOfAlertEnum.WARNING}
                />
              ) : null}
              {dashboardData?.timeToSendActivity ? (
                <AlertCard typeOfAlert={TypeOfAlertEnum.INFO} />
              ) : null}
              {dashboardData?.needToUpdateReport ? (
                <AlertCard
                  typeOfAlert={TypeOfAlertEnum.UPDATE_REPORT}
                  dashboardData={dashboardData.needToUpdateReport}
                />
              ) : null}
            </div>
          ) : null}
          <div className={styles.statsCardsContainer}>
            <div className={styles.statsCard}>
              <div className={styles.digitStatsTitle}>Сегодня новых действий</div>
              <div className={styles.digitContainer}>
                <div className={styles.digit}>{dashboardData.today}</div>
                <div
                  className={
                    dashboardData.todayPercent <= 0 ? styles.negativePercent : styles.percent
                  }
                >{`${dashboardData.todayPercent}%`}</div>
              </div>
            </div>
            <div className={styles.statsCard}>
              <div className={styles.digitStatsTitle}>Вчера новых действий</div>
              <div className={styles.digitContainer}>
                <div className={styles.digit}>{dashboardData.yesterday}</div>
                <div
                  className={
                    dashboardData.yesterdayPercent <= 0 ? styles.negativePercent : styles.percent
                  }
                >{`${dashboardData.yesterdayPercent}%`}</div>
              </div>
            </div>
            <div className={styles.statsCard}>
              <div className={styles.digitStatsTitle}>На этой неделе новых действий</div>
              <div className={styles.digitContainer}>
                <div className={styles.digit}>{dashboardData.week}</div>
                <div
                  className={
                    dashboardData.weekPercent <= 0 ? styles.negativePercent : styles.percent
                  }
                >{`${dashboardData.weekPercent}%`}</div>
              </div>
            </div>
            <div className={styles.statsCard}>
              <div className={styles.digitStatsTitle}>До сдачи отчета</div>
              <div className={styles.numberDays}>{dashboardData.reportIsDue}</div>
            </div>
          </div>
          <div className={styles.statsCardsContainer}>
            <div className={styles.criteriaCard}>
              <div className={styles.criteriaStatsTitle}>
                Самые популярные критерии деятельности
              </div>
              <div className={styles.badgeContainer}>
                {translationOfCriteria(dashboardData.popularCriteria).map((item, index) => (
                  <Badge label={item} size='l' view='stroked' status='success' key={index} />
                ))}
              </div>
            </div>
            <div className={styles.criteriaCard}>
              <div className={styles.criteriaStatsTitle}>
                Самые непопулярные критерии деятельности
              </div>
              <div className={styles.badgeContainer}>
                {translationOfCriteria(dashboardData.unpopularCriteria).map((item, index) => (
                  <Badge label={item} size='l' view='stroked' status='warning' key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
