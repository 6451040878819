import { FC, useEffect } from 'react'

import styles from './Layout.module.css'

import { Header } from 'features/Header/Header'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { StatusAuthData } from '../shared/types/statusAuthData'
import { UserRolesEnum } from '../shared/enums/UserRole'

interface LayoutProps {
  authData: StatusAuthData
}

export const Layout: FC<LayoutProps> = ({ authData }) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.startsWith('/auth') || location.pathname === '/') {
      if (authData.role === UserRolesEnum.DEPUTY) {
        navigate('/profile')
      }
      if (authData.role === UserRolesEnum.EDITOR) {
        navigate('/users')
      }
    }
  }, [authData, location.pathname, navigate])

  return (
    <div>
      <Header authData={authData} />
      <div className={styles.mainContainer}>
        <Outlet />
      </div>
    </div>
  )
}
