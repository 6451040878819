import { TextField } from '@consta/uikit/TextField'
import { DatePicker } from '@consta/uikit/DatePicker'
import { Select } from '@consta/uikit/Select'
import { InputGroup } from '../../InputGroup/InputGroup'
import { Field } from 'store/editUser'

interface Props extends Field {
  onChange: (value: any) => void
  [key: string]: any
}

export const FormInput = ({ label, required, type, items, onChange, ...textFields }: Props) => {
  return (
    <InputGroup label={label} required={required}>
      {type === 'text' && (
        <TextField {...textFields} onChange={({ value }) => onChange(value ?? '')} width='full' />
      )}

      {type === 'date' && (
        <DatePicker {...textFields} type='date' onChange={({ value }) => onChange(value)} />
      )}

      {type === 'month-range' && (
        <DatePicker {...textFields} type='month-range' onChange={({ value }) => onChange(value)} />
      )}

      {type === 'select' && (
        <Select {...textFields} items={items ?? []} onChange={({ value }) => onChange(value)} />
      )}
    </InputGroup>
  )
}
