import { EditUserState, Field, FormSectionInterface, FormSectionWithListsInterface } from './types'
import { UserRolesEnum } from '../../shared/enums/UserRole'
import {
  Awards,
  Career,
  EditUserInfoDto,
  Education,
  SocialActivities,
} from 'shared/Api/edit-user-info.dto'
import moment from 'moment'
import { itemsAutoCompleteMunicipality } from '../../shared/constants/itemsAutoCompleteMunicipality'
import { itemsAutoCompleteTypeOfEducation } from '../../shared/utils/typeOfEducationMappingForEditCard'

export function buildField(field: Partial<Field>): Field {
  return {
    type: 'text',
    label: field.label ?? '',
    required: field.required ?? false,
    value: field.value ?? '',
    ...field,
  }
}

export const filterDeleted = (item: any) => item.method !== 'DELETE'

export function buildMainInfoSection(state: EditUserState): FormSectionInterface {
  return {
    title: 'Основная информация',
    fields: [
      {
        type: 'date',
        label: 'Дата рождения',
        required: true,
        value: state.birth_date ? new Date(state.birth_date) : null,
      },
      {
        type: 'text',
        label: 'Email',
        required: true,
        value: state.email,
      },
      {
        type: 'text',
        label: 'Телефон',
        required: true,
        value: state.phone,
      },
      {
        type: 'text',
        label: 'Должность',
        required: true,
        value: state.position,
      },
      {
        type: 'text',
        label: 'Комитет',
        required: true,
        value: state.committee,
      },
      {
        type: 'select',
        label: 'Муниципальное образование',
        required: true,
        value: itemsAutoCompleteMunicipality.find(x => x.label === state.municipal_entity) ?? null,
        items: itemsAutoCompleteMunicipality,
      },
      {
        type: 'text',
        label: 'Партия',
        required: true,
        value: state.party,
      },
      ...state.social_medias.filter(filterDeleted).map((link: any) =>
        buildField({
          type: 'text',
          label: 'Ссылка на социальные сети',
          // @ts-ignore
          isremovable: 'true',
          value: link.link,
        })
      ),
    ],
    button: {
      label: 'Добавить социальную сеть',
      view: 'secondary',
    },
  }
}

export function buildSystemInfoSection(state: EditUserState): FormSectionInterface {
  const items = [
    {
      label: 'Пользователь',
      id: 1,
    },
    {
      label: 'Депутат',
      id: 2,
    },
    {
      label: 'Модератор',
      id: 3,
    },
  ]

  const selectedItem = {
    [UserRolesEnum.THIRD_FACES]: 0,
    [UserRolesEnum.DEPUTY]: 1,
    [UserRolesEnum.EDITOR]: 2,
  }

  return {
    title: 'Системная информация',
    fields: [
      {
        type: 'select',
        label: 'Роль',
        required: true,
        value: items[selectedItem[state.role] ?? 0],
        items,
      },
    ],
  }
}

export function buildEducationSection(state: EditUserState): FormSectionWithListsInterface {
  return {
    title: 'Образование',
    fields: state.educations.filter(filterDeleted).map((education: Education) => [
      buildField({
        type: 'month-range',
        label: 'Период обучения',
        required: true,
        value: education.period ? education.period.split('|').map(x => new Date(x)) : null,
      }),
      buildField({
        type: 'text',
        label: 'Специальность',
        required: true,
        value: education.specialty,
      }),
      buildField({
        type: 'text',
        label: 'Факультет',
        required: true,
        value: education.faculty,
      }),
      buildField({
        type: 'text',
        label: 'Учебное заведение',
        required: true,
        value: education.institution_name,
      }),
      buildField({
        type: 'select',
        label: 'Тип образования',
        required: true,
        value:
          itemsAutoCompleteTypeOfEducation.find(x =>
            x.label === education.type_of_education
              ? x.label
              : x.value === education.type_of_education
          ) ?? null,
        items: itemsAutoCompleteTypeOfEducation,
      }),
    ]),
    button: {
      label: 'Добавить образование',
      view: 'secondary',
    },
  }
}

export function buildCareerSection(state: EditUserState): FormSectionWithListsInterface {
  const fields = state.careers.filter(filterDeleted).map((career: Career) => [
    buildField({
      type: 'month-range',
      label: 'Период работы',
      required: true,
      value: career.period ? career.period.split('|').map(x => new Date(x)) : null,
    }),
    buildField({
      type: 'text',
      label: 'Должность',
      required: true,
      value: career.position,
    }),
    buildField({
      type: 'text',
      label: 'Организация',
      required: true,
      value: career.organization,
    }),
  ])
  return {
    title: 'Карьера',
    fields,
    button: {
      label: 'Добавить опыт работы',
      view: 'secondary',
    },
  }
}

export function buildActivitiesSection(state: EditUserState): FormSectionWithListsInterface {
  return {
    title: 'Общественная деятельность',
    fields: state.social_activities.filter(filterDeleted).map((activity: SocialActivities) => [
      buildField({
        type: 'month-range',
        label: 'Период',
        value: activity.period ? activity.period.split('|').map(x => new Date(x)) : null,
      }),
      buildField({
        type: 'text',
        label: 'Должность',
        value: activity.position,
      }),
    ]),
    button: { label: 'Добавить общественную деятельность', view: 'secondary' },
  }
}

export function buildAwardsSection(state: EditUserState): FormSectionWithListsInterface {
  return {
    title: 'Награды',
    fields: state.awards.filter(filterDeleted).map((awardsSection: Awards) => [
      buildField({
        type: 'text',
        label: 'Название награды',
        value: awardsSection.description,
      }),
    ]),
    button: {
      label: 'Добавить награду',
      view: 'secondary',
    },
  }
}

export function prepareStateToBody(state: EditUserState): EditUserInfoDto {
  return {
    ...state,
    file_deleted: state.file_deleted,
    birth_date: moment(state.birth_date).format('DD.MM.YYYY'),
    municipal_entity: state.municipal_entity ?? '',
    social_medias: state.social_medias.filter(obj => obj.link !== ''),
    social_activities: state.social_activities.filter(
      obj => obj.position !== '' && obj.period !== null
    ),
    careers: state.careers.filter(
      obj => obj.position !== '' && obj.organization !== '' && obj.period !== null
    ),
    educations: state.educations.filter(
      obj =>
        obj.faculty !== '' &&
        obj.institution_name !== '' &&
        obj.specialty !== '' &&
        obj.type_of_education !== '' &&
        obj.period !== null
    ),
    awards: state.awards.filter(obj => obj.description !== ''),
  }
}
