import { createContext, useEffect, useState } from 'react'

import axios from './shared/Api/axios'

import { Route, Routes, useLocation } from 'react-router-dom'

import { Auth } from 'features/Auth/Auth'
import { Layout } from 'layout/Layout'
import { Users } from 'features/UsersFeatures/Users/Users'
import { User } from 'features/UsersFeatures/User/User'
import { EditUser } from 'features/UsersFeatures/EditUser/EditUser'
import { Support } from 'features/Support/Support'
import { Activity } from 'features/ActivitiesFeatures/Activity/Activity'
import { ActivityCardsPage } from 'features/ActivitiesFeatures/ActivityCardsPage/ActivityCardsPage'
import { CreateActivity } from 'features/ActivitiesFeatures/CreateActivity/CreateActivity'
import { Dashboard } from 'features/Dashboard/Dashboard'
import { StatusAuthData } from './shared/types/statusAuthData'
import { ReportTable } from './features/ReportsFeatures/ReportTable/ReportTable'
import { ReportView } from './features/ReportsFeatures/ReportView/ReportView'
import { ReportCreate } from './features/ReportsFeatures/ReportCreate/ReportCreate'
import { ReportReview } from './features/ReportsFeatures/ReportReview/ReportReview'
import ReportViewById from './features/ReportsFeatures/ReportViewById/ReportViewById'
import { UserRolesEnum } from './shared/enums/UserRole'
import ActivityEdit from './features/ActivitiesFeatures/ActivityEdit/ActivityEdit'

const initialValue: StatusAuthData = {
  first_name: '',
  last_name: '',
  second_name: '',
  role: UserRolesEnum.THIRD_FACES,
  canCreateReport: false,
  municipal_entity: '',
  image: {
    url: 'null',
  },
  userId: 0,
  isLoaded: false,
}

export const AuthDataContext = createContext(initialValue)

function App() {
  const [authData, setAuthData] = useState(initialValue)

  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== '/auth') {
      axios.get('/api/auth/status').then(response => {
        setAuthData({
          ...response.data,
          isLoaded: true,
        })
      })
    }
  }, [location])

  return (
    <AuthDataContext.Provider value={authData ?? initialValue}>
      <Routes>
        <Route path='/auth' element={<Auth />} />
        <Route path='/support' element={<Support />} />
        <Route path='/' element={<Layout authData={authData} />}>
          <Route path='/users' element={<Users />} />
          <Route path='/users/:userId' element={<User authData={authData} />} />
          <Route path='/users/:userId/edit' element={<EditUser />} />
          <Route path='/profile' element={<User authData={authData} />} />
          <Route path='/profile/edit' element={<EditUser />} />
          <Route path='/board' element={<Dashboard authData={authData} />} />
          <Route path='/activity' element={<Activity authData={authData} />} />
          <Route path='/activity/:activityId' element={<ActivityCardsPage />} />
          <Route path='/edit-activity/:activityId' element={<ActivityEdit authData={authData} />} />
          <Route path='/activity/createActivity' element={<CreateActivity authData={authData} />} />
          <Route
            path='/reports/statistic-by-query'
            element={<ReportView type={'by-activity'} auth={authData} />}
          />
          <Route path='/reports' element={<ReportTable authData={authData} />} />
          <Route path='/reports/create' element={<ReportCreate authData={authData} />} />
          <Route
            path='/reports/view/quarter'
            element={<ReportView type={'quarter'} auth={authData} />}
          />
          <Route path='/reports/view/year' element={<ReportView type={'year'} auth={authData} />} />
          <Route path='/reports/:reportId' element={<ReportViewById authData={authData} />} />
          <Route path='/reports/:reportId/edit' element={<ReportReview authData={authData} />} />
        </Route>
      </Routes>
    </AuthDataContext.Provider>
  )
}

export default App
