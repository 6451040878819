import { useState } from 'react'

import { TextField } from '@consta/uikit/TextField'
import { Button } from '@consta/uikit/Button'

import edit from '../../../assets/icons/edit.svg'

import styles from './ReportEditElement.module.css'
import { DragNDropField } from '@consta/uikit/DragNDropField'
import { IconAttach } from '@consta/icons/IconAttach'
import { Attachment } from '@consta/uikit/Attachment'
import { IconTrash } from '@consta/icons/IconTrash'

interface IValue {
  origin_number: number
  comment: string | null
  new_number: number
  files?: File[]
}

interface Props {
  label: string
  value: IValue
  onChange: (value: { comment: string | null; new_number: number; files?: File[] }) => void
}

export const ReportEditElement = ({ label, value, onChange }: Props) => {
  const [isHovering, setIsHovering] = useState(false)

  const [isEditModeOn, setIsEditModeOn] = useState(false)

  const [form, setForm] = useState<IValue>(value)

  const backgroundColor = isHovering || isEditModeOn ? '#F2F6F7' : '#FFF'

  const handleOpenEditMode = () => {
    if (!isEditModeOn) {
      setIsEditModeOn(true)
    }
  }

  const handleChangeNumber = (new_number: number) => {
    setForm({
      ...form,
      new_number,
    })
  }

  const handleChangeComment = (comment: string | null) => {
    setForm({
      ...form,
      comment,
    })
  }

  const handleChangeFiles = (files: File[], deleteFile = false) => {
    const filesToUpdate = deleteFile ? files : [...(form.files ?? []), ...files]
    setForm({
      ...form,
      files: filesToUpdate,
    })
  }

  const handleButtonCancel = () => {
    setIsEditModeOn(false)
    setIsHovering(false)
  }

  const handleButtonSave = () => {
    setIsEditModeOn(false)
    setIsHovering(false)
    onChange(form)
  }
  return (
    <div
      className={styles.container}
      style={{ backgroundColor }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={handleOpenEditMode}
    >
      <TopRow
        isEditMode={isEditModeOn}
        label={label}
        value={
          form.origin_number !== form.new_number
            ? form.new_number.toString()
            : form.origin_number.toString()
        }
        isIconHidden={isHovering}
        onChange={handleChangeNumber}
      />
      {isEditModeOn && (
        <MiddleRow
          comment={form.comment}
          files={form.files}
          onChangeComment={handleChangeComment}
          onChangeFiles={handleChangeFiles}
        ></MiddleRow>
      )}
      {isEditModeOn && (
        <BottomRow onCancel={handleButtonCancel} onSave={handleButtonSave}></BottomRow>
      )}
    </div>
  )
}

const TopRow = ({
  isEditMode,
  isIconHidden,
  label,
  value,
  onChange,
}: {
  isEditMode: boolean
  label: string
  value: string | null
  isIconHidden: boolean
  onChange: (value: number) => void
}) => {
  const marginRight = isIconHidden ? '24px' : '39px'
  return (
    <div className={styles.topRow}>
      <div className={styles.topRowLabel}>{label}</div>
      <div>
        {isEditMode ? (
          <TextField
            type='number'
            size='m'
            onChange={({ value }) => onChange(+(value ?? 0))}
            value={value}
            min='0'
            max='500'
            readOnly={true}
          />
        ) : (
          <span style={{ marginRight }}>{value}</span>
        )}
        {isIconHidden && !isEditMode && <img width={'15px'} height={'15px'} src={edit} alt='' />}
      </div>
    </div>
  )
}

const BottomRow = ({ onCancel, onSave }: { onCancel: () => void; onSave: () => void }) => {
  return (
    <div className={styles.buttonContainer}>
      <Button label='Отмена' view='ghost' onClick={onCancel} />
      <Button label='Сохранить' onClick={onSave} />
    </div>
  )
}

const MiddleRow = ({
  comment,
  onChangeComment,
  files,
  onChangeFiles,
}: {
  comment: string | null
  onChangeComment: (comment: string | null) => void
  files?: File[]
  onChangeFiles: (files: File[], deleteFile?: boolean) => void
}) => {
  return (
    <div className={styles.middleRow}>
      <div className={styles.middleRowInRow}>
        <TextField
          rows={6}
          style={{ width: 'auto', flexBasis: '50%' }}
          type='textarea'
          placeholder='Причина изменения показателя'
          value={comment}
          onChange={({ value }) => onChangeComment(value)}
        />
        <DragNDropField
          style={{ width: 'auto', flexBasis: '50%' }}
          accept={['.pdf', '.docx']}
          multiple={true}
          onDropFiles={onChangeFiles}
        >
          {({ openFileDialog }) => (
            <>
              <div className={styles.middleRowTopText}>
                Перетащите файлы сюда или нажмите на кнопку
              </div>
              <div className={styles.middleRowBottomText}>Поддерживаемые форматы: PDF, DOCX</div>
              <Button
                onClick={openFileDialog}
                label='Загрузить файл'
                iconLeft={IconAttach}
                view='ghost'
                size='s'
              />
            </>
          )}
        </DragNDropField>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {files?.map((file, i) => (
          <Attachment
            style={{ width: 'auto', marginRight: '10px', marginBottom: '10px' }}
            key={i}
            fileName={file.name}
            fileExtension={file.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
            fileDescription={file.type}
            buttonIcon={IconTrash}
            onButtonClick={e => {
              e.stopPropagation()
              onChangeFiles(
                files.filter((f, _i) => _i !== i),
                true
              )
            }}
            withAction={true}
          />
        ))}
      </div>
    </div>
  )
}
