import { ReportAsSectionModel, ReportAsSectionWithValues, ReportFields, ReportState } from './types'
import { ReportOriginalAndActualDTO, ReportStatisticDTO } from '../../shared/Api/reports'

export const ReportMapping: ReportAsSectionModel[] = [
  {
    title: '1. Взаимодействие с избирателями и развитие местного самоуправления',
    items: [
      {
        key: 'personal_reception',
        label: '1.1. Количество проведённых личных приёмов граждан',
      },
      {
        key: 'received_enquiries',
        label: '1.2. Количество принятых обращений (письменные, устные и иные)',
      },
      {
        key: 'solved_appeal',
        label: '1.3. Количество обращений, решенных положительно',
      },
    ],
  },
  {
    title:
      '2. Организация и проведение членами Совета (группы) молодых депутатов в отчетном периоде мероприятий',
    items: [
      {
        key: 'event_held',
        label:
          '2.1. Количество мероприятий, проведенных молодыми депутатами Совета (группы) (круглые столы, лекции, мастер классы и иные)',
      },
      {
        key: 'participation_in_session',
        label: '2.2. Участие в сессиях, комитетах, комиссиях и иное',
      },
      {
        key: 'participation_in_citizenship_meeting',
        label:
          '2.3. Участие членов Совета (группы) в собраниях (сходах) граждан в муниципальном образовании',
      },
      {
        key: 'participation_in_municipal_entity_meeting',
        label: '2.4. Количество общих собраний Совета (группы) в муниципальном образовании',
      },
    ],
  },

  {
    title: '3. Мероприятия по обучению и повышению квалификации депутатов Совета (группы)',
    items: [
      {
        key: 'training_event_by_municipal_entity',
        label:
          '3.1. Количество обучающих мероприятий, организованных Советами (группами) в муниципальном образовании',
      },
      {
        key: 'training_event_with_participation_municipal_entity',
        label:
          '3.2. Количество обучающих мероприятий, в которых приняли участие члены Совета (группы) в муниципальном образовании',
      },
      {
        key: 'trained_deputies',
        label: '3.3. Количество депутатов, прошедших обучение',
      },
    ],
  },

  {
    title: '4. Проектная деятельность',
    items: [
      {
        key: 'municipal_project',
        label: '4.1. Количество реализованных проектов муниципального уровня',
      },
      {
        key: 'regional_project',
        label: '4.2. Количество реализованных проектов краевого уровня',
      },
    ],
  },

  {
    title: '5. Нормотворческая деятельность',
    items: [
      {
        key: 'legislative_initiative_of_municipal_entity',
        label: '5.1. Количество законодательных инициатив, принятых в представительном органе МО',
      },
      {
        key: 'legislative_initiative_of_ZSK',
        label:
          '5.2. Количество законодательных инициатив, принятых в Законодательном Собрании края',
      },
    ],
  },
  {
    title: '6. Взаимодействие с Законодательным Собранием Краснодарского края',
    items: [
      {
        key: 'participation_of_deputy_ZSK',
        label:
          '6.1. Количество мероприятий, проведенных молодыми депутатами в муниципальном образовании с участием депутатов Законодательного Собрания Краснодарского края',
      },
      {
        key: 'participation_of_deputy_GD',
        label:
          '6.2. Количество мероприятий, проведенных молодыми депутатами в муниципальном образовании с участием депутатов Госдумы РФ',
      },
      {
        key: 'bureau_of_presidium',
        label:
          '6.3. Участие молодых депутатов в заседаниях Бюро президиума и президиума Совета молодых депутатов Краснодарского края',
      },
    ],
  },
  {
    title: '7. Информационное обеспечение деятельности советов (групп)',
    items: [
      {
        key: 'quantity_of_posted_activities_in_smd_web_site',
        label:
          '7.1. Количество материалов о деятельности Совета (группы) на официальном сайте СМД Краснодарского края',
      },
      {
        key: 'publication_links_municipal_entity',
        label: '7.2. Количество публикаций на официальных сайтах органов местного самоуправления',
      },
      {
        key: 'publication_links_mass_media',
        label:
          '7.3. Количество публикаций о деятельности молодых депутатов в городских и районных СМИ',
      },
      {
        key: 'publication_links_in_internet_resources',
        label: '7.4. Количество публикаций на других Интернет-ресурсах',
      },
    ],
  },
]
export function getMappedDataForEditor(report: ReportState): ReportAsSectionWithValues[] {
  return ReportMapping.map(item => {
    return {
      ...item,
      items: item.items.map(subItem => {
        return {
          ...subItem,
          value: {
            original: report.original[subItem.key],
            actual: report.actual[subItem.key],
            files: report.files[subItem.key],
            arguments: report.arguments[subItem.key],
          },
        }
      }),
    }
  })
}
export function getMappedDataActivity(report: ReportStatisticDTO): {
  items: { value: { [x: string]: number }; label: string; key: keyof ReportFields<string> }[]
  title: string
}[] {
  return ReportMapping.map(item => {
    return {
      ...item,
      items: item.items.map(subItem => {
        return {
          ...subItem,
          value: {
            [subItem.key]: report?.[subItem.key],
          },
        }
      }),
    }
  })
}
export function getMappedData(report: ReportState): ReportAsSectionWithValues[] {
  return ReportMapping.map(item => {
    return {
      ...item,
      items: item.items.map(subItem => {
        return {
          ...subItem,
          value: {
            original: report.original[subItem.key],
            actual: report.actual[subItem.key],
            files: report.files[subItem.key],
            arguments: report.arguments[subItem.key],
          },
        }
      }),
    }
  })
}
