import { ActivityStatusEnum } from '../../shared/types/activityStatusEnum'
import { ReportFields } from './types'
import {
  ReportArgumentsDTO,
  ReportFilesDTO,
  ReportRootDTO,
  ReportStatisticDTO,
} from '../../shared/Api/reports'
import { StatusAuthData } from '../../shared/types/statusAuthData'

export enum ACTIONS {
  SET_DATA_FOR_NEW,
  SET_DATA_FOR_EXISTING,
  UPDATE_COMMENT_FIELD,
  UPDATE_STATUS_FIELD,
  UPDATE_ACTUAL_FIELD,
  UPDATE_ARGUMENTS_FIELD,
  UPDATE_FILES_FIELD,
  SEND_DATA_FAILED,
  SEND_DATA_SUCCESS,
}

export interface SET_DATA_FOR_NEW {
  type: ACTIONS.SET_DATA_FOR_NEW
  payload: {
    actual: ReportStatisticDTO
    authData: StatusAuthData
    original: ReportStatisticDTO
    typeOfReport: string
  }
}

export interface SET_DATA_FOR_EXISTING {
  type: ACTIONS.SET_DATA_FOR_EXISTING
  payload: {
    root: ReportRootDTO
    original: ReportStatisticDTO
    actual: ReportStatisticDTO
    files: ReportFilesDTO
    arguments: ReportArgumentsDTO
  }
}
export interface UPDATE_COMMENT_FIELD {
  type: ACTIONS.UPDATE_COMMENT_FIELD
  payload: {
    comment: string
    isDataChanged: boolean
  }
}
export interface UPDATE_STATUS_FIELD {
  type: ACTIONS.UPDATE_STATUS_FIELD
  payload: ActivityStatusEnum
}
export interface UPDATE_ACTUAL_FIELD {
  type: ACTIONS.UPDATE_ACTUAL_FIELD
  payload: {
    key: keyof ReportFields<string>
    value: string
  }
}
export interface UPDATE_ARGUMENTS_FIELD {
  type: ACTIONS.UPDATE_ARGUMENTS_FIELD
  payload: {
    key: keyof ReportFields<string>
    value: string
  }
}
export interface UPDATE_FILES_FIELD {
  type: ACTIONS.UPDATE_FILES_FIELD
  payload: {
    key: keyof ReportFields<File[]>
    value: File[]
  }
}
export interface SEND_DATA_FAILED {
  type: ACTIONS.SEND_DATA_FAILED
  payload: unknown
}
export interface SEND_DATA_SUCCESS {
  type: ACTIONS.SEND_DATA_SUCCESS
  payload: unknown
}

export type ReportActions =
  | SET_DATA_FOR_NEW
  | SET_DATA_FOR_EXISTING
  | UPDATE_COMMENT_FIELD
  | UPDATE_STATUS_FIELD
  | UPDATE_ACTUAL_FIELD
  | UPDATE_ARGUMENTS_FIELD
  | UPDATE_FILES_FIELD
  | SEND_DATA_FAILED
  | SEND_DATA_SUCCESS
