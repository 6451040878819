import { ItemsSelectStatusActive } from 'shared/types/itemsSelectStatusActive'

export const itemsSelectStatusActive: ItemsSelectStatusActive[] = [
  {
    label: 'Подтверждено',
    id: 1,
    statusFilter: 'approved',
  },
  // {
  //   label: 'Проверка',
  //   id: 2,
  //   statusFilter: 'checked',
  // },
  // {
  //   label: 'Исправление',
  //   id: 3,
  //   statusFilter: 'fixed',
  // },
  {
    label: 'Выгружен на сайт СМД',
    id: 4,
    statusFilter: 'uploaded',
  },
]
