import { ItemSelectStatus } from 'shared/types/itemSelectStatus'

export const itemsSelectStatus: ItemSelectStatus[] = [
  {
    label: 'Новый',
    id: 1,
    statusFilter: 'new',
  },
  {
    label: 'Активный',
    id: 2,
    statusFilter: 'active',
  },
  {
    label: 'Заблокирован',
    id: 5,
    statusFilter: 'blocked',
  },
]
