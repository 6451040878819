import { FC, useState } from 'react'

import Axios from 'axios'

import { LogoBlock } from 'components/LogoBlock/LogoBlock'

import question from '../../assets/icons/question.svg'

import { useNavigate } from 'react-router-dom'
import { TextField } from '@consta/uikit/TextField'
import { Button } from '@consta/uikit/Button'

import { useForm } from 'react-hook-form'

import styles from './Auth.module.css'

interface IValue {
  value: string | null
}

interface FormValues {
  email: string
  password: string
}

export const Auth: FC = () => {
  const navigate = useNavigate()
  const axios = Axios.create()

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<FormValues>()

  const [valueEmail, setValueEmail] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [valuePassword, setValuePassword] = useState<string | null>(null)
  const handleChangeEmail = ({ value }: IValue) => {
    setValueEmail(value ? value.trim() : value)
  }
  const handleChangePassword = ({ value }: IValue) => setValuePassword(value)

  const [isHidden, setIsHidden] = useState<boolean>(true)

  const handleButtonEmail = () => {
    setIsLoading(true)
    axios
      .post('/api/auth/send-code', { email: valueEmail?.toLowerCase() })
      .then(response => {
        if (response.data.success) {
          setIsHidden(!isHidden)
        }
      })
      .catch(err => {
        const message =
          err.response.status === 401
            ? 'Пользователь с таким email не найден'
            : 'Произошла ошибка при отправке email'
        setError('email', {
          type: 'manual',
          message,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleButtonPassword = () => {
    setIsLoading(true)
    axios
      .post(
        '/api/auth/login',
        {
          email: valueEmail,
          code: valuePassword,
        },
        {
          withCredentials: true,
        }
      )
      .then(response => {
        if (response.data.success) {
          navigate('/')
        }
      })
      .catch(err => {
        setError('password', {
          type: 'manual',
          message:
            err.response.status === 401
              ? 'Неверный пароль'
              : 'Произошла ошибка при отправке пароля',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function callOnEnter(action: () => void): (event: any) => void {
    return (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        action()
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.authFooterContainer}>
        <LogoBlock />
      </div>
      <div className={styles.loginFormContainer}>
        <div className={styles.title}>Вход в личный кабинет</div>
        {isHidden ? (
          <>
            <TextField
              {...register('email', {
                required: 'Обязательное поле',
                pattern: /^[a-z0-9-._]+@[a-z0-9]+\.[a-z]{2,4}$/i,
              })}
              onChange={handleChangeEmail}
              onKeyPress={callOnEnter(handleButtonEmail)}
              value={valueEmail}
              placeholder='Email'
              name='email'
              width='full'
              style={{ marginBottom: '8px' }}
              status={errors?.email ? 'alert' : undefined}
              caption={errors?.email && (errors?.email?.message || 'Неправильный email')}
              disabled={isLoading}
            />
            <div className={styles.labelTextFild}>
              Адрес электронной почты, на который зарегистрирован аккаунт
            </div>
            <Button
              label='Получить пароль'
              width='full'
              onClick={handleSubmit(handleButtonEmail)}
              style={{ marginBottom: '32px' }}
              disabled={isLoading}
            />
          </>
        ) : (
          <>
            <div className={styles.labelText}>Мы отправили пароль на email, который вы указали</div>
            <TextField
              {...register('password', {
                required: 'Обязательное поле',
              })}
              onChange={handleChangePassword}
              onKeyPress={callOnEnter(handleButtonPassword)}
              value={valuePassword}
              type='password'
              placeholder='Пароль'
              width='full'
              style={{ marginBottom: '24px' }}
              name='password'
              status={errors?.password && 'alert'}
              caption={errors?.password && errors?.password?.message}
              disabled={isLoading}
            />
            <Button
              label='Войти'
              width='full'
              style={{ marginBottom: '32px' }}
              onClick={handleSubmit(handleButtonPassword)}
              disabled={isLoading}
            />
          </>
        )}
        <div className={styles.supportContainer}>
          <img src={question} alt='' />
          <a
            href={'https://forms.yandex.ru/cloud/64588ab63e9d08376db197d6/'}
            target='_blank'
            rel='noreferrer'
            className={styles.support}
          >
            Служба поддержки
          </a>
        </div>
      </div>
    </div>
  )
}
