import { useState, useEffect, useContext } from 'react'

import { useParams } from 'react-router-dom'

import { Button } from '@consta/uikit/Button'

import { AuthDataContext } from 'App'
import { Content } from 'features/ActivitiesFeatures/ActivitiCards/Content/Content'
import { SystemInformation } from 'features/ActivitiesFeatures/ActivitiCards/SystemInformation/SystemInformation'
import { InteractionWithVoters } from 'features/ActivitiesFeatures/ActivitiCards/InteractionWithVoters/InteractionWithVoters'
import { InformationActivities } from 'features/ActivitiesFeatures/ActivitiCards/InformationActivities/InformationActivities'

import styles from './ActivityCardsPage.module.css'
import { Attributes, changeStatusById, getActivitiesById } from '../../../shared/Api/activities'
import { ActivityStatusEnum } from '../../../shared/types/activityStatusEnum'
import { UserRolesEnum } from '../../../shared/enums/UserRole'

export const ActivityCardsPage = () => {
  const authData = useContext(AuthDataContext)

  const { activityId } = useParams()

  const [activityData, setActivityData] = useState<Attributes>()
  const [isLoaded, setIsLoaded] = useState<boolean>(true)

  useEffect(() => {
    if (activityId && !isNaN(parseInt(activityId))) {
      setIsLoaded(false)
      getActivitiesById(+activityId)
        .then(data => {
          setActivityData(data.data.attributes)
        })
        .catch(console.error)
        .finally(() => {
          setIsLoaded(true)
        })
    }
  }, [activityId])

  const handleSendingActivity = () => {
    if (!activityId || isNaN(parseInt(activityId))) {
      console.error('activityId is not defined')
      return
    }
    const status =
      activityData?.status === ActivityStatusEnum.UPLOADED
        ? ActivityStatusEnum.APPROVED
        : ActivityStatusEnum.UPLOADED
    setIsLoaded(false)
    changeStatusById(+activityId, status)
      .then(() => {
        // @ts-ignore
        setActivityData({ ...activityData, status })
      })
      .catch(console.error)
      .finally(() => {
        setIsLoaded(true)
      })
  }

  return (
    <div className={styles.wrapper}>
      {!activityData ? (
        <div>Загрузка...</div>
      ) : (
        <>
          {authData.role === UserRolesEnum.EDITOR && (
            <div className={styles.buttonContainer}>
              <Button
                label={
                  activityData.status === ActivityStatusEnum.UPLOADED
                    ? 'Отменить выгрузку'
                    : 'Пометить выгруженным'
                }
                onClick={handleSendingActivity}
                disabled={!isLoaded}
              />
            </div>
          )}
          <Content activityData={activityData} authData={authData} id={activityId} />
          <SystemInformation activityData={activityData} />
          <div className={styles.flexContainer}>
            <InteractionWithVoters activityData={activityData} />
            <InformationActivities activityData={activityData} />
          </div>
        </>
      )}
    </div>
  )
}
