import moment from 'moment'

export function getQuarter(date: Date = new Date()) {
  return moment(date).subtract(7, 'days').quarter()
}
export function getQuarterNow(date: Date = new Date()) {
  return moment(date).quarter()
}
export function getQuarterAsGreekNow(date: Date = new Date()) {
  const quarter = getQuarterNow()
  return {
    1: 'Ι',
    2: 'ΙΙ',
    3: 'ΙΙΙ',
    4: 'ΙV',
  }[quarter]
}
export function getQuarterAsGreek(date: Date = new Date()) {
  const quarter = getQuarter(date)
  return {
    1: 'Ι',
    2: 'ΙΙ',
    3: 'ΙΙΙ',
    4: 'ΙV',
  }[quarter]
}
