import { FC, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Table } from '@consta/uikit/Table'
import { AutoComplete } from '@consta/uikit/AutoCompleteCanary'
import { TextField } from '@consta/uikit/TextField'
import { Button } from '@consta/uikit/Button'
import { Combobox } from '@consta/uikit/Combobox'

import { IconSearchStroked } from '@consta/icons/IconSearchStroked'

import { ModalCreateUser } from 'features/UsersFeatures/ModalCreateUser/ModalCreateUser'

import { itemsSelectRole } from 'shared/constants/itemsSelectRole'
import { itemsSelectStatus } from 'shared/constants/itemsSelectStatus'
import { itemsAutoCompleteMunicipality } from 'shared/constants/itemsAutoCompleteMunicipality'

import { FilteringOptionsForUsers } from 'shared/types/filteringOptionsForUsers'
import { User } from 'shared/types/user'

import activity from '../../../assets/icons/activity.svg'
import subtract from '../../../assets/icons/subtract.svg'

import styles from './Users.module.css'
import { useInView } from 'react-intersection-observer'
import {
  filterParamsUserReq,
  getParamsFromUrlForUsers,
  getUsersNavigateUrl,
  structureUser,
} from '../../../shared/utils/helper'
import { getListOfUsers } from '../../../shared/Api/users'

enum TagName {
  IMG = 'IMG',
}

const columns: any = [
  {
    title: '№',
    accessor: 'serial',
    renderCell: (row: any) => {
      return <div>{row.serial + 1}</div>
    },
  },
  {
    title: 'Местоположение',
    accessor: 'fullName',
    sortable: true,
  },
  {
    title: 'ID',
    accessor: 'id',
  },
  {
    title: 'Статус',
    accessor: 'status',
    renderCell: (row: any) => (
      <div
        className={`${
          row.status === 'Новый'
            ? styles.newStatus
            : row.status === 'Активный'
            ? styles.activeStatus
            : styles.blockedStatus
        }`}
      >
        {row.status}
      </div>
    ),
  },
  {
    title: 'Email',
    accessor: 'email',
  },
  {
    title: 'Роль',
    accessor: 'role',
  },
  {
    title: 'Дата создания',
    accessor: 'updatedAt',
    renderCell: () => (
      <div style={{ display: 'flex', gap: '27px' }}>
        <div>15.02.2023</div>
        <div style={{ display: 'flex', gap: '22px' }}>
          <img src={activity} alt='activity' />
        </div>
      </div>
    ),
  },
]

export const Users: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(20)
  const { ref, inView } = useInView({
    threshold: 0.1,
  })
  const [firstLoad, setFirstLoad] = useState(true)
  const [listOfUsers, setListOfUsers] = useState<any>([])
  const [isModalWindowOpened, setIsModalWindowOpened] = useState(false)
  const [filterParameters, setFilterParameters] = useState<FilteringOptionsForUsers>({
    municipality: null,
    role: null,
    status: null,
    search: null,
  })
  useEffect(() => {
    if (firstLoad) {
      setFilterParameters({
        ...getParamsFromUrlForUsers(queryParams),
      })
    }
  }, [firstLoad])
  useEffect(() => {
    if (inView && listOfUsers.length + limit < total) {
      setOffset(listOfUsers.length + 1)
    } else if (inView && total - listOfUsers.length > 0) {
      setLimit(total - listOfUsers.length - 1)
      setOffset(listOfUsers.length + 1)
    }
  }, [inView, listOfUsers])

  useEffect(() => {
    const paramsForReq = filterParamsUserReq({
      ...filterParameters,
      status: filterParameters?.status?.statusFilter ?? null,
      role: filterParameters?.role?.value ?? null,
    })
    getListOfUsers({ ...paramsForReq, limit, offset }).then(response => {
      const users = structureUser(response.data, offset)
      if (firstLoad) {
        setFirstLoad(false)
      }
      setTotal(response.total)
      limit === 20 && offset === 0
        ? setListOfUsers(users)
        : setListOfUsers([...listOfUsers, ...users])
    })
  }, [filterParameters, offset, limit])

  const exportUrl =
    `/api/clients/export-by-filter?` +
    `first_field=${encodeURIComponent(filterParameters.search || '')}` +
    `&municipal_entity=${encodeURIComponent(filterParameters.municipality || '')}` +
    `&role=${encodeURIComponent(filterParameters?.role?.value || '')}` +
    `&status=${encodeURIComponent(filterParameters?.status?.statusFilter || '')}`
  const handleChangeFilterParameters = (value: any, field: any) => {
    setFilterParameters(prev => ({
      ...prev,
      [field]: value,
    }))
    setLimit(20)
    setOffset(0)
    navigate(
      getUsersNavigateUrl({
        ...filterParameters,
        [field]: value,
      })
    )
  }

  const handleClickRow = ({ id, e }: { id: any; e?: any }): void => {
    if (e.target.tagName === TagName.IMG) {
      const user = listOfUsers.find((user: User) => user.id === id)
      navigate(`/activity?members[]=${user?.id}`)
    } else {
      navigate(`${id}`)
    }
  }
  return (
    <div className={styles.wrapper}>
      <ModalCreateUser isOpened={isModalWindowOpened} onToggle={setIsModalWindowOpened} />
      <div className={styles.headerContainer}>
        <h3 className={styles.title}>Список пользователей</h3>
        <div className={styles.buttonIconFlex}>
          <a href={exportUrl} download>
            <img src={subtract} alt='' />
          </a>
          <Button
            label='Создать пользователя'
            style={{ width: '227px' }}
            onClick={() => setIsModalWindowOpened(!isModalWindowOpened)}
          />
        </div>
      </div>
      <div className={styles.autoCompleteContainer}>
        <TextField
          onChange={e => handleChangeFilterParameters(e.value, 'search')}
          value={filterParameters.search}
          placeholder='Введите ФИО, ID или email'
          leftSide={IconSearchStroked}
          style={{ minWidth: '471px' }}
          name='search'
        />
        <AutoComplete
          type='text'
          placeholder='МО'
          value={filterParameters.municipality}
          items={itemsAutoCompleteMunicipality}
          onChange={e => handleChangeFilterParameters(e.value, 'municipality')}
          width='full'
          name='municipality'
        />
        <Combobox
          placeholder='Роль'
          items={itemsSelectRole}
          value={filterParameters.role}
          onChange={e => handleChangeFilterParameters(e.value, 'role')}
        />
        <Combobox
          placeholder='Статус'
          items={itemsSelectStatus}
          value={filterParameters.status}
          onChange={e => handleChangeFilterParameters(e.value, 'status')}
        />
      </div>
      {listOfUsers && (
        <div>
          <Table
            rows={listOfUsers}
            columns={columns}
            zebraStriped='even'
            borderBetweenRows
            borderBetweenColumns
            activeRow={{ id: undefined, onChange: handleClickRow }}
          />
          <div style={{ height: '2px', width: '100%' }} ref={ref}></div>
        </div>
      )}
    </div>
  )
}
