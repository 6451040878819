import styles from './Education.module.css'
import { UserInfoDTO } from 'shared/Api/users'
import { dateMappingForUserCards } from 'shared/utils/dateMappingForUserCards'
import { itemsAutoCompleteTypeOfEducation } from '../../../shared/utils/typeOfEducationMappingForEditCard'

export const Education = ({ user }: { user: UserInfoDTO }) => {
  return (
    <div className={styles.blockInfoContainer}>
      <h3 className={styles.title}>Образование</h3>
      {user.educations.length === 0 ? (
        <div className={styles.noInformation}>Информация не заполнена</div>
      ) : (
        user.educations.map((item, index) => {
          return (
            <div key={index} className={styles.infoContainer}>
              <div className={styles.textContainer}>
                <div className={styles.textLeft}>Период обучения</div>
                <div className={styles.textRight}>{dateMappingForUserCards(item.period)}</div>
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textLeft}>Специальность</div>
                <div className={styles.textRight}>{item.specialty}</div>
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textLeft}>Факультет</div>
                <div className={styles.textRight}>{item.faculty}</div>
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textLeft}>Учебное заведение</div>
                <div className={styles.textRight}>{item.institution_name}</div>
              </div>
              <div className={styles.textContainer}>
                <div className={styles.textLeft}>Тип Образования</div>
                <div className={styles.textRight}>
                  {
                    itemsAutoCompleteTypeOfEducation.find(typeOfEdu => {
                      return typeOfEdu.value === item.type_of_education
                    })?.label
                  }
                </div>
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}
