import { FC, useReducer, useEffect, useMemo, useContext } from 'react'

import { useParams, useNavigate } from 'react-router-dom'

import moment from 'moment'

import { Button } from '@consta/uikit/Button'
import { Informer } from '@consta/uikit/Informer'
import { DragNDropField } from '@consta/uikit/DragNDropField'

import IconDelete from '../../../assets/icons/delete.svg'
import photo from '../../../assets/icons/photo.svg'

import { SectionConstructor } from 'components/EditUsersCards/SectionConstructor/SectionConstructor'
import { FormInput } from 'components/EditUsersCards/FormInput/FormInput'
import { FormSection } from 'components/EditUsersCards/FormSection/FormSection'
import { AuthDataContext } from 'App'

import { getMyCard, updateMyCard, getCardById, updateCardById } from 'shared/Api/users'

import {
  buildField,
  buildMainInfoSection,
  buildSystemInfoSection,
  buildEducationSection,
  buildCareerSection,
  buildActivitiesSection,
  buildAwardsSection,
  prepareStateToBody,
} from 'store/editUser/selectors'
import { initialState, formReducer, ACTIONS, PathSections } from 'store/editUser'

import styles from './EditUser.module.css'
import { UserRolesEnum } from '../../../shared/enums/UserRole'

export const EditUser: FC = () => {
  const authData = useContext(AuthDataContext)
  const { userId } = useParams()
  const navigate = useNavigate()

  const [userProfile, dispatch] = useReducer(formReducer, initialState)

  useEffect(() => {
    const fetchData = async () => {
      const response = await (userId ? getCardById(+userId) : getMyCard())
      dispatch({
        type: ACTIONS.SET_DATA,
        payload: response.data,
      })
    }

    fetchData().catch(console.error)
  }, [userId])

  const sections = useMemo(() => {
    return {
      main: buildMainInfoSection(userProfile),
      system: buildSystemInfoSection(userProfile),
      educations: buildEducationSection(userProfile),
      careers: buildCareerSection(userProfile),
      social_activities: buildActivitiesSection(userProfile),
      awards: buildAwardsSection(userProfile),
    }
  }, [userProfile])

  const handleChangeTextField = ({ value, name }: any) => {
    dispatch({
      type: ACTIONS.UPDATE_ROOT_PROPERTY,
      payload: {
        key: name,
        value,
      },
    })
  }

  const handleChangeSection = ({
    section,
    index,
    value,
    innerIndex,
  }: {
    section: string
    index: number
    value: any
    innerIndex?: number
  }) => {
    const getKeyForMainSection = (index: number) =>
      ['birth_date', 'email', 'phone', 'position', 'committee', 'municipal_entity', 'party'][
        index
      ] ?? null

    const getPayload = (
      section: string,
      index: number,
      value: any,
      innerIndex?: number
    ): {
      path: PathSections
      index: number
      key: string
      value: string | number | boolean | object | null
      innerIndex?: number
    } => {
      switch (section) {
        case 'social_medias':
          return {
            path: 'social_medias',
            index,
            key: 'link',
            value,
          }
        case 'awards':
          return {
            path: 'awards',
            index,
            key: 'description',
            value,
          }
        case 'educations':
        case 'careers':
        case 'social_activities':
          const keyMap = {
            educations: ['period', 'specialty', 'faculty', 'institution_name', 'type_of_education'],
            careers: ['period', 'position', 'organization'],
            social_activities: ['period', 'position'],
          }
          const findIndex = (index: number): number => {
            // @ts-ignore
            const { id } = userProfile[section].filter(x => x.method !== 'DELETE')[index]
            return userProfile[section].findIndex(x => x.id === id)
          }
          if (keyMap[section][innerIndex ?? 0] === 'type_of_education') {
            return {
              path: section,
              index: findIndex(index),
              key: keyMap[section][innerIndex ?? 0],
              value: value?.label,
            }
          }
          return {
            path: section,
            index: findIndex(index),
            key: keyMap[section][innerIndex ?? 0],
            value:
              innerIndex === 0
                ? value.map((date: Date) => moment(date).format('YYYY-MM')).join('|')
                : value,
          }
        default:
          throw new Error(`Unknown section ${section}`)
      }
    }

    if (section === 'main') {
      dispatch({
        type: ACTIONS.UPDATE_ROOT_PROPERTY,
        payload: {
          key: getKeyForMainSection(index),
          value: getKeyForMainSection(index) === 'municipal_entity' ? value?.label : value,
        },
      })
    } else {
      dispatch({
        type: ACTIONS.UPDATE_SECTION,
        payload: getPayload(section, index, value, innerIndex),
      })
    }
  }
  const handleSave = () => {
    const fetchData = async () => {
      const body = prepareStateToBody(userProfile)
      await (userId ? updateCardById(+userId, body) : updateMyCard(body))
    }
    fetchData()
      .then(() => {
        dispatch({
          type: ACTIONS.SEND_DATA_SUCCESS,
        })
      })
      .catch(error => {
        const message = error.response?.data?.error?.message ?? error.message
        dispatch({
          type: ACTIONS.SEND_DATA_FAILED,
          payload: { message },
        })
      })
  }

  const handleSelectRole = (valueFromSelect: { id: 1 | 2 | 3 }) => {
    const value = {
      1: UserRolesEnum.THIRD_FACES,
      2: UserRolesEnum.DEPUTY,
      3: UserRolesEnum.EDITOR,
    }[valueFromSelect.id]

    dispatch({
      type: ACTIONS.UPDATE_ROOT_PROPERTY,
      payload: {
        key: 'role',
        value,
      },
    })
  }

  const handleChangeDeleteSection = ({
    section,
    index,
  }: {
    section: PathSections
    index: number
  }) => {
    // @ts-ignore
    const sectionByPath = userProfile[section].filter((item: any) => item.method !== 'DELETE')
    const id = sectionByPath[index]?.id || 0
    dispatch({
      type: ACTIONS.DELETE_SECTION,
      payload: {
        id,
        path: section,
      },
    })
  }

  const handleClickAdditionFields = ({ section }: { section: PathSections }) => {
    dispatch({
      type: ACTIONS.CREATE_SECTION,
      payload: {
        path: section,
      },
    })
  }

  const handleBackRedirect = () => {
    if (userId) {
      return navigate(`/users/${userId}`)
    } else {
      return navigate('/profile')
    }
  }

  const handleChangeDragNDropField = (selectedFile: File[]) => {
    dispatch({
      type: ACTIONS.UPDATE_AVATAR,
      payload: {
        selectedFile,
        file_deleted: false,
      },
    })
  }

  const handleDeleteAvatar = () => {
    dispatch({
      type: ACTIONS.DELETE_AVATAR,
      payload: {
        file_deleted: true,
      },
    })
  }

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.flexContainerHeader}>
          <div>
            <FormInput
              {...buildField({ label: 'Фамилия', value: userProfile.last_name, required: true })}
              onChange={(value: any) => handleChangeTextField({ name: 'last_name', value })}
            />
            <FormInput
              {...buildField({ label: 'Имя', value: userProfile.first_name, required: true })}
              onChange={(value: any) => handleChangeTextField({ name: 'first_name', value })}
            />
            <FormInput
              {...buildField({ label: 'Отчество', value: userProfile.second_name, required: true })}
              onChange={(value: any) => handleChangeTextField({ name: 'second_name', value })}
            />
          </div>
          {userProfile.image ? (
            <div className={styles.avatarContainer}>
              <img
                alt='delete_icon'
                src={IconDelete}
                className={styles.iconDeleteAvatar}
                onClick={handleDeleteAvatar}
              />
              <img
                alt=''
                src={
                  Array.isArray(userProfile.image)
                    ? URL.createObjectURL(userProfile.image[0])
                    : `${userProfile.image.url}`
                }
                className={styles.avatar}
              />
            </div>
          ) : (
            <DragNDropField
              onDropFiles={handleChangeDragNDropField}
              className={styles.dragNDropField}
            >
              <img alt='' src={photo} />
              <div style={{ color: '#91A4AB' }}>Добавить фото</div>
            </DragNDropField>
          )}
        </div>
        <div>
          <SectionConstructor
            formSection={sections.main}
            handleChangeSection={(index, value) =>
              index < 7
                ? handleChangeSection({ section: 'main', index, value })
                : handleChangeSection({ section: 'social_medias', index: index - 7, value })
            }
            handleChangeDeleteSection={index =>
              handleChangeDeleteSection({ section: 'social_medias', index })
            }
            handleClickAdditionFields={() =>
              handleClickAdditionFields({ section: 'social_medias' })
            }
          />
          <>
            {authData.role === UserRolesEnum.EDITOR && (
              <FormSection title={sections.system.title}>
                <FormInput
                  {...sections.system.fields[0]}
                  onChange={(value: any) => handleSelectRole(value)}
                />
              </FormSection>
            )}
          </>
          <SectionConstructor
            formSection={sections.educations}
            handleChangeSection={(index, value, innerIndex) =>
              handleChangeSection({ section: 'educations', index, value, innerIndex })
            }
            handleChangeDeleteSection={index =>
              handleChangeDeleteSection({ section: 'educations', index })
            }
            handleClickAdditionFields={() => handleClickAdditionFields({ section: 'educations' })}
          />

          <SectionConstructor
            formSection={sections.careers}
            handleChangeSection={(index, value, innerIndex) =>
              handleChangeSection({ section: 'careers', index, value, innerIndex })
            }
            handleChangeDeleteSection={index =>
              handleChangeDeleteSection({ section: 'careers', index })
            }
            handleClickAdditionFields={() => handleClickAdditionFields({ section: 'careers' })}
          />

          <SectionConstructor
            formSection={sections.social_activities}
            handleChangeSection={(index, value, innerIndex) =>
              handleChangeSection({ section: 'social_activities', index, value, innerIndex })
            }
            handleChangeDeleteSection={index =>
              handleChangeDeleteSection({ section: 'social_activities', index })
            }
            handleClickAdditionFields={() =>
              handleClickAdditionFields({ section: 'social_activities' })
            }
          />

          <SectionConstructor
            formSection={sections.awards}
            handleChangeSection={(index, value, innerIndex) =>
              handleChangeSection({ section: 'awards', index, value, innerIndex })
            }
            handleChangeDeleteSection={index =>
              handleChangeDeleteSection({ section: 'awards', index })
            }
            handleClickAdditionFields={() => handleClickAdditionFields({ section: 'awards' })}
          />
        </div>
      </div>
      <div className={styles.footerContainer}>
        {userProfile.isDataSaveFailed && (
          <Informer
            className={styles.footerInformer}
            status='alert'
            view='filled'
            title='Ошибка'
            label={userProfile.errorMessage}
          />
        )}
        <div className={styles.footerActionsButtons}>
          <Button
            onClick={() => handleBackRedirect()}
            label='Назад'
            view='secondary'
            className={styles.cancelButton}
            style={{ width: '240px', marginRight: '24px' }}
          />
          {userProfile.isDataSaveSucceed && !userProfile.isDataChanged ? (
            <Button
              className={styles.saveButton}
              disabled={true}
              label='Сохранено'
              style={{ width: '240px' }}
            />
          ) : (
            <Button
              className={styles.saveButton}
              disabled={!userProfile.isDataChanged}
              label='Сохранить'
              style={{ width: '240px' }}
              onClick={() => handleSave()}
            />
          )}
        </div>
      </div>
    </div>
  )
}
