import { UserRolesEnum } from '../enums/UserRole'

export const rolesToUIMapping = (role: string): string => {
  return (
    {
      [UserRolesEnum.DEPUTY]: 'Депутат',
      [UserRolesEnum.EDITOR]: 'Модератор',
      [UserRolesEnum.THIRD_FACES]: 'Пользователь',
    }[role] || 'none'
  )
}
