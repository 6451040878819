import { MunicipalityItem } from 'shared/types/MunicipalityItem'

export const itemsAutoCompleteMunicipality: MunicipalityItem[] = [
  {
    label: 'Анапа',
    id: 1,
    value: 'Анапа',
  },
  {
    label: 'Армавир',
    id: 2,
    value: 'Армавир',
  },
  {
    label: 'Геленджик',
    id: 3,
    value: 'Геленджик',
  },
  {
    label: 'Горячий Ключ',
    id: 4,
    value: 'Горячий Ключ',
  },
  {
    label: 'Краснодар',
    id: 5,
    value: 'Краснодар',
  },
  {
    label: 'Новороссийск',
    id: 6,
    value: 'Новороссийск',
  },
  {
    label: 'Сочи',
    id: 7,
    value: 'Сочи',
  },
  {
    label: 'Абинский',
    id: 8,
    value: 'Абинский',
  },
  {
    label: 'Апшеронский',
    id: 9,
    value: 'Апшеронский',
  },
  {
    label: 'Белоглинский',
    id: 10,
    value: 'Белоглинский',
  },
  {
    label: 'Белореченский',
    id: 11,
    value: 'Белореченский',
  },
  {
    label: 'Брюховецкий',
    id: 12,
    value: 'Брюховецкий',
  },
  {
    label: 'Выселковский',
    id: 13,
    value: 'Выселковский',
  },
  {
    label: 'Гулькевичский',
    id: 14,
    value: 'Гулькевичский',
  },
  {
    label: 'Динской',
    id: 15,
    value: 'Динской',
  },
  {
    label: 'Ейский',
    id: 16,
    value: 'Ейский',
  },
  {
    label: 'Кавказский',
    id: 17,
    value: 'Кавказский',
  },
  {
    label: 'Калининский',
    id: 18,
    value: 'Калининский',
  },
  {
    label: 'Каневской',
    id: 19,
    value: 'Каневской',
  },
  {
    label: 'Кореновский',
    id: 20,
    value: 'Кореновский',
  },
  {
    label: 'Красноармейский',
    id: 21,
    value: 'Красноармейский',
  },
  {
    label: 'Крыловский',
    id: 22,
    value: 'Крыловский',
  },
  {
    label: 'Крымский',
    id: 23,
    value: 'Крымский',
  },
  {
    label: 'Курганинский',
    id: 24,
    value: 'Курганинский',
  },
  {
    label: 'Кущевский',
    id: 25,
    value: 'Кущевский',
  },
  {
    label: 'Лабинский',
    id: 26,
    value: 'Лабинский',
  },
  {
    label: 'Ленинградский',
    id: 27,
    value: 'Ленинградский',
  },
  {
    label: 'Мостовский',
    id: 28,
    value: 'Мостовский',
  },
  {
    label: 'Новокубанский',
    id: 29,
    value: 'Новокубанский',
  },
  {
    label: 'Новопокровский',
    id: 30,
    value: 'Новопокровский',
  },
  {
    label: 'Отрадненский',
    id: 31,
    value: 'Отрадненский',
  },
  {
    label: 'Павловский',
    id: 32,
    value: 'Павловский',
  },
  {
    label: 'Приморско-Ахтарский',
    id: 33,
    value: 'Приморско-Ахтарский',
  },
  {
    label: 'Северский',
    id: 34,
    value: 'Северский',
  },
  {
    label: 'Славянский',
    id: 35,
    value: 'Славянский',
  },
  {
    label: 'Староминский',
    id: 36,
    value: 'Староминский',
  },
  {
    label: 'Тбилисский',
    id: 37,
    value: 'Тбилисский',
  },
  {
    label: 'Темрюкский',
    id: 38,
    value: 'Темрюкский',
  },
  {
    label: 'Тимашевский',
    id: 39,
    value: 'Тимашевский',
  },
  {
    label: 'Тихорецкий',
    id: 40,
    value: 'Тихорецкий',
  },
  {
    label: 'Туапсинский',
    id: 41,
    value: 'Туапсинский',
  },
  {
    label: 'Успенский',
    id: 42,
    value: 'Успенский',
  },
  {
    label: 'Усть-Лабинский',
    id: 43,
    value: 'Усть-Лабинский',
  },
  {
    label: 'Щербиновский',
    id: 44,
    value: 'Щербиновский',
  },
]
