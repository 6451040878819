import { ActivityState } from './types'

export const initState: ActivityState = {
  status: '',
  updatedAt: '',
  title: '',
  text: '',
  date: '',
  createdAt: '',
  members: [],
  client: {},
  images: {},
  documents: {},
  personal_reception: false,
  solved_appeal: false,
  received_enquiries: '',
  event_held: false,
  participation_in_session: false,
  participation_in_citizenship_meeting: false,
  participation_in_municipal_entity_meeting: false,
  training_event_by_municipal_entity: false,
  training_event_with_participation_municipal_entity: false,
  trained_deputies: '',
  municipal_project: false,
  regional_project: false,
  legislative_initiative_of_municipal_entity: false,
  legislative_initiative_of_ZSK: false,
  participation_of_deputy_ZSK: false,
  participation_of_deputy_GD: false,
  bureau_of_presidium: '',
  publication_links_municipal_entity: [],
  publication_links_mass_media: [],
  publication_links_in_internet_resources: [],
}
