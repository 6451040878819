export enum Quarter {
  I = 1,
  II = 2,
  III = 3,
  IV = 4,
}
const quarterToDateMapper: QuarterToDateMap = {
  I: {
    from: '01-01',
    until: '03-31',
  },
  II: {
    from: '04-01',
    until: '06-30',
  },
  III: {
    from: '07-01',
    until: '09-30',
  },
  IV: {
    from: '10-01',
    until: '12-31',
  },
}
type QuarterToDateMap = {
  [quarter: string]: {
    from: string
    until: string
  }
}
export const quarterToDate = (quarter: string): { from: string; until: string } => {
  if (Object.values(Quarter).includes(quarter)) {
    const { from, until } = quarterToDateMapper[quarter]
    return { from, until }
  }
  return {
    from: '',
    until: '',
  }
}
