import { ReportStatisticDTO } from '../../../shared/Api/reports'
import { getMappedData, getMappedDataActivity } from '../../../store/report/selectors'
import { ReportViewSection } from '../ReportViewSection/ReportViewSection'
import { ReportState } from '../../../store/report'

export const ReportViewContent = ({ report }: { report: ReportStatisticDTO }) => {
  return (
    <>
      {getMappedDataActivity(report).map((section, i) => {
        return <ReportViewSection key={i} title={section.title} list={section.items} />
      })}
    </>
  )
}
