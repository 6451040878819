import { ACTIONS, EDIT_USER_ACTIONS } from './actions'
import {
  Awards,
  Career,
  Education,
  SocialActivities,
  SocialMedia,
} from 'shared/Api/edit-user-info.dto'
import moment from 'moment'
import { EditUserState } from './types'

export function formReducer(state: EditUserState, action: EDIT_USER_ACTIONS): EditUserState {
  switch (action.type) {
    case ACTIONS.SET_DATA:
      const {
        id,
        role,
        social_medias,
        educations,
        careers,
        status,
        social_activities,
        awards,
        first_name,
        second_name,
        last_name,
        image,
        birth_date,
        email,
        phone,
        position,
        committee,
        party,
        municipal_entity,
      } = action.payload
      return {
        ...state,
        id,
        first_name,
        second_name,
        last_name,
        image: image ?? null,
        birth_date: birth_date ? moment(birth_date, 'DD.MM.YYYY').toDate() : null,
        role,
        status,
        email,
        phone: phone ?? '',
        position: position ?? '',
        committee: committee ?? '',
        party: party ?? '',
        municipal_entity: municipal_entity ?? '',
        social_medias:
          social_medias.length > 0
            ? (social_medias.map(item => ({
                ...item,
                method: 'PUT',
              })) as SocialMedia[])
            : ([{ id: Date.now(), method: 'POST', link: '' }] as SocialMedia[]),
        educations:
          educations.length > 0
            ? (educations.map(item => ({
                ...item,
                method: 'PUT',
              })) as Education[])
            : ([
                {
                  id: Date.now(),
                  method: 'POST',
                  faculty: '',
                  institution_name: '',
                  period: null,
                  specialty: '',
                  type_of_education: '',
                },
              ] as Education[]),
        careers:
          careers.length > 0
            ? (careers.map(item => ({
                ...item,
                method: 'PUT',
              })) as Career[])
            : ([
                { id: Date.now(), method: 'POST', period: null, position: '', organization: '' },
              ] as Career[]),
        social_activities:
          social_activities.length > 0
            ? (social_activities.map(item => ({
                ...item,
                method: 'PUT',
              })) as SocialActivities[])
            : ([
                { id: Date.now(), method: 'POST', period: null, position: '' },
              ] as SocialActivities[]),
        awards:
          awards.length > 0
            ? (awards.map(item => ({
                ...item,
                method: 'PUT',
              })) as Awards[])
            : ([{ id: Date.now(), method: 'POST', description: '' }] as Awards[]),
      }
    case ACTIONS.UPDATE_ROOT_PROPERTY:
      return {
        ...state,
        isDataChanged: true,
        isDataSaveSucceed: false,
        isDataSaveFailed: false,
        [action.payload.key]: action.payload.value,
      }
    case ACTIONS.UPDATE_EDUCATION_PROPERTY:
      return {
        ...state,
        isDataChanged: true,
        isDataSaveSucceed: false,
        isDataSaveFailed: false,
        [action.payload.key]: action.payload.value,
      }
    case ACTIONS.UPDATE_SECTION:
      const { path, index, key, value } = action.payload
      const section = state[path]
      // @ts-ignore
      section[index][key] = value
      return {
        ...state,
        isDataChanged: true,
        isDataSaveSucceed: false,
        isDataSaveFailed: false,
        [path]: section,
      }
    case ACTIONS.UPDATE_AVATAR:
      return {
        ...state,
        image: action.payload.selectedFile,
        file_deleted: action.payload.file_deleted,
        isDataChanged: true,
      }
    case ACTIONS.DELETE_AVATAR:
      return {
        ...state,
        image: null,
        file_deleted: action.payload.file_deleted,
        isDataChanged: true,
      }
    case ACTIONS.CREATE_SECTION:
      // @ts-ignore
      const getUpdatedSection = section => {
        switch (section) {
          case 'social_medias':
            return [...state.social_medias, { id: Date.now(), method: 'POST', link: '' }]
          case 'educations':
            return [
              ...state.educations,
              {
                id: Date.now(),
                method: 'POST',
                faculty: '',
                institution_name: '',
                period: null,
                specialty: '',
              },
            ]
          case 'careers':
            return [
              ...state.careers,
              { id: Date.now(), method: 'POST', period: null, position: '', organization: '' },
            ]
          case 'social_activities':
            return [
              ...state.social_activities,
              { id: Date.now(), method: 'POST', period: null, position: '' },
            ]
          case 'awards':
            return [...state.awards, { id: Date.now(), method: 'POST', description: '' }]
          default:
            throw new Error('Unexpected section')
        }
      }
      return {
        ...state,
        isDataChanged: true,
        isDataSaveSucceed: false,
        isDataSaveFailed: false,
        [action.payload.path]: getUpdatedSection(action.payload.path),
      }

    case ACTIONS.DELETE_SECTION:
      const selectedSection =
        'id' in action.payload
          ? state[action.payload.path].map(_ =>
              _?.id === action.payload.id ? { ..._, method: 'DELETE' } : _
            )
          : // @ts-ignore
            state[action.payload.path].filter((_, i) => i !== action.payload.index)
      return {
        ...state,
        isDataChanged: true,
        isDataSaveSucceed: false,
        isDataSaveFailed: false,
        [action.payload.path]: selectedSection,
      }
    case ACTIONS.SEND_DATA_SUCCESS:
      return {
        ...state,
        isDataChanged: false,
        isDataSaveSucceed: true,
      }
    case ACTIONS.SEND_DATA_FAILED:
      return {
        ...state,
        isDataChanged: false,
        isDataSaveFailed: true,
        errorMessage: action.payload.message,
      }
    default:
      return state
  }
}
