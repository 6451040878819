import { FC, useState } from 'react'

import { LogoBlock } from 'components/LogoBlock/LogoBlock'
import supportPicture from '../../assets/images/supportPicture.png'

import { TextField } from '@consta/uikit/TextField'
import { Button } from '@consta/uikit/Button'

import styles from './Support.module.css'

export const Support: FC = () => {
  const [isHidden, setIsHidden] = useState<boolean>(true)

  const [dataState, setDataState] = useState({
    email: null,
    fullName: null,
    messageSubject: null,
    descriptionProblem: null,
  })

  const handleButtonClick = () => {
    setIsHidden(!isHidden)
  }

  const handleChangeInfo = ({ value, name }: any) => {
    setDataState((prev: any) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.supportHeaderContainer}>
        <LogoBlock />
      </div>
      <div className={styles.supportFormContainer}>
        {isHidden ? (
          <>
            <div className={styles.title}>Обращение в поддержку</div>
            <div className={styles.flexContainer}>
              <TextField
                onChange={handleChangeInfo}
                value={dataState.email}
                name={'email'}
                placeholder='Email'
                width='full'
              />
              <TextField
                onChange={handleChangeInfo}
                value={dataState.fullName}
                name={'fullName'}
                placeholder='ФИО'
                width='full'
              />
            </div>
            <TextField
              onChange={handleChangeInfo}
              value={dataState.descriptionProblem}
              name={'descriptionProblem'}
              placeholder='Тема обращения'
              width='full'
              style={{ marginBottom: '24px' }}
            />
            <TextField
              onChange={handleChangeInfo}
              value={dataState.messageSubject}
              name={'messageSubject'}
              type='textarea'
              placeholder='Введите текст'
              width='full'
              style={{ marginBottom: '24px' }}
              minRows={6}
            />
            <Button
              label='Отправить'
              width='full'
              onClick={handleButtonClick}
              style={{ marginBottom: '40px', width: '320px' }}
            />
          </>
        ) : (
          <>
            <div className={styles.title}>Сообщение отправлено</div>
            <div className={styles.text}>
              Мы отправим ответ на вашу электронную почту в течение 7 дней
            </div>
            <img src={supportPicture} alt='' />
          </>
        )}
      </div>
    </div>
  )
}
