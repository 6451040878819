import { CSSProperties } from 'react'
import { ReportViewListItem } from '../ReportViewListItem/ReportViewListItem'
import {
  ReportAsSectionItemWithAllValues,
  ReportAsSectionWithValues,
  ReportFields,
  ReportValueField,
} from '../../../store/report'

const styles: {
  blockInfo: CSSProperties
  title: CSSProperties
} = {
  blockInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    padding: '16px 64px 16px 24px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    marginBottom: '24px',
  },
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '150%',
    marginBottom: '24px',
  },
}

export const ReportViewSection = ({
  title,
  list,
}: {
  title: string
  list: { label: string; value: { [x: string]: number } }[]
}) => {
  const listItems = list.map((item, i) => {
    const objkey = Object.keys(item.value)[0]
    return <ReportViewListItem key={i} text={item.label} value={item.value[objkey]} />
  })
  return (
    <div style={styles.blockInfo}>
      <h3 style={styles.title}>{title}</h3>
      {listItems}
    </div>
  )
}
