import axios from 'axios'
import { MunicipalityItemLabel } from '../types/MunicipalityItem'
import { ReportStatusEnum } from '../types/reportStatusEnum'
import { ReportFields, ReportInfo, ReportState } from '../../store/report'
import { ClientInfoInterface } from '../types/clientInfoInterface'

export const getReportForCurrentQuarter = async (): Promise<ReportStatisticDTO> => {
  return axios
    .get<ReportStatisticDTO>('/api/reports/statistic?mode=current_quarter')
    .then(r => r.data)
}
export const getQuarterlyReport = async (): Promise<ReportStatisticDTO> => {
  return axios
    .get<ReportStatisticDTO>('/api/reports/statistic?mode=quarter')
    .then(response => response.data)
}

export const getYearlyReport = async (): Promise<ReportStatisticDTO> => {
  return axios
    .get<ReportStatisticDTO>('/api/reports/statistic?mode=year')
    .then(response => response.data)
}
export const getListOfReport = async (params: {
  municipal_entity?: string
  status?: string
  first_field?: string
  quarter?: string
  year?: string
  limit?: string | number
  offset?: string | number
}): Promise<{
  data: ReportState[]
  total: number
}> => {
  return axios.get(`/api/reports`, { params }).then(response => response.data)
}
export const getReportById = async (id: number | string): Promise<ReportStatisticDTO> => {
  return axios.get<ReportStatisticDTO>(`/api/reports/${id}`).then(response => response.data)
}
export const getReportByIdState = async (id: number | string): Promise<ReportInfo> => {
  return axios
    .get<ReportInfo>(`/api/reports/${id}`)
    .then(response => response.data)
    .catch(err => err.response)
}
export const changeReportStatusToUpdated = (id: number | string) => {
  return axios.put(`/api/reports/change-status/${id}`, { status: ReportStatusEnum.TO_REVIEW })
}
export const getReportByIdToEdit = async (id: number | string): Promise<ReportInfo> => {
  return axios.get<ReportInfo>(`/api/reports/${id}`).then(response => response.data)
}
export const getReportByActivityQuery = async (query?: string): Promise<ReportStatisticDTO> => {
  return axios
    .get<ReportStatisticDTO>(`/api/reports/statistic-by-query?${query}`)
    .then(response => response.data)
}
export const approveReportById = (id: number) => {
  return axios.put(`/api/reports/change-status/${id}`, { status: ReportStatusEnum.APPROVED })
}
export const updateActualReport = (id: number, data: ReportFields<number>) => {
  return axios.put(`/api/actual-reports/${id}`, { data }).then(response => response.data)
}
export const updateArgumentsReport = (id: number, data: ReportFields<string>) => {
  return axios.put(`/api/argument-reports/${id}`, { data }).then(response => response.data)
}
export const createFilesReportEntity = (reportId: string) :Promise<{data:{data:{id:number}}}> =>{
  return axios.post('/api/files-reports',{data:{report:reportId}})
}
export const argumentReportEntity = (reportId:string):Promise<{data:{data:{id:number}}}> =>{
  return axios.post('/api/argument-reports',{data:{report:reportId}})
}
export const updateFilesReport = async (id: number, data: any) => {
  const { id: data_id, createdAt, updatedAt, report, ...updatedData }: Record<string, File[]> = data
  const formData = new FormData()
  formData.append('data', JSON.stringify({ id }))
  Object.entries(updatedData).map(([key, value]) => {
    if (value !== null && value.length > 0) {
      return appendFormData(formData, value, key)
    }
    return formData.append(`files.${key}`, [] as any)
  })
  return axios.put(`/api/files-reports/${id}`, formData).then(response => response.data)
}
export const createReport = (data: Partial<ReportRootDTO>) => {
  return axios.post('/api/reports', data).then(response => response.data)
}
export const createOriginalReport = (data: any) => {
  delete data.data.id
  return axios.post('/api/original-reports', data).then(response => response.data)
}
export const createActualReport = (data: any) => {
  delete data.data.id
  return axios.post('/api/actual-reports', data).then(response => response.data)
}
export const createArgumentsReport = (data: any) => {
  delete data.data.id
  return axios.post('/api/argument-reports', data).then(response => response.data)
}
export const appendFormData = (formData: FormData, files: File[], field: string): FormData => {
  for (const file of files) {
    formData.append(`files.${field}`, new Blob([file], { type: file.type }), file.name)
  }
  return formData
}
export const createFilesReport = (data: any) => {
  delete data.data.id
  const formData = new FormData()
  const ObjKeys = Object.keys(data.data)
  for (const field of ObjKeys) {
    if (field !== 'report' && data.data[field].length > 0) {
      appendFormData(formData, data.data[field], field)
    }
  }
  formData.append('data', JSON.stringify({ report: data.data.report }))
  return axios.post('/api/files-reports', formData).then(response => response.data)
}
export interface ReportStatisticDTO {
  id: number
  personal_reception: number
  solved_appeal: number
  received_enquiries: number
  event_held: number
  participation_in_session: number
  participation_in_citizenship_meeting: number
  participation_in_municipal_entity_meeting: number
  training_event_by_municipal_entity: number
  training_event_with_participation_municipal_entity: number
  trained_deputies: number
  municipal_project: number
  regional_project: number
  legislative_initiative_of_municipal_entity: number
  legislative_initiative_of_ZSK: number
  participation_of_deputy_ZSK: number
  participation_of_deputy_GD: number
  bureau_of_presidium: number
  publication_links_municipal_entity: number
  publication_links_mass_media: number
  publication_links_in_internet_resources: number
  quantity_of_posted_activities_in_smd_web_site: number
  quarter: string
  year: number
  status: ReportStatusEnum
}
export interface ReportOriginalAndActualDTO {
  id: number
  personal_reception: number
  solved_appeal: number
  received_enquiries: number
  event_held: number
  participation_in_session: number
  participation_in_citizenship_meeting: number
  participation_in_municipal_entity_meeting: number
  training_event_by_municipal_entity: number
  training_event_with_participation_municipal_entity: number
  trained_deputies: number
  municipal_project: number
  regional_project: number
  legislative_initiative_of_municipal_entity: number
  legislative_initiative_of_ZSK: number
  participation_of_deputy_ZSK: number
  participation_of_deputy_GD: number
  bureau_of_presidium: number
  publication_links_municipal_entity: number
  publication_links_mass_media: number
  publication_links_in_internet_resources: number
  quantity_of_posted_activities_in_smd_web_site: number
}

export interface ReportRootDTO {
  id: number
  client: string | ClientInfoInterface | number
  quarter: string
  year: string | number
  status: string
  municipal_entity: MunicipalityItemLabel
  comment: string
  type: 'quarter' | 'year'
  created_date: string
}
export interface ReportDTO {
  id?: number
  personal_reception: number
  solved_appeal: number
  received_enquiries: number
  event_held: number
  participation_in_session: number
  participation_in_citizenship_meeting: number
  participation_in_municipal_entity_meeting: number
  training_event_by_municipal_entity: number
  training_event_with_participation_municipal_entity: number
  trained_deputies: number
  municipal_project: number
  regional_project: number
  legislative_initiative_of_municipal_entity: number
  legislative_initiative_of_ZSK: number
  participation_of_deputy_ZSK: number
  participation_of_deputy_GD: number
  bureau_of_presidium: number
  publication_links_municipal_entity: number
  publication_links_mass_media: number
  publication_links_in_internet_resources: number
  quantity_of_posted_activities_in_smd_web_site: number
}

export interface ReportArgumentsDTO {
  id: number
  personal_reception: string
  solved_appeal: string
  received_enquiries: string
  event_held: string
  participation_in_session: string
  participation_in_citizenship_meeting: string
  participation_in_municipal_entity_meeting: string
  training_event_by_municipal_entity: string
  training_event_with_participation_municipal_entity: string
  trained_deputies: string
  municipal_project: string
  regional_project: string
  legislative_initiative_of_municipal_entity: string
  legislative_initiative_of_ZSK: string
  participation_of_deputy_ZSK: string
  participation_of_deputy_GD: string
  bureau_of_presidium: string
  publication_links_municipal_entity: string
  publication_links_mass_media: string
  publication_links_in_internet_resources: string
  quantity_of_posted_activities_in_smd_web_site: string
}

export interface ReportFilesDTO {
  id: number
  personal_reception: File[]
  solved_appeal: File[]
  received_enquiries: File[]
  event_held: File[]
  participation_in_session: File[]
  participation_in_citizenship_meeting: File[]
  participation_in_municipal_entity_meeting: File[]
  training_event_by_municipal_entity: File[]
  training_event_with_participation_municipal_entity: File[]
  trained_deputies: File[]
  municipal_project: File[]
  regional_project: File[]
  legislative_initiative_of_municipal_entity: File[]
  legislative_initiative_of_ZSK: File[]
  participation_of_deputy_ZSK: File[]
  participation_of_deputy_GD: File[]
  bureau_of_presidium: File[]
  publication_links_municipal_entity: File[]
  publication_links_mass_media: File[]
  publication_links_in_internet_resources: File[]
  quantity_of_posted_activities_in_smd_web_site: File[]
}
