import { FC, SVGProps, useEffect, useState } from 'react'

import { useParams, Link, useNavigate } from 'react-router-dom'

import { Button } from '@consta/uikit/Button'
import { createIcon } from '@consta/icons/Icon'
import { IconCancel } from '@consta/icons/IconCancel'
import { IconEdit } from '@consta/icons/IconEdit'
import { IconUnlock } from '@consta/icons/IconUnlock'

import { Avatar } from '@consta/uikit/Avatar'
import { SystemInformation } from 'components/UserCards/SystemInformation/SystemInformation'
import { Education } from 'components/UserCards/Education/Education'
import { PublicActivities } from 'components/UserCards/PublicActivities/PublicActivities'
import { BasicInfo } from 'components/UserCards/BasicInfo/BasicInfo'
import { Career } from 'components/UserCards/Career/Career'
import { Awards } from 'components/UserCards/Awards/Awards'
import { ModalForBlocking } from 'components/ModalForBlocking/ModalForBlocking'
import { ModalForUnlock } from 'components/ModalForUnlock/ModalForUnlock'

import styles from './User.module.css'
import { UserInfoDTO, getMyCard, getCardById } from 'shared/Api/users'
import { StatusAuthData } from '../../../shared/types/statusAuthData'
import { UserRolesEnum } from '../../../shared/enums/UserRole'

const IconReportSize = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM5 14C4.45 14 4 13.55 4 13V8C4 7.45 4.45 7 5 7C5.55 7 6 7.45 6 8V13C6 13.55 5.55 14 5 14ZM9 14C8.45 14 8 13.55 8 13V5C8 4.45 8.45 4 9 4C9.55 4 10 4.45 10 5V13C10 13.55 9.55 14 9 14ZM13 14C12.45 14 12 13.55 12 13V11C12 10.45 12.45 10 13 10C13.55 10 14 10.45 14 11V13C14 13.55 13.55 14 13 14Z' />
  </svg>
)
const IconReport = createIcon({
  l: IconReportSize,
  m: IconReportSize,
  s: IconReportSize,
  xs: IconReportSize,
  name: 'IconReport',
  renderType: {
    l: 'use',
    m: 'use',
    s: 'default',
    xs: 'use',
  },
  color: 'mono',
})

const IconActiveSize = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 18 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M13 9H5C4.45 9 4 9.45 4 10C4 10.55 4.45 11 5 11H13C13.55 11 14 10.55 14 10C14 9.45 13.55 9 13 9ZM16 2H15V1C15 0.45 14.55 0 14 0C13.45 0 13 0.45 13 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.89 2 0 2.9 0 4V18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM15 18H3C2.45 18 2 17.55 2 17V7H16V17C16 17.55 15.55 18 15 18ZM10 13H5C4.45 13 4 13.45 4 14C4 14.55 4.45 15 5 15H10C10.55 15 11 14.55 11 14C11 13.45 10.55 13 10 13Z' />
  </svg>
)
const IconActive = createIcon({
  l: IconActiveSize,
  m: IconActiveSize,
  s: IconActiveSize,
  xs: IconActiveSize,
  name: 'IconActive',
  renderType: {
    l: 'use',
    m: 'use',
    s: 'default',
    xs: 'use',
  },
  color: 'mono',
})

interface UserProps {
  authData: StatusAuthData
}

export const User: FC<UserProps> = ({ authData }) => {
  const { userId } = useParams()
  const navigate = useNavigate()

  const [userData, setUserData] = useState<UserInfoDTO>()

  const [isModallockOpened, setIsModallockOpened] = useState(false)
  const [isModalUnlockOpened, setIsModalUnlockOpened] = useState(false)
  const [isUserBlocked, setIsUserBlocked] = useState(false)

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await (userId ? getCardById(+userId) : getMyCard())
      setUserData(response.data)
    }
    fetchUserData().catch(console.error)
  }, [userId, isUserBlocked])

  useEffect(() => {
    if (userData?.status === 'blocked') {
      setIsUserBlocked(true)
    }
  }, [userData])

  const getFullName: () => string = () =>
    `${userData?.last_name} ${userData?.first_name} ${userData?.second_name}`

  return (
    <>
      {userData && (
        <div className={styles.wrapper}>
          <div className={styles.flexContainerHeader}>
            {userData?.image ? (
              <div className={styles.avatarContainer}>
                <img src={userData.image.url} alt='' className={styles.avatar} />
              </div>
            ) : (
              <Avatar
                form='default'
                name={`${userData?.first_name} ${userData?.last_name}`}
                className={styles.avatarConsta}
              />
            )}
            <div className={styles.infoContainer}>
              <div className={styles.fullName}>{getFullName()}</div>
              <div className={styles.buttonContainerLeft}>
                {!userId ? (
                  <Link to={`/profile/edit`}>
                    <Button iconLeft={IconEdit} onlyIcon />
                  </Link>
                ) : (
                  <Link to={`/users/${userId}/edit`}>
                    <Button iconLeft={IconEdit} onlyIcon />
                  </Link>
                )}
                {authData?.role === UserRolesEnum.EDITOR &&
                  (isUserBlocked ? (
                    <Button
                      iconLeft={IconUnlock}
                      onlyIcon
                      view='ghost'
                      onClick={() => setIsModalUnlockOpened(!isModalUnlockOpened)}
                      style={{ color: '#35AF0C' }}
                    />
                  ) : (
                    <Button
                      iconLeft={IconCancel}
                      onlyIcon
                      view='ghost'
                      onClick={() => setIsModallockOpened(!isModallockOpened)}
                      style={{ color: '#D90045' }}
                    />
                  ))}
              </div>
              <ModalForBlocking
                id={userId}
                isModallockOpened={isModallockOpened}
                setIsModallockOpened={setIsModallockOpened}
                setIsUserBlocked={setIsUserBlocked}
              />
              <ModalForUnlock
                id={userId}
                isModalUnlockOpened={isModalUnlockOpened}
                setIsModalUnlockOpened={setIsModalUnlockOpened}
                setIsUserBlocked={setIsUserBlocked}
              />
            </div>
          </div>
          <div className={styles.blockInfo}>
            {!userData ? (
              <div>Загрузка...</div>
            ) : (
              <div>
                <SystemInformation user={userData} />
                {userData.role !== UserRolesEnum.THIRD_FACES && <Education user={userData} />}
                {userData.role !== UserRolesEnum.THIRD_FACES && (
                  <PublicActivities user={userData} />
                )}
                {userData.role !== UserRolesEnum.THIRD_FACES && <BasicInfo user={userData} />}
                {userData.role !== UserRolesEnum.THIRD_FACES && <Career user={userData} />}
                {userData.role !== UserRolesEnum.THIRD_FACES && <Awards user={userData} />}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
