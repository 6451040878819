import { CSSProperties } from 'react'
import { ReportEditElement } from '../ReportEditElement/ReportEditElement'
import { ReportAsSectionItemWithAllValues } from '../../../store/report'

const styles: {
  blockInfo: CSSProperties
  title: CSSProperties
} = {
  blockInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    padding: '16px 12px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    marginBottom: '24px',
  },
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '150%',
    marginBottom: '24px',
  },
}

export const ReportEditSection = ({
  title,
  list,
  onChange,
}: {
  title: string
  list: ReportAsSectionItemWithAllValues[]
  onChange: (change: {
    actual_number: string
    comment: string | null
    files: File[] | undefined
    name: string
  }) => void
}) => {
  const listItems = list.map((item, i) => (
    <ReportEditElement
      key={i}
      value={{
        origin_number: +item.value.original,
        new_number: +item.value.actual,
        comment: item.value.arguments,
        files: item.value.files,
      }}
      label={item.label}
      onChange={value => {
        onChange({
          name: item.key,
          actual_number: String(value.new_number),
          comment: value.comment,
          files: value.files,
        })
      }}
    />
  ))
  return (
    <div style={styles.blockInfo}>
      <h3 style={styles.title}>{title}</h3>
      {listItems}
    </div>
  )
}
